<template>
  <div class="gift-shop">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2155.55 1089.74" class="dt">
      <g>
        <g>
          <rect x="388.67" y="-200" width="47.33" height="209.61" fill="#afafaf"/>
          <polygon points="939.97 264.35 939.97 286.81 924.58 298.72 924.11 299.09 0 299.09 0 264.35 899.84 264.35 899.84 198.74 939.88 198.74 939.88 264.35 939.97 264.35" fill="#223a5e"/>
          <polygon points="102.31 450.57 79.85 450.57 67.94 435.89 67.57 435.44 67.57 -29.1 102.31 -29.1 102.31 412.29 167.92 412.29 167.92 450.48 102.31 450.48 102.31 450.57" fill="#bfbfbf" opacity=".3"/>
          <g>
            <path d="M2155.37,106.97v90.22l-89.39,18.8-251.63-21.91-97.16,35.06-313.91-32.87-176.89,37.26-139.51-32.88-209.27,8.77-156.96-8.77-82.88-13.49h-70.42s.3-11.52,.85-23.52c.65-13.55,1.65-27.71,2.97-26.55l49.82,8.77,181.88-43.83,42.34-19.73,72.25,35.07,32.39-21.92,39.86,13.15,62.28-43.83,97.17,28.49,59.79,15.34,57.3,30.69,64.77-21.92,59.79-50.41h72.25l59.79-13.15,74.74,35.07h49.82l87.2,50.41,42.35-4.39,67.26,17.54,67.27-52.6,54.81,19.72,49.82-19.72,84.71,24.1,42.35-24.1,12.15,7.13Z" fill="#eff2f4"/>
            <path d="M2155.37,106.97v23.28l-25.88,21.25-62.13-26.32-92.05,14.17-46.01-20.24-59.84,42.51-105.85-2.03-126.57-52.63-101.26,2.02-80.54-24.29-156.48,74.9-94.36-16.19-126.56-58.71-112.76,46.56h-73.64l-80.55-8.1-167.99,40.49h-74.67c.65-13.55,1.65-27.71,2.97-26.55l49.82,8.77,181.88-43.83,42.34-19.73,72.25,35.07,32.39-21.92,39.86,13.15,62.28-43.83,97.17,28.49,59.79,15.34,57.3,30.69,64.77-21.92,59.79-50.41h72.25l59.79-13.15,74.74,35.07h49.82l87.2,50.41,42.35-4.39,67.26,17.54,67.27-52.6,54.81,19.72,49.82-19.72,84.71,24.1,42.35-24.1,12.15,7.13Z" fill="#e1ebf4"/>
          </g>
          <rect x="155.37" y="187.16" width="2000" height="421.57" fill="#42669b"/>
          <rect x="347.24" y="187.16" width="274.7" height="421.57" fill="#234668"/>
          <g id="topbar">
            <rect x="467.41" y="79.5" width="1687.96" height="30.52" fill="#afafaf"/>
            <rect x="467.41" y="94.74" width="1687.96" height="15.28" fill="#9b9b9b"/>
          </g>
          <g id="bottombar" class="short">
            <rect x="463.3" y="640.45" width="1692" height="30.52" fill="#afafaf"/>
            <rect x="463.3" y="655.69" width="1692" height="15.28" fill="#9b9b9b"/>
          </g>
          <g id="bottombar" class="tall">
            <rect x="463.3" y="500.45" width="1692" height="30.52" fill="#afafaf"/>
            <rect x="463.3" y="515.69" width="1692" height="15.28" fill="#9b9b9b"/>
          </g>
          <rect x="211.94" y="28.18" width="355.42" height="663.7" fill="#9ab1e8"/>
          <rect x="164.89" y="1.9" width="449.53" height="71.18" fill="#5f8ad6"/>
          <rect x="227.49" y="465.17" width="182.65" height="82.53" fill="#4264a8"/>
          <rect x="425.02" y="465.18" width="142.34" height="82.53" fill="#4264a8"/>
          <g transform="translate(18.505 -65.771)">
            <path id="Path_1447" data-name="Path 1447" d="M567.1,66.424s-374.489-2.33-416.739-2.59c-2.461-.01-3.8-.02-3.87-.02l32.549-28.65,49.49-18.23,66.41,2.61,79.431,9.11,69.02-22.14L500.694,0l39.171,38.03,5.1,4.95Z" transform="translate(0 2.767)" fill="#fff"/>
            <path id="Path_1448" data-name="Path 1448" d="M539.865,38.035l-22.24-4.17L481.164,11.724,448.6,28.655l-69.019,13.02-52.08-7.81-59.91,2.6-58.6,1.31-58.63,26.06c-2.46-.01-3.8-.02-3.87-.02l32.549-28.65,49.491-18.23,66.41,2.61,79.43,9.11,69.02-22.14L500.7,0Z" transform="translate(0 2.767)" fill="#e1ebf4"/>
          </g>
          <g>
            <rect x="481.41" y="472.32" width="105.04" height="22.98" fill="#848484"/>
            <path d="M582.61,472.36h-97.36l1.44-25.09,.75-12.99,1.58-27.56,.02-.23v.22s14.37-20.78,14.37-20.78h60.39c.21,0,.42,0,.63,.02l12.84,11.12c.16,.83,.24,1.69,.24,2.56l.28,4.05,1.37,19.48,1.29,18.35,.51,7.35,1.65,23.5Z" fill="#ff3e3e"/>
            <polygon points="522.67 472.32 519.13 495.3 481.41 495.3 481.41 472.32 522.67 472.32" fill="#707070"/>
            <polygon points="534.84 385.93 528.84 427.7 526.53 443.83 522.43 472.36 485.25 472.36 486.69 447.27 487.44 434.28 489.03 406.71 503.41 385.93 534.84 385.93" fill="#ff7373"/>
            <polygon points="528.84 427.7 526.53 443.83 486.69 447.27 489.04 406.49 528.84 427.7" fill="#ff9494"/>
            <polygon points="534.84 385.93 528.84 427.7 519.06 385.93 534.84 385.93" fill="#ff9494"/>
            <polygon points="582.61 472.36 522.43 472.36 554.02 455.56 580.45 441.51 580.96 448.86 582.61 472.36" fill="#cc1717"/>
            <polygon points="580.45 441.51 580.25 441.63 554.02 455.56 562.72 436.57 577.79 403.68 579.16 423.16 580.45 441.51" fill="#e52323"/>
            <path d="M577.79,403.68l-15.07,32.89-27.88-50.64h28.96c.21,0,.42,0,.63,.02l12.84,11.12c.16,.83,.24,1.69,.24,2.56l.28,4.05Z" fill="#f66"/>
            <polygon points="489.04 406.49 489.03 406.71 489.02 406.72 489.04 406.49" fill="#f66"/>
          </g>
          <g>
            <path d="M480.09,496.33s-285.32-2.58-306.94-.29c-.74,.08-1.17,.17-1.28,.25l15.22-43.42,17.47-12.01,18.82-25.9,34.49,1.86,13.89,5.55,36.29-3.72,33.94-15.07,1.46-.64,21.95,34.22,24.64,36.99,24.64-27.74,28.68-4.62,.77,1.34,14.01,24.54,21.95,28.67Z" fill="#fff"/>
            <path d="M341.99,403.58l-34.04,37.56-25.6,3.9-42.16-6.23-32.75,14.78-34.29,42.45c-.74,.08-1.17,.17-1.28,.25l15.22-43.42,17.47-12.01,18.82-25.9,34.49,1.86,13.89,5.55,36.29-3.72,33.94-15.07Z" fill="#e1ebf4"/>
            <polygon points="444.13 443.12 429.18 454.35 405.46 474.56 390.04 474.14 414.68 446.4 443.36 441.78 444.13 443.12" fill="#e1ebf4"/>
          </g>
          <polygon points="613.73 516.82 613.73 709.99 164.2 709.99 164.2 508.67 171.52 495.51 595.44 495.42 613.73 516.82" fill="#88ade2"/>
          <g>
            <polygon points="577.02 517.19 554.65 517.19 543.46 536.57 554.65 555.95 577.02 555.95 588.21 536.57 577.02 517.19" fill="#969696"/>
            <polygon points="558.01 557.44 554.65 555.95 577.02 555.95 588.21 536.57 577.02 517.19 580.39 518.68 591.58 538.06 580.39 557.44 558.01 557.44" fill="#757575"/>
            <polygon points="572.49 519.74 554.31 519.74 545.23 535.48 554.31 551.22 572.49 551.22 581.57 535.48 572.49 519.74" fill="#eae065"/>
            <polygon points="557.05 552.43 554.31 551.22 572.49 551.22 581.57 535.48 572.49 519.74 575.22 520.95 584.31 536.69 575.22 552.43 557.05 552.43" fill="#8e8335"/>
            <polygon points="554.31 519.74 560.66 534.17 545.23 535.48 554.31 519.74" fill="#fcf6c0"/>
            <polygon points="572.49 519.74 560.66 534.17 581.57 535.48 572.49 519.74" fill="#b7ac4b"/>
            <polygon points="560.66 534.17 577.88 541.79 581.57 535.48 560.66 534.17" fill="#fcf6c0"/>
            <polygon points="560.66 534.17 554.31 551.22 565.28 551.22 560.66 534.17" fill="#b7ac4b"/>
          </g>
          <g>
            <polygon points="518.5 517.19 496.12 517.19 484.94 536.57 496.12 555.95 518.5 555.95 529.69 536.57 518.5 517.19" fill="#969696"/>
            <polygon points="499.49 557.44 496.12 555.95 518.5 555.95 529.69 536.57 518.5 517.19 521.86 518.68 533.05 538.06 521.86 557.44 499.49 557.44" fill="#757575"/>
            <polygon points="513.96 519.74 495.79 519.74 486.7 535.48 495.79 551.22 513.96 551.22 523.05 535.48 513.96 519.74" fill="#eae065"/>
            <polygon points="498.52 552.43 495.79 551.22 513.96 551.22 523.05 535.48 513.96 519.74 516.7 520.95 525.78 536.69 516.7 552.43 498.52 552.43" fill="#8e8335"/>
            <polygon points="495.79 519.74 502.13 534.17 486.7 535.48 495.79 519.74" fill="#fcf6c0"/>
            <polygon points="513.96 519.74 502.13 534.17 523.05 535.48 513.96 519.74" fill="#b7ac4b"/>
            <polygon points="502.13 534.17 519.36 541.79 523.05 535.48 502.13 534.17" fill="#fcf6c0"/>
            <polygon points="502.13 534.17 495.79 551.22 506.76 551.22 502.13 534.17" fill="#b7ac4b"/>
          </g>
          <polygon points="578.52 621.04 578.52 707.92 199.4 707.92 199.4 603.52 226.22 589.21 551.34 589.21 578.52 621.04" fill="#4264a8"/>
          <polygon points="564.36 707.92 205.56 707.92 225.69 630.59 288.98 606.14 429.94 597.5 498.98 632.03 553.64 640.66 564.36 707.92" fill="#365689"/>
          <polygon points="642.8 1431.74 120.65 1431.74 210.97 733.53 228.63 665.84 260.4 650.85 521.48 650.85 544.46 664.31 560.71 732.98 642.8 1431.74" fill="#9b9b9b" class="tall"/>
          <polygon points="642.8 1079.74 120.65 1079.74 210.97 733.53 228.63 665.84 260.4 650.85 521.48 650.85 544.46 664.31 560.71 732.98 642.8 1079.74" fill="#9b9b9b" class="short"/>
          <polygon points="560.71 732.95 480.97 679.04 398.06 685.11 306.05 677.02 210.97 733.5 228.63 665.43 260.4 650.35 521.48 650.35 544.46 663.89 560.71 732.95" fill="#7a7a7a"/>
          <polygon points="489.62 823.89 263.79 823.26 280.21 959.28 485.92 959.28 489.62 823.89" fill="#5b5b5b"/>
          <polygon points="441.73 650.81 311.75 650.45 330.89 716.77 439.6 717.22 441.73 650.81" fill="#5b5b5b"/>
          <g>
            <rect x="280.28" y="832.42" width="210.78" height="180.24" fill="#4ea872"/>
            <rect x="360.33" y="833.84" width="50.68" height="178.81" fill="#e5cc38"/>
            <polygon points="498.14 808.46 498.14 835.83 491.06 835.83 491.06 841.41 280.28 841.41 280.28 835.83 273.21 835.83 273.21 808.46 498.14 808.46" opacity=".28"/>
            <rect x="273.21" y="802.88" width="224.93" height="32.94" fill="#419362"/>
            <g>
              <polygon points="405.43 799.36 401.35 803.44 360.66 803.44 358.84 801.62 335.89 778.66 360.31 754.24 385.48 779.41 405.43 799.36" fill="#fce542"/>
              <polygon points="435.46 778.66 412.5 801.62 410.68 803.44 369.99 803.44 365.91 799.36 385.67 779.6 385.86 779.41 411.03 754.24 435.46 778.66" fill="#fce542"/>
              <polygon points="414.69 803.44 356.66 803.44 358.84 801.62 385.48 779.41 385.67 779.25 385.86 779.41 412.5 801.62 414.69 803.44" fill="#d3bc33"/>
            </g>
            <rect x="356.64" y="802.97" width="58.06" height="33.81" fill="#fce542"/>
          </g>
          <g>
            <rect x="331.61" y="644.45" width="109.63" height="93.75" fill="#c25ddb"/>
            <rect x="373.24" y="645.19" width="26.36" height="93.01" fill="#dd8e37"/>
            <polygon points="444.92 631.99 444.92 646.23 441.24 646.23 441.24 649.13 331.61 649.13 331.61 646.23 327.93 646.23 327.93 631.99 444.92 631.99" opacity=".28"/>
            <rect x="327.93" y="629.09" width="117" height="17.13" fill="#ad4dc4"/>
            <g>
              <polygon points="396.7 627.26 394.58 629.38 373.42 629.38 372.47 628.43 360.53 616.49 373.23 603.79 386.33 616.88 396.7 627.26" fill="#f9a643"/>
              <polygon points="412.32 616.49 400.38 628.43 399.43 629.38 378.27 629.38 376.15 627.26 386.43 616.98 386.52 616.88 399.62 603.79 412.32 616.49" fill="#f9a643"/>
              <polygon points="401.52 629.38 371.33 629.38 372.47 628.43 386.33 616.88 386.43 616.8 386.52 616.88 400.38 628.43 401.52 629.38" fill="#c67926"/>
            </g>
            <rect x="371.32" y="629.14" width="30.2" height="17.59" fill="#f9a643"/>
          </g>
          <g>
            <g>
              <path d="M208.35,549.55v13.63l-4.76,2.84-15.29-5.07c-.05-.02-.1-.04-.15-.06h0c-.41-.21-.68-.63-.68-1.1v-28.84c0-.37,.17-.72,.45-.95,.24-.19,.5-.28,.77-.28,.63,0,1.23,.5,1.23,1.23v8.91c0,.52,.33,.98,.81,1.15l8.36,2.97,7.08,2.52,2.19,3.04Z" fill="#376ced"/>
              <polygon points="208.35 524.98 208.35 538.65 205.68 541.65 199.09 543.99 189.91 547.25 189.91 561.45 188.14 560.89 188.14 560.89 187.46 560.68 187.46 527.3 202.27 522.39 202.27 522.39 208.35 524.98" fill="#829bf9"/>
            </g>
            <path d="M208.33,524.96v.76l-4.41-1.88h0l-14.77,4.9h-.1v32.45l-.76-.26c-.05-.01-.11-.03-.15-.06h0l-.68-.22v-33.37l14.82-4.91,6.07,2.59Z" fill="#5f7ace"/>
          </g>
          <g>
            <rect x="151.92" y="44.26" width="430.66" height="334.7" transform="translate(-25.48 52.19) rotate(-7.86)" fill="#c4c4c4"/>
            <g>
              <g>
                <polygon points="408.81 38.01 145.06 73.41 142.09 73.81 155.24 49.7 171.32 37.28 188.13 21.93 206.4 21.93 220.28 8.78 241.47 8.04 262.66 13.16 286.05 23.39 317.47 34.35 345.97 29.24 354.9 30.85 374.47 34.35 397.85 30.7 408.81 38.01" fill="#fff"/>
                <polygon points="354.9 30.85 354.26 31.58 317.86 41.44 271.59 30.05 239.73 18.68 223.06 30.81 194.99 36.12 169.2 61.16 145.06 73.41 142.09 73.81 155.24 49.7 171.32 37.28 188.13 21.93 206.4 21.93 220.28 8.78 241.47 8.04 262.66 13.16 286.05 23.39 317.47 34.35 345.97 29.24 354.9 30.85" fill="#e1ebf4"/>
              </g>
              <g>
                <rect x="169.68" y="64.28" width="392.86" height="296.24" transform="translate(-25.6 52.04) rotate(-7.86)" fill="#1b364c"/>
                <path d="M557.63,16.03l15.06,9.47,45.8,331.93-426.62,58.87-15.06-9.47,426.62-58.87S558.55,15.9,557.63,16.03Z" fill="#9b9b9b"/>
                <g>
                  <polygon points="571.94 261.46 582.25 332.25 415.98 356.48 416.2 351.5 436.91 338.44 439.65 336.7 463.29 336.1 478.55 336.73 512.21 313.79 543.93 310.13 564.74 296.65 568.15 280.98 571.94 261.46" fill="#fff"/>
                  <polygon points="571.94 261.46 570.84 303.45 551.95 321.44 522.34 321.28 505.53 322.83 476.23 343.24 436.91 338.44 439.65 336.7 463.29 336.1 478.55 336.73 512.21 313.79 543.93 310.13 564.74 296.65 568.15 280.98 571.94 261.46" fill="#e1ebf4"/>
                </g>
              </g>
              <g>
                <foreignObject width="350" height="310" class="bdy" x="240" y="20" requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility">
                  <text class="hdl">The Gift Gallery</text>
                  <p xmlns="http://www.w3.org/1999/xhtml">Get inspired! Browse the gallery and see the creative gifts our visitors have made.</p>
                </foreignObject>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <div class="mobile-shop">
      <div class="mob-screen">
        <img src="../assets/images/screen-mobile.svg" alt="">
        <p class="hdl">The Gift Gallery</p>
        <p class="bdy">Get inspired! Browse the gallery and see the creative gifts our visitors have made.</p>
      </div>
      <div class="mobile-gray-pipe"></div>
      <img src="../assets/images/mobile-snow.svg" class="snow" />
      <div class="top"></div>
      <div class="middle">
        <svg xmlns="http://www.w3.org/2000/svg" width="64.26" height="285.137" viewBox="0 0 64.26 285.137">
          <path id="Path_1900" data-name="Path 1900" d="M160.4,352.666H146.016l-7.627-9.4-.237-.288.237-275.448h22.246L160.4,328.153h42.014v24.455H160.4Z" transform="translate(-138.153 -67.529)" fill="#4c618c"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="227.597" height="432.003" viewBox="0 0 227.597 432.003">
          <g id="Group_254" data-name="Group 254" transform="translate(-148.899 -59.115)">
            <rect id="Rectangle_427" data-name="Rectangle 427" width="227.597" height="425.003" transform="translate(148.899 59.115)" fill="#9ab1e8"/>
            <rect id="Rectangle_429" data-name="Rectangle 429" width="116.963" height="52.85" transform="translate(157.985 96.361)" fill="#4264a8"/>
            <rect id="Rectangle_430" data-name="Rectangle 430" width="92.02" height="52.855" transform="translate(284.477 96.358)" fill="#4264a8"/>
            <rect id="Rectangle_431" data-name="Rectangle 431" width="116.963" height="52.85" transform="translate(219.499 157.009)" fill="#4264a8"/>
            <rect id="Rectangle_432" data-name="Rectangle 432" width="61.071" height="52.849" transform="translate(148.899 157.007)" fill="#4264a8"/>
            <rect id="Rectangle_433" data-name="Rectangle 433" width="30.507" height="52.849" transform="translate(345.989 157.007)" fill="#4264a8"/>
            <rect id="Rectangle_434" data-name="Rectangle 434" width="106.12" height="52.849" transform="translate(148.899 217.655)" fill="#4264a8"/>
            <rect id="Rectangle_435" data-name="Rectangle 435" width="111.948" height="52.849" transform="translate(264.548 217.655)" fill="#4264a8"/>
            <rect id="Rectangle_436" data-name="Rectangle 436" width="116.963" height="52.85" transform="translate(199.572 278.303)" fill="#4264a8"/>
            <rect id="Rectangle_437" data-name="Rectangle 437" width="41.143" height="52.849" transform="translate(148.899 278.303)" fill="#4264a8"/>
            <rect id="Rectangle_438" data-name="Rectangle 438" width="50.428" height="52.849" transform="translate(326.068 278.303)" fill="#4264a8"/>
          </g>
        </svg>
        <div class="right-pipes">
          <svg xmlns="http://www.w3.org/2000/svg" width="185.342" height="19.544" viewBox="0 0 185.342 19.544" class="pipe upper">
            <g transform="translate(-312.492 -91.979)">
              <rect width="185.342" height="19.544" transform="translate(312.492 91.979)" fill="#afafaf"/>
              <rect width="185.342" height="9.785" transform="translate(312.492 101.738)" fill="#9b9b9b"/>
            </g>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="185.342" height="19.544" viewBox="0 0 185.342 19.544" class="pipe lower">
            <g transform="translate(-312.492 -91.979)">
              <rect width="185.342" height="19.544" transform="translate(312.492 91.979)" fill="#afafaf"/>
              <rect width="185.342" height="9.785" transform="translate(312.492 101.738)" fill="#9b9b9b"/>
            </g>
          </svg>
        </div>
      </div>
      <div class="middle-pipe"></div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 333 584.06" class="mob-bottom">
        <g>
          <g>
            <g transform="translate(245 15)">
              <rect y="34.72" width="40.63" height="9.24" fill="#848484"/>
              <path d="M39.15,34.74H1.49l.56-10.08,.29-5.22,.61-11.08v-.09s.01,.09,.01,.09L8.51,0H31.87c.08,0,.16,0,.24,.01l4.97,4.47c.06,.34,.09,.68,.09,1.03l.11,1.63,.53,7.83,.5,7.38,.2,2.95,.64,9.45h0Z" fill="#ff3e3e"/>
              <polygon points="15.96 34.72 14.59 43.96 0 43.96 0 34.72 15.96 34.72" fill="#707070"/>
              <polygon points="20.67 0 18.35 16.79 17.46 23.27 15.87 34.74 1.49 34.74 2.04 24.66 2.33 19.44 2.95 8.35 8.51 0 20.67 0" fill="#ff7373" class="lg-light-fills-alt b"/>
              <polygon points="18.35 16.79 17.46 23.27 2.04 24.66 2.95 8.26 18.35 16.79" fill="#ff9494" class="lg-light-fills-alt c"/>
              <polygon points="20.67 0 18.35 16.79 14.56 0 20.67 0" fill="#ff9494" class="lg-light-fills-alt d"/>
              <polygon points="39.15 34.74 15.87 34.74 28.09 27.99 38.31 22.34 38.51 25.29 39.15 34.74" fill="#cc1717" class="lg-light-fills-alt e"/>
              <polygon points="38.31 22.34 38.23 22.39 28.09 27.99 31.45 20.35 37.28 7.14 37.81 14.96 38.31 22.34" fill="#e52323" class="lg-light-fills-alt f"/>
              <path d="M37.28,7.14l-5.83,13.22L20.67,0h11.2c.08,0,.16,0,.24,.01l4.97,4.47c.06,.34,.09,.68,.09,1.03l.11,1.63h0Z" fill="#f66" class="lg-light-fills-alt a"/>
              <polygon points="2.95 8.26 2.95 8.35 2.94 8.36 2.95 8.26" fill="#f66"/>
            </g>
          </g>
          <g>
            <path d="M229.23,59.56s-181.96-1.65-195.75-.18c-.47,.05-.75,.11-.82,.16l9.71-27.69,11.14-7.66,12-16.52,22,1.19,8.86,3.54,23.14-2.37L141.16,.41l.93-.41,14,21.82,15.71,23.59,15.71-17.69,18.29-2.95,.49,.85,8.93,15.65,14,18.28h0Z" fill="#fff"/>
            <path d="M141.16,.41l-21.71,23.95-16.33,2.49-26.89-3.97-20.89,9.43-21.87,27.07c-.47,.05-.75,.11-.82,.16l9.71-27.69,11.14-7.66,12-16.52,22,1.19,8.86,3.54,23.14-2.37L141.16,.41h0Z" fill="#e1ebf4"/>
            <polygon points="206.3 25.62 196.76 32.79 181.64 45.68 171.8 45.41 187.52 27.72 205.81 24.77 206.3 25.62" fill="#e1ebf4"/>
          </g>
          <polygon points="314.46 72.63 314.46 195.82 27.77 195.82 27.77 67.43 32.44 59.04 302.8 58.98 314.46 72.63" fill="#88ade2"/>
          <g>
            <polygon points="291.05 72.86 276.78 72.86 269.65 85.22 276.78 97.58 291.05 97.58 298.19 85.22 291.05 72.86" fill="#969696"/>
            <polygon points="278.93 98.53 276.78 97.58 291.05 97.58 298.19 85.22 291.05 72.86 293.2 73.81 300.33 86.17 293.2 98.53 278.93 98.53" fill="#757575" class="flickerstatic"/>
            <polygon points="288.16 74.49 276.57 74.49 270.77 84.53 276.57 94.57 288.16 94.57 293.95 84.53 288.16 74.49" fill="#eae065" class="flickerstatic"/>
            <polygon points="278.31 95.34 276.57 94.57 288.16 94.57 293.95 84.53 288.16 74.49 289.9 75.26 295.7 85.3 289.9 95.34 278.31 95.34" fill="#8e8335" class="flickerstatic"/>
            <polygon points="276.57 74.49 280.62 83.69 270.77 84.53 276.57 74.49" fill="#fcf6c0" class="flickerstatic"/>
            <polygon points="288.16 74.49 280.62 83.69 293.95 84.53 288.16 74.49" fill="#b7ac4b" class="flickerstatic"/>
            <polygon points="280.62 83.69 291.6 88.55 293.95 84.53 280.62 83.69" fill="#fcf6c0" class="flickerstatic"/>
            <polygon points="280.62 83.69 276.57 94.57 283.56 94.57 280.62 83.69" fill="#b7ac4b" class="flickerstatic"/>
          </g>
          <g>
            <polygon points="253.73 72.86 239.46 72.86 232.33 85.22 239.46 97.58 253.73 97.58 260.86 85.22 253.73 72.86" fill="#969696"/>
            <polygon points="241.6 98.53 239.46 97.58 253.73 97.58 260.86 85.22 253.73 72.86 255.87 73.81 263.01 86.17 255.87 98.53 241.6 98.53" fill="#757575" class="flickerstatic"/>
            <polygon points="250.83 74.49 239.24 74.49 233.45 84.53 239.24 94.57 250.83 94.57 256.63 84.53 250.83 74.49" fill="#eae065" class="flickerstatic"/>
            <polygon points="240.99 95.34 239.24 94.57 250.83 94.57 256.63 84.53 250.83 74.49 252.58 75.26 258.37 85.3 252.58 95.34 240.99 95.34" fill="#8e8335" class="flickerstatic"/>
            <polygon points="239.24 74.49 243.29 83.69 233.45 84.53 239.24 74.49" fill="#fcf6c0" class="flickerstatic"/>
            <polygon points="250.83 74.49 243.29 83.69 256.63 84.53 250.83 74.49" fill="#b7ac4b" class="flickerstatic"/>
            <polygon points="243.29 83.69 254.28 88.55 256.63 84.53 243.29 83.69" fill="#fcf6c0" class="flickerstatic"/>
            <polygon points="243.29 83.69 239.24 94.57 246.24 94.57 243.29 83.69" fill="#b7ac4b" class="flickerstatic"/>
          </g>
          <polygon points="292.01 139.09 292.01 194.5 50.22 194.5 50.22 127.92 67.33 118.79 274.67 118.79 292.01 139.09" fill="#4264a8"/>
          <polygon points="282.98 194.5 54.15 194.5 66.99 145.18 107.35 129.59 197.25 124.08 241.28 146.1 276.14 151.61 282.98 194.5" fill="#365689"/>
          <polygon points="333 584.06 0 584.06 57.6 240.22 68.86 172.99 89.13 158.1 255.63 158.1 270.28 171.47 280.65 239.67 333 584.06" fill="#9b9b9b"/>
          <polygon points="280.65 210.46 229.79 176.08 176.92 179.95 118.24 174.79 57.6 210.81 68.86 167.4 89.13 157.79 255.63 157.79 270.28 166.42 280.65 210.46" fill="#7a7a7a"/>
          <polygon points="204.77 158.08 121.87 157.85 134.08 200.14 203.41 200.43 204.77 158.08" fill="#5b5b5b"/>
          <g transform="translate(-2.5 0)">
            <rect x="134.54" y="154.02" width="69.92" height="59.79" fill="#c25ddb"/>
            <rect x="161.09" y="154.49" width="16.81" height="59.32" fill="#dd8e37"/>
            <polygon points="206.8 146.08 206.8 155.16 204.46 155.16 204.46 157.01 134.54 157.01 134.54 155.16 132.19 155.16 132.19 146.08 206.8 146.08" isolation="isolate" opacity=".28"/>
            <rect x="132.19" y="144.23" width="74.62" height="10.92" fill="#ad4dc4"/>
            <g>
              <polygon points="176.05 143.06 174.7 144.41 161.2 144.41 160.6 143.81 152.98 136.19 161.08 128.09 169.44 136.44 176.05 143.06" fill="#f9a643"/>
              <polygon points="186.01 136.19 178.4 143.81 177.79 144.41 164.3 144.41 162.94 143.06 169.5 136.5 169.56 136.44 177.91 128.09 186.01 136.19" fill="#f9a643"/>
              <polygon points="179.12 144.41 159.87 144.41 160.6 143.81 169.44 136.44 169.5 136.39 169.56 136.44 178.4 143.81 179.12 144.41" fill="#c67926"/>
            </g>
            <rect x="159.86" y="144.26" width="19.26" height="11.22" fill="#f9a643"/>
          </g>
          <g>
            <g>
              <path d="M55.93,93.5v8.69l-3.04,1.81-9.75-3.23s-.06-.03-.1-.04h0c-.26-.13-.43-.4-.43-.7v-18.39c0-.24,.11-.46,.29-.61,.15-.12,.32-.18,.49-.18,.4,0,.78,.32,.78,.78v5.68c0,.33,.21,.62,.52,.73l5.33,1.89,4.52,1.61,1.4,1.94h0Z" fill="#376ced"/>
              <polygon points="55.93 77.83 55.93 86.55 54.23 88.46 50.02 89.95 44.17 92.03 44.17 101.09 43.04 100.73 43.04 100.73 42.61 100.6 42.61 79.31 52.05 76.18 52.05 76.18 55.93 77.83" fill="#829bf9"/>
            </g>
            <path d="M55.92,77.82v.48l-2.81-1.2h0l-9.42,3.12h-.06v20.69l-.48-.17s-.07-.02-.1-.04h0l-.43-.14v-21.28l9.45-3.13,3.87,1.65h-.01Z" fill="#5f7ace"/>
          </g>
        </g>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 622.43 228.86" class="shop-lights">
        <g>
          <polyline points="614.23 202.99 594.32 80.3 513.89 57.25 421.83 76.9 338.65 122.64 211.03 92.81 120.35 41.5 4.74 77.53" fill="none" stroke="#1d7c13" stroke-miterlimit="10" stroke-width="2"/>
          <g>
            <polygon points="414.25 42.91 408.78 44.8 406.83 48.02 410.35 49.35 415.82 47.45 417.77 44.23 414.25 42.91" fill="#62d14e" class="bulbe"/>
            <polygon points="415.82 47.45 428.88 58.91 417.77 44.23 415.82 47.45" fill="#5ebc48" class="bulbe"/>
            <polygon points="410.35 49.34 407.22 66.41 406.92 51.21 406.86 48.03 410.35 49.34" fill="#5ebc48" class="bulbe"/>
            <polygon points="410.35 49.34 416.58 65.68 421.84 64.13 415.82 47.45 410.35 49.34" fill="#79e260" class="bulbe"/>
            <polygon points="416.58 65.68 420.4 80.52 420.42 80.59 420.2 80.59 417.98 80.64 407.22 66.41 416.58 65.68" fill="#5bb545" class="bulbe"/>
            <polygon points="428.88 58.91 429.24 77.46 427.95 76.98 420.65 64.27 428.88 58.91" fill="#5bb545" class="bulbe"/>
            <polygon points="410.35 49.34 407.22 66.41 416.58 65.68 410.35 49.34" fill="#6fce59" class="bulbe"/>
            <polygon points="415.82 47.45 420.65 64.27 428.88 58.91 415.82 47.45" fill="#6fce59" class="bulbe"/>
            <polygon points="416.58 65.68 420.65 64.27 427.33 75.82 419.24 78.62 416.58 65.68" fill="#6fce59" class="bulbe"/>
            <rect x="415.7" y="76.69" width="16.6" height="5.48" transform="translate(-2.64 143.17) rotate(-19.11)" fill="#383838"/>
          </g>
          <g>
            <polygon points="595.09 226.38 600.32 228.86 603.97 227.93 602.39 224.52 597.16 222.03 593.51 222.96 595.09 226.38" fill="#62d14e" class="bulbe"/>
            <polygon points="597.16 222.03 595.89 204.71 593.51 222.96 597.16 222.03" fill="#5ebc48" class="bulbe"/>
            <polygon points="602.39 224.52 616.58 214.55 606.14 225.6 603.96 227.9 602.39 224.52" fill="#5ebc48" class="bulbe"/>
            <polygon points="602.39 224.52 609.4 208.51 604.56 205.92 597.16 222.03 602.39 224.52" fill="#79e260" class="bulbe"/>
            <polygon points="609.4 208.51 617.09 195.24 617.12 195.19 617.28 195.33 618.9 196.86 616.58 214.55 609.4 208.51" fill="#5bb545" class="bulbe"/>
            <polygon points="595.89 204.71 608.64 191.23 609.22 192.47 605.51 206.66 595.89 204.71" fill="#5bb545" class="bulbe"/>
            <polygon points="602.39 224.52 616.58 214.55 609.4 208.51 602.39 224.52" fill="#6fce59" class="bulbe"/>
            <polygon points="597.16 222.03 605.51 206.66 595.89 204.71 597.16 222.03" fill="#6fce59" class="bulbe"/>
            <polygon points="609.4 208.51 605.51 206.66 608.85 193.74 616.58 197.42 609.4 208.51" fill="#6fce59" class="bulbe"/>
            <rect x="605.46" y="190.76" width="16.6" height="5.48" transform="translate(1085.05 631.71) rotate(-154.58)" fill="#383838"/>
          </g>
          <g>
            <polygon points="116.41 0 109.81 1.11 106.98 4.41 110.74 6.59 117.33 5.47 120.17 2.18 116.41 0" fill="#62d14e" class="bulbe"/>
            <polygon points="117.33 5.47 130 21.02 120.17 2.18 117.33 5.47" fill="#5ebc48" class="bulbe"/>
            <polygon points="110.74 6.58 103.91 25.42 106.48 8.05 107.01 4.43 110.74 6.58" fill="#5ebc48" class="bulbe"/>
            <polygon points="110.74 6.58 114.71 26.37 121 25.62 117.33 5.47 110.74 6.58" fill="#79e260" class="bulbe"/>
            <polygon points="114.71 26.37 116.22 44.01 116.23 44.09 115.98 44.05 113.44 43.68 103.91 25.42 114.71 26.37" fill="#5bb545" class="bulbe"/>
            <polygon points="130 21.02 126.87 42.21 125.5 41.42 119.61 25.55 130 21.02" fill="#5bb545" class="bulbe"/>
            <polygon points="110.74 6.58 103.91 25.42 114.71 26.37 110.74 6.58" fill="#6fce59" class="bulbe"/>
            <polygon points="117.33 5.47 119.61 25.55 130 21.02 117.33 5.47" fill="#6fce59" class="bulbe"/>
            <polygon points="114.71 26.37 119.61 25.55 125.01 39.98 115.26 41.62 114.71 26.37" fill="#6fce59" class="bulbe"/>
            <rect x="110.94" y="40.29" width="19.17" height="6.33" transform="translate(-5.55 20.66) rotate(-9.58)" fill="#383838"/>
          </g>
          <g>
            <polygon points="613.53 53.2 608.53 50.27 604.82 50.88 606.1 54.42 611.09 57.35 614.81 56.74 613.53 53.2" fill="#ed4f75" class="bulbe"/>
            <polygon points="611.09 57.35 610.85 74.71 614.81 56.74 611.09 57.35" fill="#a0133c" class="bulbe"/>
            <polygon points="606.1 54.41 591.09 63.12 602.46 53.02 604.83 50.91 606.1 54.41" fill="#a0133c" class="bulbe"/>
            <polygon points="606.1 54.41 597.72 69.76 602.32 72.75 611.09 57.35 606.1 54.41" fill="#fc1f49" class="bulbe"/>
            <polygon points="597.72 69.76 588.91 82.31 588.87 82.36 588.73 82.2 587.25 80.54 591.09 63.12 597.72 69.76" fill="#a0133c" class="bulbe"/>
            <polygon points="610.85 74.71 596.98 87.04 596.51 85.75 601.44 71.94 610.85 74.71" fill="#a0133c" class="bulbe"/>
            <polygon points="606.1 54.41 591.09 63.12 597.72 69.76 606.1 54.41" fill="#d61241" class="bulbe"/>
            <polygon points="611.09 57.35 601.44 71.94 610.85 74.71 611.09 57.35" fill="#d61241" class="bulbe"/>
            <polygon points="597.72 69.76 601.44 71.94 596.99 84.52 589.6 80.19 597.72 69.76" fill="#ed4f75" class="bulbe"/>
            <rect x="583.77" y="81.59" width="16.6" height="5.48" transform="translate(124.09 -288.03) rotate(30.4)" fill="#383838"/>
          </g>
          <g>
            <polygon points="7.74 106.57 13.53 106.81 16.52 104.53 13.73 102 7.94 101.76 4.95 104.04 7.74 106.57" fill="#ed4f75" class="bulbe"/>
            <polygon points="7.94 101.76 0 86.31 4.95 104.04 7.94 101.76" fill="#a0133c" class="bulbe"/>
            <polygon points="13.73 102.01 22.89 87.27 17.6 101.53 16.5 104.5 13.73 102.01" fill="#a0133c" class="bulbe"/>
            <polygon points="13.73 102.01 13.92 84.53 8.46 84.04 7.94 101.76 13.73 102.01" fill="#fc1f49" class="bulbe"/>
            <polygon points="13.92 84.53 15.81 69.31 15.82 69.24 16.02 69.32 18.11 70.09 22.89 87.27 13.92 84.53" fill="#a0133c" class="bulbe"/>
            <polygon points="0 86.31 6.46 68.92 7.48 69.84 9.62 84.35 0 86.31" fill="#a0133c" class="bulbe"/>
            <polygon points="13.73 102.01 22.89 87.27 13.92 84.53 13.73 102.01" fill="#d61241" class="bulbe"/>
            <polygon points="7.94 101.76 9.62 84.35 0 86.31 7.94 101.76" fill="#d61241" class="bulbe"/>
            <polygon points="13.92 84.53 9.62 84.35 7.64 71.15 16.19 71.51 13.92 84.53" fill="#ed4f75" class="bulbe"/>
            <rect x="3.76" y="66.27" width="16.6" height="5.48" transform="translate(21.23 138.46) rotate(-177.6)" fill="#383838"/>
          </g>
          <g>
            <polygon points="317.36 148.74 323.34 151.67 327.55 150.65 325.77 146.7 319.79 143.77 315.58 144.79 317.36 148.74" fill="#ed4f75" class="bulbe"/>
            <polygon points="319.79 143.77 318.55 123.83 315.58 144.79 319.79 143.77" fill="#a0133c" class="bulbe"/>
            <polygon points="325.77 146.7 342.23 135.41 330.07 147.99 327.54 150.61 325.77 146.7" fill="#a0133c" class="bulbe"/>
            <polygon points="325.77 146.7 334.05 128.37 328.51 125.33 319.79 143.77 325.77 146.7" fill="#fc1f49" class="bulbe"/>
            <polygon points="334.05 128.37 343.06 113.21 343.1 113.14 343.28 113.31 345.12 115.09 342.23 135.41 334.05 128.37" fill="#a0133c" class="bulbe"/>
            <polygon points="318.55 123.83 333.39 108.48 334.05 109.92 329.6 126.19 318.55 123.83" fill="#a0133c" class="bulbe"/>
            <polygon points="325.77 146.7 342.23 135.41 334.05 128.37 325.77 146.7" fill="#d61241" class="bulbe"/>
            <polygon points="319.79 143.77 329.6 126.19 318.55 123.83 319.79 143.77" fill="#d61241" class="bulbe"/>
            <polygon points="334.05 128.37 329.6 126.19 333.6 111.37 342.45 115.7 334.05 128.37" fill="#ed4f75" class="bulbe"/>
            <rect x="329.7" y="108.01" width="19.1" height="6.31" transform="translate(595.18 360.06) rotate(-153.94)" fill="#383838"/>
          </g>
          <g>
            <polygon points="515.78 22.26 509.99 22.12 507.04 24.45 509.87 26.93 515.66 27.07 518.62 24.73 515.78 22.26" fill="#fffc92" class="bulbe"/>
            <polygon points="515.66 27.07 523.88 42.37 518.62 24.73 515.66 27.07" fill="#aaa321" class="bulbe"/>
            <polygon points="509.87 26.92 500.97 41.82 506.01 27.47 507.06 24.47 509.87 26.92" fill="#aaa321" class="bulbe"/>
            <polygon points="509.87 26.92 509.99 44.4 515.46 44.8 515.66 27.07 509.87 26.92" fill="#f9f921" class="bulbe"/>
            <polygon points="509.99 44.4 508.37 59.65 508.36 59.72 508.16 59.65 506.06 58.91 500.97 41.82 509.99 44.4" fill="#aaa321" class="bulbe"/>
            <polygon points="523.88 42.37 517.72 59.88 516.68 58.98 514.29 44.51 523.88 42.37" fill="#aaa321" class="bulbe"/>
            <polygon points="509.87 26.92 500.97 41.82 509.99 44.4 509.87 26.92" fill="#d6d234" class="bulbe"/>
            <polygon points="515.66 27.07 514.29 44.51 523.88 42.37 515.66 27.07" fill="#d6d234" class="bulbe"/>
            <polygon points="509.99 44.4 514.29 44.51 516.51 57.67 507.95 57.46 509.99 44.4" fill="#dbd628" class="bulbe"/>
            <rect x="503.82" y="57.15" width="16.6" height="5.48" transform="translate(1.6 -12.4) rotate(1.39)" fill="#383838"/>
          </g>
          <g>
            <polygon points="242.97 62.89 238.05 58.06 233.57 57.68 234.03 62.15 238.96 66.98 243.43 67.35 242.97 62.89" fill="#fffc92" class="bulbe"/>
            <polygon points="238.96 66.98 233.65 87 243.43 67.35 238.96 66.98" fill="#aaa321" class="bulbe"/>
            <polygon points="234.03 62.15 214.16 67.87 230.23 59.48 233.58 57.72 234.03 62.15" fill="#aaa321" class="bulbe"/>
            <polygon points="234.03 62.15 219.91 77.46 224.35 82.26 238.96 66.98 234.03 62.15" fill="#f9f921" class="bulbe"/>
            <polygon points="219.91 77.46 206.09 89.42 206.03 89.48 205.91 89.25 204.67 86.9 214.16 67.87 219.91 77.46" fill="#aaa321" class="bulbe"/>
            <polygon points="233.65 87 214.04 97.23 213.87 95.61 223.57 81.06 233.65 87" fill="#aaa321" class="bulbe"/>
            <polygon points="234.03 62.15 214.16 67.87 219.91 77.46 234.03 62.15" fill="#d6d234" class="bulbe"/>
            <polygon points="238.96 66.98 223.57 81.06 233.65 87 238.96 66.98" fill="#d6d234" class="bulbe"/>
            <polygon points="219.91 77.46 223.57 81.06 214.79 94.32 207.5 87.17 219.91 77.46" fill="#dbd628" class="bulbe"/>
            <rect x="199.26" y="89.41" width="19.79" height="6.53" transform="translate(124.76 -119.96) rotate(44.45)" fill="#383838"/>
          </g>
        </g>
      </svg>
    </div>
    <hooper group="imgslides" :settings="hooperSettings">
      <slide>
        <img src="../assets/images/slide-17.png" />
      </slide>
      <slide>
        <img src="../assets/images/slide-2.png" />
      </slide>
      <slide>
        <img src="../assets/images/slide-10.png" />
      </slide>
      <slide>
        <img src="../assets/images/slide-4.png" />
      </slide>
      <slide>
        <img src="../assets/images/slide-15.png" />
      </slide>
      <slide>
        <img src="../assets/images/slide-6.png" />
      </slide>
      <slide>
        <img src="../assets/images/slide-13.png" />
      </slide>
      <slide>
        <img src="../assets/images/slide-14.png" />
      </slide>
      <slide>
        <img src="../assets/images/slide-18.png" />
      </slide>
    </hooper>
    <hooper group="imgslides" :settings="hooperSettings" class="btm">
      <slide>
        <img src="../assets/images/slide-12.png" />
      </slide>
      <slide>
        <img src="../assets/images/slide-8.png" />
      </slide>
      <slide>
        <img src="../assets/images/slide-9.png" />
      </slide>
      <slide>
        <img src="../assets/images/slide-3.png" />
      </slide>
      <slide>
        <img src="../assets/images/slide-11.png" />
      </slide>
      <slide>
        <img src="../assets/images/slide-7.png" />
      </slide>
      <slide>
        <img src="../assets/images/slide-5.png" />
      </slide>
      <slide>
        <img src="../assets/images/slide-16.png" />
      </slide>
      <slide>
        <img src="../assets/images/slide-1.png" />
      </slide>
      <hooper-pagination slot="hooper-addons"></hooper-pagination>
    </hooper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2350 50" class="upper-lights">
      <g>
        <polyline points="565.65 86.34 694.28 50.31 795.17 101.62 937.15 131.44 1029.7 85.71 1132.13 66.06 1221.61 89.1 1460.44 117.31 1625.1 77.57 1886.46 109.19 2066.66 101.67 2108.88 78.7 2155.37 70.79" fill="none" stroke="#1d7c13" stroke-miterlimit="10" stroke-width="2.11"/>
        <g class="lights">
          <g class="ilum">
            <polygon points="1021.27 51.72 1015.18 53.61 1013.01 56.83 1016.93 58.15 1023.02 56.26 1025.19 53.04 1021.27 51.72"/>
            <polygon points="1023.02 56.26 1037.54 67.72 1025.19 53.04 1023.02 56.26"/>
            <polygon points="1016.93 58.15 1013.45 75.22 1013.11 60.01 1013.04 56.84 1016.93 58.15"/>
            <polygon points="1016.93 58.15 1023.85 74.49 1029.71 72.94 1023.02 56.26 1016.93 58.15"/>
            <polygon points="1023.86 74.49 1028.11 89.33 1028.13 89.4 1027.89 89.4 1025.41 89.45 1013.45 75.22 1023.86 74.49"/>
            <polygon points="1037.54 67.72 1037.94 86.27 1036.51 85.79 1028.38 73.08 1037.54 67.72"/>
            <polygon points="1016.93 58.15 1013.45 75.22 1023.86 74.49 1016.93 58.15"/>
            <polygon points="1023.02 56.26 1028.38 73.08 1037.54 67.72 1023.02 56.26"/>
            <polygon points="1023.86 74.49 1028.38 73.08 1035.82 84.63 1026.82 87.43 1023.86 74.49"/>
            <polygon points="1041.83 88.11 1024.38 93.55 1022.38 88.37 1039.84 82.94 1041.83 88.11"/>
          </g>
          <polygon points="1021.27 51.72 1015.18 53.61 1013.01 56.83 1016.93 58.15 1023.02 56.26 1025.19 53.04 1021.27 51.72" fill="#62d14e" class="bulbb"/>
          <polygon points="1023.02 56.26 1037.54 67.72 1025.19 53.04 1023.02 56.26" fill="#5ebc48" class="bulbb"/>
          <polygon points="1016.93 58.15 1013.45 75.22 1013.11 60.01 1013.04 56.84 1016.93 58.15" fill="#5ebc48" class="bulbb"/>
          <polygon points="1016.93 58.15 1023.85 74.49 1029.71 72.94 1023.02 56.26 1016.93 58.15" fill="#79e260" class="bulbb"/>
          <polygon points="1023.86 74.49 1028.11 89.33 1028.13 89.4 1027.89 89.4 1025.41 89.45 1013.45 75.22 1023.86 74.49" fill="#5bb545" class="bulbb"/>
          <polygon points="1037.54 67.72 1037.94 86.27 1036.51 85.79 1028.38 73.08 1037.54 67.72" fill="#5bb545" class="bulbb"/>
          <polygon points="1016.93 58.15 1013.45 75.22 1023.86 74.49 1016.93 58.15" fill="#6fce59" class="bulbb"/>
          <polygon points="1023.02 56.26 1028.38 73.08 1037.54 67.72 1023.02 56.26" fill="#6fce59" class="bulbb"/>
          <polygon points="1023.86 74.49 1028.38 73.08 1035.82 84.63 1026.82 87.43 1023.86 74.49" fill="#6fce59" class="bulbb"/>
          <polygon points="1041.83 88.11 1024.38 93.55 1022.38 88.37 1039.84 82.94 1041.83 88.11" fill="#383838" class="bulbb"/>
        </g>
        <g class="lights">
          <g class="ilum">
            <polygon points="1722.63 123.44 1729.01 122.67 1731.85 119.91 1728.31 117.91 1721.92 118.67 1719.08 121.44 1722.63 123.44"/>
            <polygon points="1721.92 118.67 1710.24 104.83 1719.08 121.44 1721.92 118.67"/>
            <polygon points="1728.31 117.91 1735.51 101.81 1732.46 116.77 1731.83 119.89 1728.31 117.91"/>
            <polygon points="1728.31 117.91 1725.15 100.66 1719.06 101.13 1721.92 118.67 1728.31 117.91"/>
            <polygon points="1725.15 100.66 1724.28 85.35 1724.27 85.28 1724.51 85.32 1726.95 85.72 1735.51 101.81 1725.15 100.66"/>
            <polygon points="1710.24 104.83 1713.96 86.58 1715.26 87.31 1720.4 101.23 1710.24 104.83"/>
            <polygon points="1728.31 117.91 1735.51 101.81 1725.15 100.66 1728.31 117.91"/>
            <polygon points="1721.92 118.67 1720.4 101.23 1710.24 104.83 1721.92 118.67"/>
            <polygon points="1725.15 100.66 1720.4 101.23 1715.68 88.57 1725.12 87.44 1725.15 100.66"/>
            <polygon points="1710.56 84.08 1728.87 81.89 1729.67 87.32 1711.37 89.51 1710.56 84.08"/>
          </g>
          <polygon points="1722.63 123.44 1729.01 122.67 1731.85 119.91 1728.31 117.91 1721.92 118.67 1719.08 121.44 1722.63 123.44" fill="#62d14e" class="bulbb"/>
          <polygon points="1721.92 118.67 1710.24 104.83 1719.08 121.44 1721.92 118.67" fill="#5ebc48" class="bulbb"/>
          <polygon points="1728.31 117.91 1735.51 101.81 1732.46 116.77 1731.83 119.89 1728.31 117.91" fill="#5ebc48" class="bulbb"/>
          <polygon points="1728.31 117.91 1725.15 100.66 1719.06 101.13 1721.92 118.67 1728.31 117.91" fill="#79e260" class="bulbb"/>
          <polygon points="1725.15 100.66 1724.28 85.35 1724.27 85.28 1724.51 85.32 1726.95 85.72 1735.51 101.81 1725.15 100.66" fill="#5bb545" class="bulbb"/>
          <polygon points="1710.24 104.83 1713.96 86.58 1715.26 87.31 1720.4 101.23 1710.24 104.83" fill="#5bb545" class="bulbb"/>
          <polygon points="1728.31 117.91 1735.51 101.81 1725.15 100.66 1728.31 117.91" fill="#6fce59" class="bulbb"/>
          <polygon points="1721.92 118.67 1720.4 101.23 1710.24 104.83 1721.92 118.67" fill="#6fce59" class="bulbb"/>
          <polygon points="1725.15 100.66 1720.4 101.23 1715.68 88.57 1725.12 87.44 1725.15 100.66" fill="#6fce59" class="bulbb"/>
          <polygon points="1710.56 84.08 1728.87 81.89 1729.67 87.32 1711.37 89.51 1710.56 84.08" fill="#383838" class="bulbb"/>
        </g>
        <g class="lights">
          <g class="ilum">
            <polygon points="2075.94 65.31 2069.58 64.41 2065.98 66.34 2068.75 69.16 2075.12 70.06 2078.71 68.13 2075.94 65.31"/>
            <polygon points="2075.12 70.06 2081.96 86.3 2078.71 68.13 2075.12 70.06"/>
            <polygon points="2068.75 69.16 2056.77 82.77 2064.41 69.2 2066 66.37 2068.75 69.16"/>
            <polygon points="2068.75 69.16 2066.34 86.51 2072.32 87.61 2075.12 70.06 2068.75 69.16"/>
            <polygon points="2066.34 86.51 2062.34 101.41 2062.33 101.48 2062.11 101.38 2059.9 100.38 2056.77 82.77 2066.34 86.51"/>
            <polygon points="2081.96 86.3 2072.63 102.86 2071.61 101.83 2071.07 87.17 2081.96 86.3"/>
            <polygon points="2068.75 69.16 2056.77 82.77 2066.34 86.51 2068.75 69.16"/>
            <polygon points="2075.12 70.06 2071.07 87.17 2081.96 86.3 2075.12 70.06"/>
            <polygon points="2066.34 86.51 2071.07 87.17 2071.61 100.51 2062.2 99.19 2066.34 86.51"/>
            <polygon points="2075.1 106.13 2056.85 103.56 2057.79 98.15 2076.04 100.71 2075.1 106.13"/>
          </g>
          <polygon points="2075.94 65.31 2069.58 64.41 2065.98 66.34 2068.75 69.16 2075.12 70.06 2078.71 68.13 2075.94 65.31" fill="#62d14e" class="bulbb"/>
          <polygon points="2075.12 70.06 2081.96 86.3 2078.71 68.13 2075.12 70.06" fill="#5ebc48" class="bulbb"/>
          <polygon points="2068.75 69.16 2056.77 82.77 2064.41 69.2 2066 66.37 2068.75 69.16" fill="#5ebc48" class="bulbb"/>
          <polygon points="2068.75 69.16 2066.34 86.51 2072.32 87.61 2075.12 70.06 2068.75 69.16" fill="#79e260" class="bulbb"/>
          <polygon points="2066.34 86.51 2062.34 101.41 2062.33 101.48 2062.11 101.38 2059.9 100.38 2056.77 82.77 2066.34 86.51" fill="#5bb545" class="bulbb"/>
          <polygon points="2081.96 86.3 2072.63 102.86 2071.61 101.83 2071.07 87.17 2081.96 86.3" fill="#5bb545" class="bulbb"/>
          <polygon points="2068.75 69.16 2056.77 82.77 2066.34 86.51 2068.75 69.16" fill="#6fce59" class="bulbb"/>
          <polygon points="2075.12 70.06 2071.07 87.17 2081.96 86.3 2075.12 70.06" fill="#6fce59" class="bulbb"/>
          <polygon points="2066.34 86.51 2071.07 87.17 2071.61 100.51 2062.2 99.19 2066.34 86.51" fill="#6fce59" class="bulbb"/>
          <polygon points="2075.1 106.13 2056.85 103.56 2057.79 98.15 2076.04 100.71 2075.1 106.13" fill="#383838" class="bulbb"/>
        </g>
        <g class="lights">
          <g class="ilum">
            <polygon points="1338.7 134.31 1344.69 132.17 1346.69 128.86 1342.71 127.7 1336.72 129.84 1334.72 133.15 1338.7 134.31"/>
            <polygon points="1336.72 129.84 1321.64 118.99 1334.72 133.15 1336.72 129.84"/>
            <polygon points="1342.71 127.7 1345.32 110.51 1346.43 125.69 1346.66 128.85 1342.71 127.7"/>
            <polygon points="1342.71 127.7 1334.96 111.67 1329.19 113.46 1336.72 129.84 1342.71 127.7"/>
            <polygon points="1334.96 111.67 1329.96 97.01 1329.94 96.95 1330.18 96.93 1332.65 96.78 1345.32 110.51 1334.96 111.67"/>
            <polygon points="1321.64 118.99 1320.29 100.47 1321.75 100.89 1330.51 113.26 1321.64 118.99"/>
            <polygon points="1342.71 127.7 1345.32 110.51 1334.96 111.67 1342.71 127.7"/>
            <polygon points="1336.72 129.84 1330.51 113.26 1321.64 118.99 1336.72 129.84"/>
            <polygon points="1334.96 111.67 1330.51 113.26 1322.5 102.03 1331.35 98.86 1334.96 111.67"/>
            <polygon points="1316.31 98.79 1333.47 92.65 1335.73 97.74 1318.57 103.88 1316.31 98.79"/>
          </g>
          <polygon points="1338.7 134.31 1344.69 132.17 1346.69 128.86 1342.71 127.7 1336.72 129.84 1334.72 133.15 1338.7 134.31" fill="#62d14e" class="bulbb"/>
          <polygon points="1336.72 129.84 1321.64 118.99 1334.72 133.15 1336.72 129.84" fill="#5ebc48" class="bulbb"/>
          <polygon points="1342.71 127.7 1345.32 110.51 1346.43 125.69 1346.66 128.85 1342.71 127.7" fill="#5ebc48" class="bulbb"/>
          <polygon points="1342.71 127.7 1334.96 111.67 1329.19 113.46 1336.72 129.84 1342.71 127.7" fill="#79e260" class="bulbb"/>
          <polygon points="1334.96 111.67 1329.96 97.01 1329.94 96.95 1330.18 96.93 1332.65 96.78 1345.32 110.51 1334.96 111.67" fill="#5bb545" class="bulbb"/>
          <polygon points="1321.64 118.99 1320.29 100.47 1321.75 100.89 1330.51 113.26 1321.64 118.99" fill="#5bb545" class="bulbb"/>
          <polygon points="1342.71 127.7 1345.32 110.51 1334.96 111.67 1342.71 127.7" fill="#6fce59" class="bulbb"/>
          <polygon points="1336.72 129.84 1330.51 113.26 1321.64 118.99 1336.72 129.84" fill="#6fce59" class="bulbb"/>
          <polygon points="1334.96 111.67 1330.51 113.26 1322.5 102.03 1331.35 98.86 1334.96 111.67" fill="#6fce59" class="bulbb"/>
          <polygon points="1316.31 98.79 1333.47 92.65 1335.73 97.74 1318.57 103.88 1316.31 98.79" fill="#383838" class="bulbb"/>
        </g>
        <g class="lights">
          <g class="ilum">
            <polygon points="689.89 8.81 682.55 9.92 679.4 13.22 683.58 15.4 690.92 14.28 694.07 10.99 689.89 8.81"/>
            <polygon points="690.92 14.28 705.01 29.83 694.07 10.99 690.92 14.28"/>
            <polygon points="683.58 15.39 675.99 34.23 678.84 16.86 679.43 13.24 683.58 15.39"/>
            <polygon points="683.58 15.39 687.99 35.18 694.99 34.43 690.92 14.28 683.58 15.39"/>
            <polygon points="688 35.18 689.68 52.82 689.69 52.9 689.41 52.86 686.58 52.49 675.99 34.23 688 35.18"/>
            <polygon points="705.01 29.83 701.53 51.02 700 50.23 693.45 34.35 705.01 29.83"/>
            <polygon points="683.58 15.39 675.99 34.23 688 35.18 683.58 15.39"/>
            <polygon points="690.92 14.28 693.45 34.35 705.01 29.83 690.92 14.28"/>
            <polygon points="688 35.18 693.45 34.35 699.46 48.79 688.61 50.43 688 35.18"/>
            <polygon points="705.57 53.79 684.54 56.98 683.37 50.74 704.39 47.55 705.57 53.79"/>
          </g>
          <polygon points="689.89 8.81 682.55 9.92 679.4 13.22 683.58 15.4 690.92 14.28 694.07 10.99 689.89 8.81" fill="#62d14e" class="bulbb"/>
          <polygon points="690.92 14.28 705.01 29.83 694.07 10.99 690.92 14.28" fill="#5ebc48" class="bulbb"/>
          <polygon points="683.58 15.39 675.99 34.23 678.84 16.86 679.43 13.24 683.58 15.39" fill="#5ebc48" class="bulbb"/>
          <polygon points="683.58 15.39 687.99 35.18 694.99 34.43 690.92 14.28 683.58 15.39" fill="#79e260" class="bulbb"/>
          <polygon points="688 35.18 689.68 52.82 689.69 52.9 689.41 52.86 686.58 52.49 675.99 34.23 688 35.18" fill="#5bb545" class="bulbb"/>
          <polygon points="705.01 29.83 701.53 51.02 700 50.23 693.45 34.35 705.01 29.83" fill="#5bb545" class="bulbb"/>
          <polygon points="683.58 15.39 675.99 34.23 688 35.18 683.58 15.39" fill="#6fce59" class="bulbb"/>
          <polygon points="690.92 14.28 693.45 34.35 705.01 29.83 690.92 14.28" fill="#6fce59" class="bulbb"/>
          <polygon points="688 35.18 693.45 34.35 699.46 48.79 688.61 50.43 688 35.18" fill="#6fce59" class="bulbb"/>
          <polygon points="705.57 53.79 684.54 56.98 683.37 50.74 704.39 47.55 705.57 53.79" fill="#383838" class="bulbb"/>
        </g>
        <g class="lights">
          <g class="ilum">
            <polygon points="1242.98 62.01 1237.42 59.08 1233.29 59.69 1234.71 63.23 1240.27 66.16 1244.4 65.55 1242.98 62.01"/>
            <polygon points="1240.27 66.16 1240 83.52 1244.4 65.55 1240.27 66.16"/>
            <polygon points="1234.71 63.22 1218.01 71.93 1230.66 61.83 1233.3 59.72 1234.71 63.22"/>
            <polygon points="1234.71 63.22 1225.39 78.56 1230.5 81.56 1240.27 66.16 1234.71 63.22"/>
            <polygon points="1225.39 78.57 1215.59 91.11 1215.55 91.17 1215.39 91.01 1213.74 89.35 1218.01 71.93 1225.39 78.57"/>
            <polygon points="1240 83.52 1224.56 95.85 1224.04 94.56 1229.52 80.74 1240 83.52"/>
            <polygon points="1234.71 63.22 1218.01 71.93 1225.39 78.57 1234.71 63.22"/>
            <polygon points="1240.27 66.16 1229.52 80.74 1240 83.52 1240.27 66.16"/>
            <polygon points="1225.39 78.57 1229.52 80.74 1224.58 93.33 1216.36 88.99 1225.39 78.57"/>
            <polygon points="1225.53 99.7 1209.6 91.3 1212.69 86.57 1228.62 94.98 1225.53 99.7"/>
          </g>
          <polygon points="1242.98 62.01 1237.42 59.08 1233.29 59.69 1234.71 63.23 1240.27 66.16 1244.4 65.55 1242.98 62.01" fill="#ed4f75" class="bulbb"/>
          <polygon points="1240.27 66.16 1240 83.52 1244.4 65.55 1240.27 66.16" fill="#a0133c" class="bulbb"/>
          <polygon points="1234.71 63.22 1218.01 71.93 1230.66 61.83 1233.3 59.72 1234.71 63.22" fill="#a0133c" class="bulbb"/>
          <polygon points="1234.71 63.22 1225.39 78.56 1230.5 81.56 1240.27 66.16 1234.71 63.22" fill="#fc1f49" class="bulbb"/>
          <polygon points="1225.39 78.57 1215.59 91.11 1215.55 91.17 1215.39 91.01 1213.74 89.35 1218.01 71.93 1225.39 78.57" fill="#a0133c" class="bulbb"/>
          <polygon points="1240 83.52 1224.56 95.85 1224.04 94.56 1229.52 80.74 1240 83.52" fill="#a0133c" class="bulbb"/>
          <polygon points="1234.71 63.22 1218.01 71.93 1225.39 78.57 1234.71 63.22" fill="#d61241" class="bulbb"/>
          <polygon points="1240.27 66.16 1229.52 80.74 1240 83.52 1240.27 66.16" fill="#d61241" class="bulbb"/>
          <polygon points="1225.39 78.57 1229.52 80.74 1224.58 93.33 1216.36 88.99 1225.39 78.57" fill="#ed4f75" class="bulbb"/>
          <polygon points="1225.53 99.7 1209.6 91.3 1212.69 86.57 1228.62 94.98 1225.53 99.7" fill="#383838" class="bulbb"/>
        </g>
        <g class="lights">
          <g class="ilum">
            <polygon points="1973.45 147.18 1979.24 144.65 1980.97 141.22 1976.91 140.32 1971.11 142.85 1969.38 146.28 1973.45 147.18"/>
            <polygon points="1971.11 142.85 1955.19 133.01 1969.38 146.28 1971.11 142.85"/>
            <polygon points="1976.91 140.32 1978.12 123.01 1980.45 138.07 1980.94 141.21 1976.91 140.32"/>
            <polygon points="1976.91 140.32 1967.89 124.84 1962.27 127 1971.11 142.85 1976.91 140.32"/>
            <polygon points="1967.88 124.84 1961.71 110.54 1961.68 110.48 1961.92 110.45 1964.37 110.14 1978.12 123.01 1967.88 124.84"/>
            <polygon points="1955.19 133.01 1952.35 114.63 1953.83 114.95 1963.57 126.72 1955.19 133.01"/>
            <polygon points="1976.91 140.32 1978.12 123.01 1967.88 124.84 1976.91 140.32"/>
            <polygon points="1971.11 142.85 1963.57 126.72 1955.19 133.01 1971.11 142.85"/>
            <polygon points="1967.88 124.84 1963.57 126.72 1954.68 116.03 1963.25 112.3 1967.88 124.84"/>
            <polygon points="1948.24 113.21 1964.86 105.96 1967.52 110.9 1950.91 118.14 1948.24 113.21"/>
          </g>
          <polygon points="1973.45 147.18 1979.24 144.65 1980.97 141.22 1976.91 140.32 1971.11 142.85 1969.38 146.28 1973.45 147.18" fill="#ed4f75" class="bulbb"/>
          <polygon points="1971.11 142.85 1955.19 133.01 1969.38 146.28 1971.11 142.85" fill="#a0133c" class="bulbb"/>
          <polygon points="1976.91 140.32 1978.12 123.01 1980.45 138.07 1980.94 141.21 1976.91 140.32" fill="#a0133c" class="bulbb"/>
          <polygon points="1976.91 140.32 1967.89 124.84 1962.27 127 1971.11 142.85 1976.91 140.32" fill="#fc1f49" class="bulbb"/>
          <polygon points="1967.88 124.84 1961.71 110.54 1961.68 110.48 1961.92 110.45 1964.37 110.14 1978.12 123.01 1967.88 124.84" fill="#a0133c" class="bulbb"/>
          <polygon points="1955.19 133.01 1952.35 114.63 1953.83 114.95 1963.57 126.72 1955.19 133.01" fill="#a0133c" class="bulbb"/>
          <polygon points="1976.91 140.32 1978.12 123.01 1967.88 124.84 1976.91 140.32" fill="#d61241" class="bulbb"/>
          <polygon points="1971.11 142.85 1963.57 126.72 1955.19 133.01 1971.11 142.85" fill="#d61241" class="bulbb"/>
          <polygon points="1967.88 124.84 1963.57 126.72 1954.68 116.03 1963.25 112.3 1967.88 124.84" fill="#ed4f75" class="bulbb"/>
          <polygon points="1948.24 113.21 1964.86 105.96 1967.52 110.9 1950.91 118.14 1948.24 113.21" fill="#383838" class="bulbb"/>
        </g>
        <g class="lights">
          <g class="ilum">
            <polygon points="1582.98 52.24 1577.65 55.49 1576.49 59.11 1580.66 59.47 1585.99 56.21 1587.15 52.6 1582.98 52.24"/>
            <polygon points="1585.99 56.21 1603.33 63.88 1587.15 52.6 1585.99 56.21"/>
            <polygon points="1580.66 59.47 1582.24 76.76 1577.51 62.16 1576.52 59.11 1580.66 59.47"/>
            <polygon points="1580.66 59.47 1592.07 73.62 1597.28 70.75 1585.99 56.21 1580.66 59.47"/>
            <polygon points="1592.08 73.62 1600.48 86.96 1600.52 87.01 1600.29 87.08 1597.91 87.7 1582.24 76.76 1592.08 73.62"/>
            <polygon points="1603.33 63.88 1609.09 81.7 1607.57 81.57 1596.04 71.19 1603.33 63.88"/>
            <polygon points="1580.66 59.47 1582.24 76.76 1592.08 73.62 1580.66 59.47"/>
            <polygon points="1585.99 56.21 1596.04 71.19 1603.33 63.88 1585.99 56.21"/>
            <polygon points="1592.08 73.62 1596.04 71.19 1606.56 80.61 1598.68 85.42 1592.08 73.62"/>
            <polygon points="1613.38 82.57 1598.1 91.9 1594.67 87.36 1609.95 78.04 1613.38 82.57"/>
          </g>
          <polygon points="1582.98 52.24 1577.65 55.49 1576.49 59.11 1580.66 59.47 1585.99 56.21 1587.15 52.6 1582.98 52.24" fill="#ed4f75" class="bulbb"/>
          <polygon points="1585.99 56.21 1603.33 63.88 1587.15 52.6 1585.99 56.21" fill="#a0133c" class="bulbb"/>
          <polygon points="1580.66 59.47 1582.24 76.76 1577.51 62.16 1576.52 59.11 1580.66 59.47" fill="#a0133c" class="bulbb"/>
          <polygon points="1580.66 59.47 1592.07 73.62 1597.28 70.75 1585.99 56.21 1580.66 59.47" fill="#fc1f49" class="bulbb"/>
          <polygon points="1592.08 73.62 1600.48 86.96 1600.52 87.01 1600.29 87.08 1597.91 87.7 1582.24 76.76 1592.08 73.62" fill="#a0133c" class="bulbb"/>
          <polygon points="1603.33 63.88 1609.09 81.7 1607.57 81.57 1596.04 71.19 1603.33 63.88" fill="#a0133c" class="bulbb"/>
          <polygon points="1580.66 59.47 1582.24 76.76 1592.08 73.62 1580.66 59.47" fill="#d61241" class="bulbb"/>
          <polygon points="1585.99 56.21 1596.04 71.19 1603.33 63.88 1585.99 56.21" fill="#d61241" class="bulbb"/>
          <polygon points="1592.08 73.62 1596.04 71.19 1606.56 80.61 1598.68 85.42 1592.08 73.62" fill="#ed4f75" class="bulbb"/>
          <polygon points="1613.38 82.57 1598.1 91.9 1594.67 87.36 1609.95 78.04 1613.38 82.57" fill="#383838" class="bulbb"/>
        </g>
        <g class="lights">
          <g class="ilum">
            <polygon points="568.99 115.37 575.42 115.62 578.76 113.34 575.65 110.81 569.21 110.57 565.88 112.85 568.99 115.37"/>
            <polygon points="569.21 110.57 560.37 95.12 565.88 112.85 569.21 110.57"/>
            <polygon points="575.65 110.81 585.85 96.08 579.96 110.34 578.73 113.31 575.65 110.81"/>
            <polygon points="575.65 110.81 575.86 93.34 569.78 92.85 569.21 110.57 575.65 110.81"/>
            <polygon points="575.86 93.33 577.96 78.12 577.97 78.05 578.2 78.13 580.52 78.9 585.85 96.08 575.86 93.33"/>
            <polygon points="560.37 95.12 567.57 77.73 568.7 78.65 571.08 93.16 560.37 95.12"/>
            <polygon points="575.65 110.81 585.85 96.08 575.86 93.33 575.65 110.81"/>
            <polygon points="569.21 110.57 571.08 93.16 560.37 95.12 569.21 110.57"/>
            <polygon points="575.86 93.33 571.08 93.16 568.87 79.96 578.39 80.32 575.86 93.33"/>
            <polygon points="564.7 74.73 583.15 75.43 582.9 80.9 564.44 80.21 564.7 74.73"/>
          </g>
          <polygon points="568.99 115.37 575.42 115.62 578.76 113.34 575.65 110.81 569.21 110.57 565.88 112.85 568.99 115.37" fill="#ed4f75" class="bulbb"/>
          <polygon points="569.21 110.57 560.37 95.12 565.88 112.85 569.21 110.57" fill="#a0133c" class="bulbb"/>
          <polygon points="575.65 110.81 585.85 96.08 579.96 110.34 578.73 113.31 575.65 110.81" fill="#a0133c" class="bulbb"/>
          <polygon points="575.65 110.81 575.86 93.34 569.78 92.85 569.21 110.57 575.65 110.81" fill="#fc1f49" class="bulbb"/>
          <polygon points="575.86 93.33 577.96 78.12 577.97 78.05 578.2 78.13 580.52 78.9 585.85 96.08 575.86 93.33" fill="#a0133c" class="bulbb"/>
          <polygon points="560.37 95.12 567.57 77.73 568.7 78.65 571.08 93.16 560.37 95.12" fill="#a0133c" class="bulbb"/>
          <polygon points="575.65 110.81 585.85 96.08 575.86 93.33 575.65 110.81" fill="#d61241" class="bulbb"/>
          <polygon points="569.21 110.57 571.08 93.16 560.37 95.12 569.21 110.57" fill="#d61241" class="bulbb"/>
          <polygon points="575.86 93.33 571.08 93.16 568.87 79.96 578.39 80.32 575.86 93.33" fill="#ed4f75" class="bulbb"/>
          <polygon points="564.7 74.73 583.15 75.43 582.9 80.9 564.44 80.21 564.7 74.73" fill="#383838" class="bulbb"/>
        </g>
        <g class="lights">
          <g class="ilum">
            <polygon points="913.46 157.55 920.12 160.48 924.8 159.46 922.82 155.51 916.16 152.58 911.48 153.6 913.46 157.55"/>
            <polygon points="916.16 152.58 914.79 132.63 911.48 153.6 916.16 152.58"/>
            <polygon points="922.82 155.51 941.14 144.22 927.61 156.8 924.79 159.42 922.82 155.51"/>
            <polygon points="922.82 155.51 932.03 137.18 925.87 134.14 916.16 152.58 922.82 155.51"/>
            <polygon points="932.03 137.18 942.06 122.02 942.1 121.95 942.3 122.12 944.35 123.9 941.14 144.22 932.03 137.18"/>
            <polygon points="914.79 132.63 931.3 117.29 932.03 118.73 927.08 135 914.79 132.63"/>
            <polygon points="922.82 155.51 941.14 144.22 932.03 137.18 922.82 155.51"/>
            <polygon points="916.16 152.58 927.08 135 914.79 132.63 916.16 152.58"/>
            <polygon points="932.03 137.18 927.08 135 931.54 120.18 941.38 124.51 932.03 137.18"/>
            <polygon points="929.82 112.94 948.91 121.33 945.83 127 926.74 118.61 929.82 112.94"/>
          </g>
          <polygon points="913.46 157.55 920.12 160.48 924.8 159.46 922.82 155.51 916.16 152.58 911.48 153.6 913.46 157.55" fill="#ed4f75" class="bulbb"/>
          <polygon points="916.16 152.58 914.79 132.63 911.48 153.6 916.16 152.58" fill="#a0133c" class="bulbb"/>
          <polygon points="922.82 155.51 941.14 144.22 927.61 156.8 924.79 159.42 922.82 155.51" fill="#a0133c" class="bulbb"/>
          <polygon points="922.82 155.51 932.03 137.18 925.87 134.14 916.16 152.58 922.82 155.51" fill="#fc1f49" class="bulbb"/>
          <polygon points="932.03 137.18 942.06 122.02 942.1 121.95 942.3 122.12 944.35 123.9 941.14 144.22 932.03 137.18" fill="#a0133c" class="bulbb"/>
          <polygon points="914.79 132.63 931.3 117.29 932.03 118.73 927.08 135 914.79 132.63" fill="#a0133c" class="bulbb"/>
          <polygon points="922.82 155.51 941.14 144.22 932.03 137.18 922.82 155.51" fill="#d61241" class="bulbb"/>
          <polygon points="916.16 152.58 927.08 135 914.79 132.63 916.16 152.58" fill="#d61241" class="bulbb"/>
          <polygon points="932.03 137.18 927.08 135 931.54 120.18 941.38 124.51 932.03 137.18" fill="#ed4f75" class="bulbb"/>
          <polygon points="929.82 112.94 948.91 121.33 945.83 127 926.74 118.61 929.82 112.94" fill="#383838" class="bulbb"/>
        </g>
        <g class="lights">
          <g class="ilum">
            <polygon points="1134.22 31.07 1127.78 30.93 1124.5 33.26 1127.65 35.73 1134.09 35.88 1137.38 33.54 1134.22 31.07"/>
            <polygon points="1134.09 35.88 1143.23 51.18 1137.38 33.54 1134.09 35.88"/>
            <polygon points="1127.65 35.73 1117.75 50.63 1123.36 36.28 1124.52 33.28 1127.65 35.73"/>
            <polygon points="1127.65 35.73 1127.78 53.21 1133.87 53.61 1134.09 35.88 1127.65 35.73"/>
            <polygon points="1127.78 53.21 1125.98 68.46 1125.97 68.53 1125.75 68.45 1123.41 67.72 1117.75 50.63 1127.78 53.21"/>
            <polygon points="1143.23 51.18 1136.38 68.69 1135.23 67.79 1132.57 53.32 1143.23 51.18"/>
            <polygon points="1127.65 35.73 1117.75 50.63 1127.78 53.21 1127.65 35.73"/>
            <polygon points="1134.09 35.88 1132.57 53.32 1143.23 51.18 1134.09 35.88"/>
            <polygon points="1127.78 53.21 1132.57 53.32 1135.03 66.48 1125.51 66.27 1127.78 53.21"/>
            <polygon points="1139.31 71.64 1120.84 71.23 1120.99 65.75 1139.46 66.16 1139.31 71.64"/>
          </g>
          <polygon points="1134.22 31.07 1127.78 30.93 1124.5 33.26 1127.65 35.73 1134.09 35.88 1137.38 33.54 1134.22 31.07" fill="#fffc92" class="bulbb"/>
          <polygon points="1134.09 35.88 1143.23 51.18 1137.38 33.54 1134.09 35.88" fill="#aaa321" class="bulbb"/>
          <polygon points="1127.65 35.73 1117.75 50.63 1123.36 36.28 1124.52 33.28 1127.65 35.73" fill="#aaa321" class="bulbb"/>
          <polygon points="1127.65 35.73 1127.78 53.21 1133.87 53.61 1134.09 35.88 1127.65 35.73" fill="#f9f921" class="bulbb"/>
          <polygon points="1127.78 53.21 1125.98 68.46 1125.97 68.53 1125.75 68.45 1123.41 67.72 1117.75 50.63 1127.78 53.21" fill="#aaa321" class="bulbb"/>
          <polygon points="1143.23 51.18 1136.38 68.69 1135.23 67.79 1132.57 53.32 1143.23 51.18" fill="#aaa321" class="bulbb"/>
          <polygon points="1127.65 35.73 1117.75 50.63 1127.78 53.21 1127.65 35.73" fill="#d6d234" class="bulbb"/>
          <polygon points="1134.09 35.88 1132.57 53.32 1143.23 51.18 1134.09 35.88" fill="#d6d234" class="bulbb"/>
          <polygon points="1127.78 53.21 1132.57 53.32 1135.03 66.48 1125.51 66.27 1127.78 53.21" fill="#dbd628" class="bulbb"/>
          <polygon points="1139.31 71.64 1120.84 71.23 1120.99 65.75 1139.46 66.16 1139.31 71.64" fill="#383838" class="bulbb"/>
        </g>
        <g class="lights">
          <g class="ilum">
            <polygon points="1850.4 71.12 1844.35 69.11 1840.4 70.35 1842.49 73.61 1848.54 75.63 1852.49 74.38 1850.4 71.12"/>
            <polygon points="1848.54 75.63 1851.64 92.77 1852.49 74.38 1848.54 75.63"/>
            <polygon points="1842.5 73.61 1827.74 84.8 1838.23 72.87 1840.42 70.38 1842.5 73.61"/>
            <polygon points="1842.5 73.61 1836.29 90.18 1841.91 92.33 1848.54 75.63 1842.5 73.61"/>
            <polygon points="1836.3 90.18 1829.08 104.08 1829.05 104.14 1828.86 104 1826.91 102.63 1827.74 84.8 1836.3 90.18"/>
            <polygon points="1851.64 92.77 1838.84 107.33 1838.07 106.14 1840.79 91.68 1851.64 92.77"/>
            <polygon points="1842.5 73.61 1827.74 84.8 1836.3 90.18 1842.5 73.61"/>
            <polygon points="1848.54 75.63 1840.79 91.68 1851.64 92.77 1848.54 75.63"/>
            <polygon points="1836.3 90.18 1840.79 91.68 1838.36 104.84 1829.43 101.87 1836.3 90.18"/>
            <polygon points="1840.54 110.97 1823.22 105.2 1825.34 100.06 1842.66 105.83 1840.54 110.97"/>
          </g>
          <polygon points="1850.4 71.12 1844.35 69.11 1840.4 70.35 1842.49 73.61 1848.54 75.63 1852.49 74.38 1850.4 71.12" fill="#fffc92" class="bulbb"/>
          <polygon points="1848.54 75.63 1851.64 92.77 1852.49 74.38 1848.54 75.63" fill="#aaa321" class="bulbb"/>
          <polygon points="1842.5 73.61 1827.74 84.8 1838.23 72.87 1840.42 70.38 1842.5 73.61" fill="#aaa321" class="bulbb"/>
          <polygon points="1842.5 73.61 1836.29 90.18 1841.91 92.33 1848.54 75.63 1842.5 73.61" fill="#f9f921" class="bulbb"/>
          <polygon points="1836.3 90.18 1829.08 104.08 1829.05 104.14 1828.86 104 1826.91 102.63 1827.74 84.8 1836.3 90.18" fill="#aaa321" class="bulbb"/>
          <polygon points="1851.64 92.77 1838.84 107.33 1838.07 106.14 1840.79 91.68 1851.64 92.77" fill="#aaa321" class="bulbb"/>
          <polygon points="1842.5 73.61 1827.74 84.8 1836.3 90.18 1842.5 73.61" fill="#d6d234" class="bulbb"/>
          <polygon points="1848.54 75.63 1840.79 91.68 1851.64 92.77 1848.54 75.63" fill="#d6d234" class="bulbb"/>
          <polygon points="1836.3 90.18 1840.79 91.68 1838.36 104.84 1829.43 101.87 1836.3 90.18" fill="#dbd628" class="bulbb"/>
          <polygon points="1840.54 110.97 1823.22 105.2 1825.34 100.06 1842.66 105.83 1840.54 110.97" fill="#383838" class="bulbb"/>
        </g>
        <g class="lights">
          <g class="ilum">
            <polygon points="1473.76 84.93 1467.93 82.45 1463.88 83.38 1465.64 86.79 1471.46 89.28 1475.52 88.35 1473.76 84.93"/>
            <polygon points="1471.46 89.28 1472.88 106.6 1475.52 88.35 1471.46 89.28"/>
            <polygon points="1465.64 86.79 1449.85 96.77 1461.47 85.72 1463.89 83.41 1465.64 86.79"/>
            <polygon points="1465.64 86.79 1457.85 102.81 1463.23 105.39 1471.46 89.28 1465.64 86.79"/>
            <polygon points="1457.85 102.81 1449.3 116.08 1449.26 116.13 1449.09 115.99 1447.29 114.46 1449.85 96.77 1457.85 102.81"/>
            <polygon points="1472.88 106.6 1458.7 120.09 1458.05 118.84 1462.17 104.65 1472.88 106.6"/>
            <polygon points="1465.64 86.79 1449.85 96.77 1457.85 102.81 1465.64 86.79"/>
            <polygon points="1471.46 89.28 1462.17 104.65 1472.88 106.6 1471.46 89.28"/>
            <polygon points="1457.85 102.81 1462.17 104.65 1458.47 117.58 1449.86 113.9 1457.85 102.81"/>
            <polygon points="1460.04 123.85 1443.35 116.73 1445.97 111.78 1462.65 118.9 1460.04 123.85"/>
          </g>
          <polygon points="1473.76 84.93 1467.93 82.45 1463.88 83.38 1465.64 86.79 1471.46 89.28 1475.52 88.35 1473.76 84.93" fill="#fffc92" class="bulbb"/>
          <polygon points="1471.46 89.28 1472.88 106.6 1475.52 88.35 1471.46 89.28" fill="#aaa321" class="bulbb"/>
          <polygon points="1465.64 86.79 1449.85 96.77 1461.47 85.72 1463.89 83.41 1465.64 86.79" fill="#aaa321" class="bulbb"/>
          <polygon points="1465.64 86.79 1457.85 102.81 1463.23 105.39 1471.46 89.28 1465.64 86.79" fill="#f9f921" class="bulbb"/>
          <polygon points="1457.85 102.81 1449.3 116.08 1449.26 116.13 1449.09 115.99 1447.29 114.46 1449.85 96.77 1457.85 102.81" fill="#aaa321" class="bulbb"/>
          <polygon points="1472.88 106.6 1458.7 120.09 1458.05 118.84 1462.17 104.65 1472.88 106.6" fill="#aaa321" class="bulbb"/>
          <polygon points="1465.64 86.79 1449.85 96.77 1457.85 102.81 1465.64 86.79" fill="#d6d234" class="bulbb"/>
          <polygon points="1471.46 89.28 1462.17 104.65 1472.88 106.6 1471.46 89.28" fill="#d6d234" class="bulbb"/>
          <polygon points="1457.85 102.81 1462.17 104.65 1458.47 117.58 1449.86 113.9 1457.85 102.81" fill="#dbd628" class="bulbb"/>
          <polygon points="1460.04 123.85 1443.35 116.73 1445.97 111.78 1462.65 118.9 1460.04 123.85" fill="#383838" class="bulbb"/>
        </g>
        <g class="lights">
          <g class="ilum">
            <polygon points="830.7 71.7 825.22 66.87 820.25 66.49 820.76 70.96 826.24 75.79 831.21 76.16 830.7 71.7"/>
            <polygon points="826.24 75.79 820.34 95.81 831.21 76.16 826.24 75.79"/>
            <polygon points="820.76 70.96 798.64 76.68 816.52 68.28 820.25 66.53 820.76 70.96"/>
            <polygon points="820.76 70.96 805.04 86.27 809.99 91.07 826.24 75.79 820.76 70.96"/>
            <polygon points="805.04 86.27 789.66 98.23 789.6 98.29 789.46 98.06 788.09 95.71 798.64 76.68 805.04 86.27"/>
            <polygon points="820.34 95.81 798.52 106.04 798.33 104.41 809.12 89.87 820.34 95.81"/>
            <polygon points="820.76 70.96 798.64 76.68 805.04 86.27 820.76 70.96"/>
            <polygon points="826.24 75.79 809.12 89.87 820.34 95.81 826.24 75.79"/>
            <polygon points="805.04 86.27 809.12 89.87 799.34 103.13 791.24 95.98 805.04 86.27"/>
            <polygon points="798.4 110.75 782.68 96.89 787.77 92.23 803.49 106.09 798.4 110.75"/>
          </g>
          <polygon points="830.7 71.7 825.22 66.87 820.25 66.49 820.76 70.96 826.24 75.79 831.21 76.16 830.7 71.7" fill="#fffc92" class="bulbb"/>
          <polygon points="826.24 75.79 820.34 95.81 831.21 76.16 826.24 75.79" fill="#aaa321" class="bulbb"/>
          <polygon points="820.76 70.96 798.64 76.68 816.52 68.28 820.25 66.53 820.76 70.96" fill="#aaa321" class="bulbb"/>
          <polygon points="820.76 70.96 805.04 86.27 809.99 91.07 826.24 75.79 820.76 70.96" fill="#f9f921" class="bulbb"/>
          <polygon points="805.04 86.27 789.66 98.23 789.6 98.29 789.46 98.06 788.09 95.71 798.64 76.68 805.04 86.27" fill="#aaa321" class="bulbb"/>
          <polygon points="820.34 95.81 798.52 106.04 798.33 104.41 809.12 89.87 820.34 95.81" fill="#aaa321" class="bulbb"/>
          <polygon points="820.76 70.96 798.64 76.68 805.04 86.27 820.76 70.96" fill="#d6d234" class="bulbb"/>
          <polygon points="826.24 75.79 809.12 89.87 820.34 95.81 826.24 75.79" fill="#d6d234" class="bulbb"/>
          <polygon points="805.04 86.27 809.12 89.87 799.34 103.13 791.24 95.98 805.04 86.27" fill="#dbd628" class="bulbb"/>
          <polygon points="798.4 110.75 782.68 96.89 787.77 92.23 803.49 106.09 798.4 110.75" fill="#383838" class="bulbb"/>
        </g>
      </g>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="1111.775" height="151.668" viewBox="-160 43 1800 50" class="lower-lights">
      <g id="Group_253" data-name="Group 253" transform="translate(-581.662 -502.409)">
        <path id="Path_1320" data-name="Path 1320" d="M2010.694,576.546l-115.611,36.026-90.679-51.3-127.616-29.826-83.18,45.739-92.064,19.647-80.426-23.046-214.667-28.2-147.993,39.739L823.541,553.692,661.58,561.215l-37.944,22.971-41.788,7.906" fill="none" stroke="#1d7c13" stroke-miterlimit="10" stroke-width="2"/>
        <path id="Path_1321" data-name="Path 1321" d="M1601.186,611.171l5.473-1.9,1.949-3.219-3.523-1.324-5.473,1.9-1.949,3.219Z" fill="#62d14e" class="bulbc"/>
        <path id="Path_1322" data-name="Path 1322" d="M1599.612,606.627l-13.052-11.461,11.1,14.681Z" fill="#5ebc48" class="bulbc"/>
        <path id="Path_1323" data-name="Path 1323" d="M1605.085,604.734l3.127-17.068.3,15.205.065,3.171Z" fill="#5ebc48" class="bulbc"/>
        <path id="Path_1324" data-name="Path 1324" d="M1605.085,604.734l-6.226-16.333-5.265,1.547,6.018,16.679Z" fill="#79e260" class="bulbc"/>
        <path id="Path_1325" data-name="Path 1325" d="M1598.857,588.4l-3.823-14.848-.016-.065.215,0,2.226-.049,10.753,14.232Z" fill="#5bb545" class="bulbc"/>
        <path id="Path_1326" data-name="Path 1326" d="M1586.56,595.166l-.363-18.553,1.285.479,7.306,12.718Z" fill="#5bb545" class="bulbc"/>
        <path id="Path_1327" data-name="Path 1327" d="M1605.085,604.734l3.127-17.068-9.355.734Z" fill="#6fce59" class="bulbc"/>
        <path id="Path_1328" data-name="Path 1328" d="M1599.612,606.627l-4.823-16.817-8.229,5.356Z" fill="#6fce59" class="bulbc"/>
        <path id="Path_1329" data-name="Path 1329" d="M1598.857,588.4l-4.068,1.41-6.682-11.553,8.09-2.8Z" fill="#6fce59" class="bulbc"/>
        <rect id="Rectangle_332" data-name="Rectangle 332" width="16.602" height="5.481" transform="translate(1582.7 574.771) rotate(-19.106)" fill="#383838"/>
        <path id="Path_1330" data-name="Path 1330" d="M970.8,539.449l-5.741.765-2.553,2.765,3.188,2,5.741-.765,2.553-2.765Z" fill="#62d14e" class="bulbc"/>
        <path id="Path_1331" data-name="Path 1331" d="M971.434,544.215l10.5,13.836-7.947-16.6Z" fill="#5ebc48" class="bulbc"/>
        <path id="Path_1332" data-name="Path 1332" d="M965.693,544.978l-6.472,16.1,2.74-14.959.57-3.12Z" fill="#5ebc48" class="bulbc"/>
        <path id="Path_1333" data-name="Path 1333" d="M965.693,544.978l2.84,17.248,5.468-.465-2.567-17.546Z" fill="#79e260" class="bulbc"/>
        <path id="Path_1334" data-name="Path 1334" d="M968.534,562.226l.781,15.313,0,.066-.212-.039-2.19-.4-7.7-16.092Z" fill="#5bb545" class="bulbc"/>
        <path id="Path_1335" data-name="Path 1335" d="M981.934,558.051,978.585,576.3l-1.163-.726-4.62-13.921Z" fill="#5bb545" class="bulbc"/>
        <path id="Path_1336" data-name="Path 1336" d="M965.693,544.978l-6.472,16.1,9.313,1.148Z" fill="#6fce59" class="bulbc"/>
        <path id="Path_1337" data-name="Path 1337" d="M971.434,544.215l1.368,17.442,9.132-3.606Z" fill="#6fce59" class="bulbc"/>
        <path id="Path_1338" data-name="Path 1338" d="M968.534,562.226l4.268-.57,4.24,12.655-8.487,1.131Z" fill="#6fce59" class="bulbc"/>
        <rect id="Rectangle_333" data-name="Rectangle 333" width="16.601" height="5.482" transform="translate(964.464 575.566) rotate(-7.591)" fill="#383838"/>
        <path id="Path_1339" data-name="Path 1339" d="M653.237,597.577l5.722.894,3.233-1.928-2.49-2.823-5.723-.894-3.232,1.929Z" fill="#62d14e" class="bulbc"/>
        <path id="Path_1340" data-name="Path 1340" d="M653.979,592.827l-6.147-16.245,2.915,18.173Z" fill="#5ebc48" class="bulbc"/>
        <path id="Path_1341" data-name="Path 1341" d="M659.7,593.723l10.771-13.6L663.6,593.687l-1.431,2.831Z" fill="#5ebc48" class="bulbc"/>
        <path id="Path_1342" data-name="Path 1342" d="M659.7,593.723l2.167-17.345-5.376-1.1-2.512,17.553Z" fill="#79e260" class="bulbc"/>
        <path id="Path_1343" data-name="Path 1343" d="M661.866,576.377l3.592-14.906.016-.065.192.1,1.989,1,2.817,17.614Z" fill="#5bb545" class="bulbc"/>
        <path id="Path_1344" data-name="Path 1344" d="M647.832,576.581l8.386-16.554.91,1.027.483,14.659Z" fill="#5bb545" class="bulbc"/>
        <path id="Path_1345" data-name="Path 1345" d="M659.7,593.723l10.771-13.6-8.6-3.742Z" fill="#6fce59" class="bulbc"/>
        <path id="Path_1346" data-name="Path 1346" d="M653.979,592.827l3.633-17.114-9.78.869Z" fill="#6fce59" class="bulbc"/>
        <path id="Path_1347" data-name="Path 1347" d="M661.866,576.377l-4.254-.664-.479-13.337,8.459,1.322Z" fill="#6fce59" class="bulbc"/>
        <rect id="Rectangle_334" data-name="Rectangle 334" width="5.482" height="16.601" transform="translate(653.148 562.176) rotate(-81.119)" fill="#383838"/>
        <path id="Path_1348" data-name="Path 1348" d="M1315.873,528.574l-5.381,2.143-1.8,3.3,3.58,1.162,5.381-2.143,1.8-3.3Z" fill="#62d14e" class="bulbc"/>
        <path id="Path_1349" data-name="Path 1349" d="M1317.652,533.041l13.559,10.856-11.758-14.161Z" fill="#5ebc48" class="bulbc"/>
        <path id="Path_1350" data-name="Path 1350" d="M1312.271,535.181l-2.348,17.193-.992-15.176-.21-3.165Z" fill="#5ebc48" class="bulbc"/>
        <path id="Path_1351" data-name="Path 1351" d="M1312.271,535.181l6.962,16.034,5.189-1.786-6.77-16.388Z" fill="#79e260" class="bulbc"/>
        <path id="Path_1352" data-name="Path 1352" d="M1319.235,551.215l4.494,14.659.02.064-.216.014-2.221.15-11.389-13.728Z" fill="#5bb545" class="bulbc"/>
        <path id="Path_1353" data-name="Path 1353" d="M1331.211,543.9l1.206,18.518-1.305-.421-7.877-12.372Z" fill="#5bb545" class="bulbc"/>
        <path id="Path_1354" data-name="Path 1354" d="M1312.271,535.181l-2.348,17.193,9.312-1.159Z" fill="#6fce59" class="bulbc"/>
        <path id="Path_1355" data-name="Path 1355" d="M1317.652,533.041l5.583,16.58,7.976-5.724Z" fill="#6fce59" class="bulbc"/>
        <path id="Path_1356" data-name="Path 1356" d="M1319.235,551.215l4-1.594,7.2,11.237-7.954,3.168Z" fill="#6fce59" class="bulbc"/>
        <rect id="Rectangle_335" data-name="Rectangle 335" width="16.601" height="5.482" transform="matrix(0.929, -0.37, 0.37, 0.929, 1318.543, 565.145)" fill="#383838"/>
        <path id="Path_1357" data-name="Path 1357" d="M1899.03,654.077l6.594-1.113,2.835-3.293-3.759-2.181-6.594,1.113-2.835,3.293Z" fill="#62d14e" class="bulbc"/>
        <path id="Path_1358" data-name="Path 1358" d="M1898.106,648.6l-12.671-15.545,9.836,18.838Z" fill="#5ebc48" class="bulbc"/>
        <path id="Path_1359" data-name="Path 1359" d="M1904.7,647.492l6.823-18.837-2.563,17.371-.532,3.623Z" fill="#5ebc48" class="bulbc"/>
        <path id="Path_1360" data-name="Path 1360" d="M1904.7,647.492l-3.968-19.788-6.291.756,3.666,20.142Z" fill="#79e260" class="bulbc"/>
        <path id="Path_1361" data-name="Path 1361" d="M1900.73,627.7l-1.515-17.638-.007-.077.247.037,2.543.369,9.525,18.261Z" fill="#5bb545" class="bulbc"/>
        <path id="Path_1362" data-name="Path 1362" d="M1885.435,633.058l3.132-21.2,1.372.791,5.889,15.878Z" fill="#5bb545" class="bulbc"/>
        <path id="Path_1363" data-name="Path 1363" d="M1904.7,647.492l6.823-18.837-10.793-.952Z" fill="#6fce59" class="bulbc"/>
        <path id="Path_1364" data-name="Path 1364" d="M1898.106,648.6l-2.278-20.071-10.393,4.526Z" fill="#6fce59" class="bulbc"/>
        <path id="Path_1365" data-name="Path 1365" d="M1900.73,627.7l-4.9.828-5.4-14.432,9.747-1.645Z" fill="#6fce59" class="bulbc"/>
        <rect id="Rectangle_336" data-name="Rectangle 336" width="19.168" height="6.329" transform="matrix(0.986, -0.166, 0.166, 0.986, 1884.938, 609.097)" fill="#383838"/>
        <path id="Path_1366" data-name="Path 1366" d="M1401.91,600.877l4.995,2.931,3.715-.608-1.281-3.539-5-2.931-3.714.608Z" fill="#ed4f75" class="bulbc"/>
        <path id="Path_1367" data-name="Path 1367" d="M1404.344,596.729l.242-17.368-3.956,17.976Z" fill="#a0133c" class="bulbc"/>
        <path id="Path_1368" data-name="Path 1368" d="M1409.337,599.662l15.011-8.7-11.368,10.1-2.369,2.108Z" fill="#a0133c" class="bulbc"/>
        <path id="Path_1369" data-name="Path 1369" d="M1409.337,599.662l8.379-15.341-4.6-3-8.777,15.407Z" fill="#fc1f49" class="bulbc"/>
        <path id="Path_1370" data-name="Path 1370" d="M1417.715,584.32l8.81-12.549.039-.054.143.161,1.482,1.661-3.841,17.419Z" fill="#a0133c" class="bulbc"/>
        <path id="Path_1371" data-name="Path 1371" d="M1404.585,579.362l13.874-12.323.47,1.288L1414,582.142Z" fill="#a0133c" class="bulbc"/>
        <path id="Path_1372" data-name="Path 1372" d="M1409.337,599.662l15.011-8.7-6.633-6.638Z" fill="#d61241" class="bulbc"/>
        <path id="Path_1373" data-name="Path 1373" d="M1404.344,596.729,1414,582.141l-9.416-2.78Z" fill="#d61241" class="bulbc"/>
        <path id="Path_1374" data-name="Path 1374" d="M1417.715,584.32,1414,582.142l4.448-12.583,7.384,4.333Z" fill="#ed4f75" class="bulbc"/>
        <rect id="Rectangle_337" data-name="Rectangle 337" width="5.482" height="16.602" transform="translate(1414.815 567.911) rotate(-59.6)" fill="#383838"/>
        <path id="Path_1375" data-name="Path 1375" d="M745.362,515.71l-5.211,2.528-1.556,3.427,3.655.9,5.211-2.528,1.556-3.427Z" fill="#ed4f75" class="bulbc"/>
        <path id="Path_1376" data-name="Path 1376" d="M747.461,520.036l14.312,9.841-12.756-13.268Z" fill="#a0133c" class="bulbc"/>
        <path id="Path_1377" data-name="Path 1377" d="M742.25,522.562l-1.092,17.318-2.093-15.064-.439-3.141Z" fill="#a0133c" class="bulbc"/>
        <path id="Path_1378" data-name="Path 1378" d="M742.25,522.562l8.11,15.485,5.045-2.158-7.944-15.853Z" fill="#fc1f49" class="bulbc"/>
        <path id="Path_1379" data-name="Path 1379" d="M750.361,538.047l5.548,14.293.024.063-.214.029-2.2.312L741.158,539.88Z" fill="#a0133c" class="bulbc"/>
        <path id="Path_1380" data-name="Path 1380" d="M761.773,529.878l2.55,18.381-1.333-.325-8.756-11.767Z" fill="#a0133c" class="bulbc"/>
        <path id="Path_1381" data-name="Path 1381" d="M742.25,522.562l-1.092,17.318,9.2-1.833Z" fill="#d61241" class="bulbc"/>
        <path id="Path_1382" data-name="Path 1382" d="M747.461,520.036l6.774,16.131,7.538-6.29Z" fill="#d61241" class="bulbc"/>
        <path id="Path_1383" data-name="Path 1383" d="M750.361,538.047l3.873-1.88,8,10.684-7.7,3.737Z" fill="#ed4f75" class="bulbc"/>
        <rect id="Rectangle_338" data-name="Rectangle 338" width="16.601" height="5.482" transform="matrix(0.9, -0.437, 0.437, 0.9, 750.684, 551.99)" fill="#383838"/>
        <path id="Path_1384" data-name="Path 1384" d="M1096.313,610.65l4.791-3.255,1.044-3.616-3.746-.361-4.791,3.255-1.044,3.616Z" fill="#ed4f75" class="bulbc"/>
        <path id="Path_1385" data-name="Path 1385" d="M1093.611,606.673,1078.027,599l14.54,11.285Z" fill="#a0133c" class="bulbc"/>
        <path id="Path_1386" data-name="Path 1386" d="M1098.4,603.421l-1.422-17.294,4.248,14.6.888,3.045Z" fill="#a0133c" class="bulbc"/>
        <path id="Path_1387" data-name="Path 1387" d="M1098.4,603.421l-10.263-14.15-4.68,2.864,10.152,14.539Z" fill="#fc1f49" class="bulbc"/>
        <path id="Path_1388" data-name="Path 1388" d="M1088.138,589.271l-7.556-13.342-.032-.058.207-.06,2.136-.626,14.087,10.942Z" fill="#a0133c" class="bulbc"/>
        <path id="Path_1389" data-name="Path 1389" d="M1078.027,599l-5.179-17.819,1.365.128,10.365,10.378Z" fill="#a0133c" class="bulbc"/>
        <path id="Path_1390" data-name="Path 1390" d="M1098.4,603.421l-1.422-17.294-8.842,3.144Z" fill="#d61241" class="bulbc"/>
        <path id="Path_1391" data-name="Path 1391" d="M1093.611,606.673l-9.034-14.982-6.55,7.313Z" fill="#d61241" class="bulbc"/>
        <path id="Path_1392" data-name="Path 1392" d="M1088.138,589.271l-3.561,2.42-9.458-9.415,7.082-4.812Z" fill="#ed4f75" class="bulbc"/>
        <rect id="Rectangle_339" data-name="Rectangle 339" width="16.601" height="5.482" transform="translate(1068.991 580.316) rotate(-34.193)" fill="#383838"/>
        <path id="Path_1393" data-name="Path 1393" d="M2007.7,547.511l-5.787-.242-2.994,2.281,2.793,2.523,5.787.242,2.994-2.281Z" fill="#ed4f75" class="bulbc"/>
        <path id="Path_1394" data-name="Path 1394" d="M2007.495,552.315l7.942,15.447-4.948-17.728Z" fill="#a0133c" class="bulbc"/>
        <path id="Path_1395" data-name="Path 1395" d="M2001.709,552.071l-9.166,14.734,5.293-14.258,1.1-2.974Z" fill="#a0133c" class="bulbc"/>
        <path id="Path_1396" data-name="Path 1396" d="M2001.709,552.071l-.194,17.479,5.465.49.515-17.725Z" fill="#fc1f49" class="bulbc"/>
        <path id="Path_1397" data-name="Path 1397" d="M2001.516,569.551l-1.886,15.216-.008.066-.2-.075-2.088-.77-4.788-17.183Z" fill="#a0133c" class="bulbc"/>
        <path id="Path_1398" data-name="Path 1398" d="M2015.437,567.763l-6.463,17.4-1.02-.917-2.136-14.511Z" fill="#a0133c" class="bulbc"/>
        <path id="Path_1399" data-name="Path 1399" d="M2001.709,552.071l-9.166,14.734,8.973,2.746Z" fill="#d61241" class="bulbc"/>
        <path id="Path_1400" data-name="Path 1400" d="M2007.495,552.315l-1.677,17.415,9.619-1.968Z" fill="#d61241" class="bulbc"/>
        <path id="Path_1401" data-name="Path 1401" d="M2001.516,569.551l4.3.179,1.982,13.2-8.555-.358Z" fill="#ed4f75" class="bulbc"/>
        <rect id="Rectangle_340" data-name="Rectangle 340" width="5.482" height="16.602" transform="translate(1994.965 587.459) rotate(-87.604)" fill="#383838"/>
        <path id="Path_1402" data-name="Path 1402" d="M1698.081,505.336l-5.986-2.927-4.208,1.021,1.778,3.948,5.986,2.928,4.208-1.021Z" fill="#ed4f75" class="bulbc"/>
        <path id="Path_1403" data-name="Path 1403" d="M1695.651,510.305l1.235,19.946,2.973-20.967Z" fill="#a0133c" class="bulbc"/>
        <path id="Path_1404" data-name="Path 1404" d="M1689.667,507.376,1673.2,518.669l12.162-12.579,2.534-2.626Z" fill="#a0133c" class="bulbc"/>
        <path id="Path_1405" data-name="Path 1405" d="M1689.667,507.376l-8.276,18.329,5.533,3.04,8.727-18.44Z" fill="#fc1f49" class="bulbc"/>
        <path id="Path_1406" data-name="Path 1406" d="M1681.391,525.706l-9.014,15.163-.039.066-.179-.172-1.845-1.776,2.889-20.319Z" fill="#a0133c" class="bulbc"/>
        <path id="Path_1407" data-name="Path 1407" d="M1696.886,530.251,1682.042,545.6l-.651-1.438,4.45-16.277Z" fill="#a0133c" class="bulbc"/>
        <path id="Path_1408" data-name="Path 1408" d="M1689.667,507.376,1673.2,518.669l8.187,7.038Z" fill="#d61241" class="bulbc"/>
        <path id="Path_1409" data-name="Path 1409" d="M1695.651,510.305l-9.809,17.577,11.044,2.369Z" fill="#d61241" class="bulbc"/>
        <path id="Path_1410" data-name="Path 1410" d="M1681.391,525.706l4.45,2.175-4.006,14.823-8.849-4.327Z" fill="#ed4f75" class="bulbc"/>
        <rect id="Rectangle_341" data-name="Rectangle 341" width="6.307" height="19.1" transform="translate(1666.218 541.553) rotate(-63.939)" fill="#383838"/>
        <path id="Path_1411" data-name="Path 1411" d="M1499.657,631.818l5.79.14,2.953-2.333-2.836-2.474-5.79-.14-2.954,2.333Z" fill="#fffc92" class="bulbc"/>
        <path id="Path_1412" data-name="Path 1412" d="M1499.774,627.011l-8.212-15.3,5.259,17.638Z" fill="#aaa321" class="bulbc"/>
        <path id="Path_1413" data-name="Path 1413" d="M1505.563,627.153l8.906-14.892-5.042,14.348-1.049,2.993Z" fill="#aaa321" class="bulbc"/>
        <path id="Path_1414" data-name="Path 1414" d="M1505.563,627.153l-.113-17.48-5.474-.393-.2,17.73Z" fill="#f9f921" class="bulbc"/>
        <path id="Path_1415" data-name="Path 1415" d="M1505.449,609.673l1.618-15.247.007-.066.2.071,2.1.734,5.089,17.1Z" fill="#aaa321" class="bulbc"/>
        <path id="Path_1416" data-name="Path 1416" d="M1491.562,611.705l6.156-17.506,1.036.9,2.391,14.471Z" fill="#aaa321" class="bulbc"/>
        <path id="Path_1417" data-name="Path 1417" d="M1505.563,627.153l8.906-14.892-9.02-2.588Z" fill="#d6d234" class="bulbc"/>
        <path id="Path_1418" data-name="Path 1418" d="M1499.774,627.011l1.371-17.441-9.583,2.136Z" fill="#d6d234" class="bulbc"/>
        <path id="Path_1419" data-name="Path 1419" d="M1505.449,609.673l-4.3-.1-2.214-13.161,8.559.208Z" fill="#dbd628" class="bulbc"/>
        <rect id="Rectangle_342" data-name="Rectangle 342" width="5.481" height="16.601" transform="matrix(0.024, -1, 1, 0.024, 1494.954, 596.729)" fill="#383838"/>
        <path id="Path_1420" data-name="Path 1420" d="M855.959,591.765l5.43,2.014,3.551-1.247-1.879-3.261-5.43-2.014L854.08,588.5Z" fill="#fffc92" class="bulbc"/>
        <path id="Path_1421" data-name="Path 1421" d="M857.631,587.257l-2.793-17.143-.758,18.39Z" fill="#aaa321" class="bulbc"/>
        <path id="Path_1422" data-name="Path 1422" d="M863.06,589.273l13.262-11.19-9.431,11.931-1.965,2.489Z" fill="#aaa321" class="bulbc"/>
        <path id="Path_1423" data-name="Path 1423" d="M863.06,589.273l5.573-16.568-5.048-2.151-5.954,16.7Z" fill="#f9f921" class="bulbc"/>
        <path id="Path_1424" data-name="Path 1424" d="M868.632,572.7l6.485-13.893.028-.061.17.134,1.749,1.376-.742,17.823Z" fill="#aaa321" class="bulbc"/>
        <path id="Path_1425" data-name="Path 1425" d="M854.838,570.113l11.511-14.555.687,1.187L864.6,571.207Z" fill="#aaa321" class="bulbc"/>
        <path id="Path_1426" data-name="Path 1426" d="M863.06,589.273l13.262-11.19-7.69-5.379Z" fill="#d6d234" class="bulbc"/>
        <path id="Path_1427" data-name="Path 1427" d="M857.631,587.257l6.964-16.049-9.757-1.094Z" fill="#d6d234" class="bulbc"/>
        <path id="Path_1428" data-name="Path 1428" d="M868.632,572.7l-4.037-1.5,2.183-13.166,8.027,2.978Z" fill="#dbd628" class="bulbc"/>
        <rect id="Rectangle_343" data-name="Rectangle 343" width="5.481" height="16.601" transform="translate(862.914 557.052) rotate(-69.647)" fill="#383838"/>
        <path id="Path_1429" data-name="Path 1429" d="M1194.485,577.953l5.232,2.484,3.647-.93-1.585-3.414-5.232-2.484-3.647.93Z" fill="#fffc92" class="bulbc"/>
        <path id="Path_1430" data-name="Path 1430" d="M1196.547,573.609l-1.276-17.322-2.371,18.252Z" fill="#aaa321" class="bulbc"/>
        <path id="Path_1431" data-name="Path 1431" d="M1201.778,576.095l14.194-9.982-10.443,11.056-2.176,2.308Z" fill="#aaa321" class="bulbc"/>
        <path id="Path_1432" data-name="Path 1432" d="M1201.778,576.095l7.007-16.014-4.84-2.586-7.4,16.115Z" fill="#f9f921" class="bulbc"/>
        <path id="Path_1433" data-name="Path 1433" d="M1208.784,560.079l7.681-13.27.033-.058.157.148,1.622,1.525-2.306,17.688Z" fill="#aaa321" class="bulbc"/>
        <path id="Path_1434" data-name="Path 1434" d="M1195.271,556.287l12.745-13.488.581,1.243-3.7,14.192Z" fill="#aaa321" class="bulbc"/>
        <path id="Path_1435" data-name="Path 1435" d="M1201.778,576.095l14.194-9.982-7.187-6.033Z" fill="#d6d234" class="bulbc"/>
        <path id="Path_1436" data-name="Path 1436" d="M1196.547,573.609l8.347-15.375-9.623-1.947Z" fill="#d6d234" class="bulbc"/>
        <path id="Path_1437" data-name="Path 1437" d="M1208.784,560.079l-3.89-1.846,3.332-12.923,7.734,3.672Z" fill="#dbd628" class="bulbc"/>
        <rect id="Rectangle_344" data-name="Rectangle 344" width="5.482" height="16.601" transform="matrix(0.429, -0.903, 0.903, 0.429, 1204.463, 543.986)" fill="#383838"/>
        <path id="Path_1438" data-name="Path 1438" d="M1772.463,591.185l4.928,4.835,4.471.372-.457-4.463-4.928-4.835-4.471-.372Z" fill="#fffc92" class="bulbc"/>
        <path id="Path_1439" data-name="Path 1439" d="M1776.477,587.094l5.306-20.014-9.777,19.642Z" fill="#aaa321" class="bulbc"/>
        <path id="Path_1440" data-name="Path 1440" d="M1781.4,591.93l19.878-5.721-16.07,8.392-3.35,1.752Z" fill="#aaa321" class="bulbc"/>
        <path id="Path_1441" data-name="Path 1441" d="M1781.4,591.93l14.129-15.315-4.447-4.8-14.608,15.277Z" fill="#f9f921" class="bulbc"/>
        <path id="Path_1442" data-name="Path 1442" d="M1795.531,576.613l13.819-11.961.06-.052.119.228,1.234,2.349-9.483,19.032Z" fill="#aaa321" class="bulbc"/>
        <path id="Path_1443" data-name="Path 1443" d="M1781.783,567.08l19.61-10.235.171,1.626-9.7,14.549Z" fill="#aaa321" class="bulbc"/>
        <path id="Path_1444" data-name="Path 1444" d="M1781.4,591.93l19.878-5.721-5.75-9.6Z" fill="#d6d234" class="bulbc"/>
        <path id="Path_1445" data-name="Path 1445" d="M1776.477,587.094l15.39-14.074-10.084-5.94Z" fill="#d6d234" class="bulbc"/>
        <path id="Path_1446" data-name="Path 1446" d="M1795.531,576.613l-3.664-3.594,8.784-13.263,7.285,7.147Z" fill="#dbd628" class="bulbc"/>
        <rect id="Rectangle_345" data-name="Rectangle 345" width="6.534" height="19.789" transform="translate(1796.927 556.799) rotate(-45.547)" fill="#383838"/>
      </g>
    </svg>
  </div>
</template>

<script>

import { Hooper, Slide, Pagination as HooperPagination } from 'hooper'
import 'hooper/dist/hooper.css'
import { gsap } from 'gsap'

export default {
  name: 'GiftShop',
  components: {
    Hooper,
    Slide,
    HooperPagination
  },

  data () {
    return {
      hooperSettings: {
        itemsToShow: 1,
        wheelControl: false,
        centerMode: false,
        breakpoints: {
          768: {
            centerMode: false,
            itemsToShow: 1,
            itemsToSlide: 1
          },
          1024: {
            centerMode: false,
            itemsToShow: 2,
            itemsToSlide: 2
          },
          1200: {
            centerMode: false,
            itemsToShow: 3,
            itemsToSlide: 3
          }
        }
      }
    }
  },
  mounted () {
    const bulbsb = document.querySelectorAll('.bulbb')
    const lightsb = gsap.timeline({
      repeat: -1,
      repeatDelay: 0.8
    })
    lightsb.to(bulbsb, { filter: 'brightness(1.5)', yoyo: true, duration: 0.5, ease: 'bounce.out', stagger: { amount: 0.2 } })

    const bulbsc = document.querySelectorAll('.bulbc')
    const lightsc = gsap.timeline({
      repeat: -1,
      repeatDelay: 0.8
    })
    lightsc.to(bulbsc, { filter: 'brightness(1.5)', yoyo: true, duration: 0.5, ease: 'bounce.out', stagger: { amount: 0.35 } })

    const bulbse = document.querySelectorAll('.bulbe')
    const lightse = gsap.timeline({
      repeat: -1,
      repeatDelay: 0.8
    })
    lightse.to(bulbse, { filter: 'brightness(1.5)', yoyo: true, duration: 0.5, ease: 'bounce.out', stagger: { amount: 0.35 } })
  }
}
</script>

<style lang="scss">
</style>
