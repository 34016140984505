<template>
  <div>
    <section class="marquee">
      <div class="row align-middle align-center">
        <div class="columns large-8 medium-9 small-11">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1480.16 195.33">
            <defs>
              <filter id="e" data-name="luminosity-invert" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse">
                <feColorMatrix values="-1 0 0 0 1 0 -1 0 0 1 0 0 -1 0 1 0 0 0 1 0"/>
              </filter>
              <mask id="d" data-name="mask" x="116.46" y="26.55" width="1369" height="115" maskUnits="userSpaceOnUse">
                <g filter="url(#e)">
                  <image width="1369" height="115" transform="translate(116.46 26.55)" xlink:href="headline-final_11.png"/>
                </g>
              </mask>
            </defs>
             <g>
              <polyline points="13.8 120.51 113.17 88.21 227.69 134.81 331.87 153.46 422.7 124.36 531.16 140.24 611.17 132.59 825.01 166.55 949.16 136.71 1237.52 164.05 1370.1 165.55 1375.34 164.18 1450.63 136.83" fill="none" stroke="#1d7c13" stroke-miterlimit="10" stroke-width="2" id="light-string"/>
              <g class="lights">
                <g class="ilum">
                  <polygon points="415.42 90.38 409.9 92.13 407.86 95.3 411.35 96.71 416.87 94.97 418.91 91.8 415.42 90.38"/>
                  <polygon points="416.87 94.97 429.61 106.77 418.91 91.8 416.87 94.97"/>
                  <polygon points="411.35 96.71 407.76 113.69 407.87 98.48 407.89 95.31 411.35 96.71"/>
                  <polygon points="411.35 96.71 417.13 113.21 422.44 111.8 416.87 94.97 411.35 96.71"/>
                  <polygon points="417.14 113.21 420.56 128.15 420.57 128.22 420.36 128.22 418.13 128.2 407.76 113.69 417.14 113.21"/>
                  <polygon points="429.61 106.77 429.47 125.33 428.2 124.82 421.24 111.91 429.61 106.77"/>
                  <polygon points="411.35 96.71 407.76 113.69 417.14 113.21 411.35 96.71"/>
                  <polygon points="416.87 94.97 421.24 111.91 429.61 106.77 416.87 94.97"/>
                  <polygon points="417.14 113.21 421.24 111.91 427.61 123.63 419.45 126.22 417.14 113.21"/>
                  <rect x="415.88" y="124.42" width="16.6" height="5.48" transform="translate(-18.6 133.97) rotate(-17.57)"/>
                </g>
                <polygon points="415.42 90.38 409.9 92.13 407.86 95.3 411.35 96.71 416.87 94.97 418.91 91.8 415.42 90.38" fill="#62d14e" class="bulb"/>
                <polygon points="416.87 94.97 429.61 106.77 418.91 91.8 416.87 94.97" fill="#5ebc48" class="bulb"/>
                <polygon points="411.35 96.71 407.76 113.69 407.87 98.48 407.89 95.31 411.35 96.71" fill="#5ebc48" class="bulb"/>
                <polygon points="411.35 96.71 417.13 113.21 422.44 111.8 416.87 94.97 411.35 96.71" fill="#79e260" class="bulb"/>
                <polygon points="417.14 113.21 420.56 128.15 420.57 128.22 420.36 128.22 418.13 128.2 407.76 113.69 417.14 113.21" fill="#5bb545" class="bulb"/>
                <polygon points="429.61 106.77 429.47 125.33 428.2 124.82 421.24 111.91 429.61 106.77" fill="#5bb545" class="bulb"/>
                <polygon points="411.35 96.71 407.76 113.69 417.14 113.21 411.35 96.71" fill="#6fce59" class="bulb"/>
                <polygon points="416.87 94.97 421.24 111.91 429.61 106.77 416.87 94.97" fill="#6fce59" class="bulb"/>
                <polygon points="417.14 113.21 421.24 111.91 427.61 123.63 419.45 126.22 417.14 113.21" fill="#6fce59" class="bulb"/>
                <rect x="415.88" y="124.42" width="16.6" height="5.48" transform="translate(-18.6 133.97) rotate(-17.57)" fill="#383838" class="bulb"/>
              </g>
              <g class="lights">
                <g class="ilum">
                  <polygon points="1060.41 179.01 1066.17 178.39 1068.79 175.7 1065.66 173.61 1059.9 174.22 1057.28 176.92 1060.41 179.01"/>
                  <polygon points="1059.9 174.22 1049.78 160.11 1057.28 176.92 1059.9 174.22"/>
                  <polygon points="1065.66 173.62 1072.56 157.7 1069.42 172.58 1068.77 175.68 1065.66 173.62"/>
                  <polygon points="1065.66 173.62 1063.29 156.3 1057.81 156.62 1059.9 174.22 1065.66 173.62"/>
                  <polygon points="1063.28 156.3 1062.91 140.97 1062.91 140.9 1063.12 140.95 1065.3 141.4 1072.56 157.7 1063.28 156.3"/>
                  <polygon points="1049.78 160.11 1053.61 141.95 1054.76 142.71 1059 156.75 1049.78 160.11"/>
                  <polygon points="1065.66 173.62 1072.56 157.7 1063.28 156.3 1065.66 173.62"/>
                  <polygon points="1059.9 174.22 1059 156.75 1049.78 160.11 1059.9 174.22"/>
                  <polygon points="1063.28 156.3 1059 156.75 1055.1 143.99 1063.62 143.09 1063.28 156.3"/>
                  <rect x="1050.87" y="138.48" width="16.6" height="5.48" transform="translate(2127.32 170.01) rotate(173.95)"/>
                  </g>
                <polygon points="1060.41 179.01 1066.17 178.39 1068.79 175.7 1065.66 173.61 1059.9 174.22 1057.28 176.92 1060.41 179.01" fill="#62d14e" class="bulb"/>
                <polygon points="1059.9 174.22 1049.78 160.11 1057.28 176.92 1059.9 174.22" fill="#5ebc48" class="bulb"/>
                <polygon points="1065.66 173.62 1072.56 157.7 1069.42 172.58 1068.77 175.68 1065.66 173.62" fill="#5ebc48" class="bulb"/>
                <polygon points="1065.66 173.62 1063.29 156.3 1057.81 156.62 1059.9 174.22 1065.66 173.62" fill="#79e260" class="bulb"/>
                <polygon points="1063.28 156.3 1062.91 140.97 1062.91 140.9 1063.12 140.95 1065.3 141.4 1072.56 157.7 1063.28 156.3" fill="#5bb545" class="bulb"/>
                <polygon points="1049.78 160.11 1053.61 141.95 1054.76 142.71 1059 156.75 1049.78 160.11" fill="#5bb545" class="bulb"/>
                <polygon points="1065.66 173.62 1072.56 157.7 1063.28 156.3 1065.66 173.62" fill="#6fce59" class="bulb"/>
                <polygon points="1059.9 174.22 1059 156.75 1049.78 160.11 1059.9 174.22" fill="#6fce59" class="bulb"/>
                <polygon points="1063.28 156.3 1059 156.75 1055.1 143.99 1063.62 143.09 1063.28 156.3" fill="#6fce59" class="bulb"/>
                <rect x="1050.87" y="138.48" width="16.6" height="5.48" transform="translate(2127.32 170.01) rotate(173.95)" fill="#383838" class="bulb"/>
              </g>
              <g class="lights">
                <g class="ilum">
                  <polygon points="1374.91 131.61 1369.12 131.82 1366.31 134.33 1369.29 136.62 1375.08 136.41 1377.89 133.9 1374.91 131.61"/>
                  <polygon points="1375.08 136.41 1384.21 151.19 1377.89 133.9 1375.08 136.41"/>
                  <polygon points="1369.29 136.62 1361.31 152.03 1365.47 137.4 1366.34 134.35 1369.29 136.62"/>
                  <polygon points="1369.29 136.62 1370.47 154.06 1375.96 154.12 1375.08 136.41 1369.29 136.62"/>
                  <polygon points="1370.47 154.06 1369.78 169.38 1369.78 169.45 1369.57 169.39 1367.43 168.78 1361.31 152.03 1370.47 154.06"/>
                  <polygon points="1384.21 151.19 1379.13 169.04 1378.04 168.2 1374.77 153.91 1384.21 151.19"/>
                  <polygon points="1369.29 136.62 1361.31 152.03 1370.47 154.06 1369.29 136.62"/>
                  <polygon points="1375.08 136.41 1374.77 153.91 1384.21 151.19 1375.08 136.41"/>
                  <polygon points="1370.47 154.06 1374.77 153.91 1377.78 166.91 1369.23 167.22 1370.47 154.06"/>
                  <rect x="1365.24" y="166.65" width="16.6" height="5.48" transform="translate(-5.27 50.32) rotate(-2.09)"/>
                </g>
                <polygon points="1374.91 131.61 1369.12 131.82 1366.31 134.33 1369.29 136.62 1375.08 136.41 1377.89 133.9 1374.91 131.61" fill="#62d14e" class="bulb"/>
                <polygon points="1375.08 136.41 1384.21 151.19 1377.89 133.9 1375.08 136.41" fill="#5ebc48" class="bulb"/>
                <polygon points="1369.29 136.62 1361.31 152.03 1365.47 137.4 1366.34 134.35 1369.29 136.62" fill="#5ebc48" class="bulb"/>
                <polygon points="1369.29 136.62 1370.47 154.06 1375.96 154.12 1375.08 136.41 1369.29 136.62" fill="#79e260" class="bulb"/>
                <polygon points="1370.47 154.06 1369.78 169.38 1369.78 169.45 1369.57 169.39 1367.43 168.78 1361.31 152.03 1370.47 154.06" fill="#5bb545" class="bulb"/>
                <polygon points="1384.21 151.19 1379.13 169.04 1378.04 168.2 1374.77 153.91 1384.21 151.19" fill="#5bb545" class="bulb"/>
                <polygon points="1369.29 136.62 1361.31 152.03 1370.47 154.06 1369.29 136.62" fill="#6fce59" class="bulb"/>
                <polygon points="1375.08 136.41 1374.77 153.91 1384.21 151.19 1375.08 136.41" fill="#6fce59" class="bulb"/>
                <polygon points="1370.47 154.06 1374.77 153.91 1377.78 166.91 1369.23 167.22 1370.47 154.06" fill="#6fce59" class="bulb"/>
                <rect x="1365.24" y="166.65" width="16.6" height="5.48" transform="translate(-5.27 50.32) rotate(-2.09)" fill="#383838" class="bulb"/>
              </g>
              <g class="lights">
                <g class="ilum">
                  <polygon points="715.17 180.61 720.6 178.61 722.49 175.36 718.94 174.1 713.51 176.1 711.62 179.35 715.17 180.61"/>
                  <polygon points="713.51 176.1 700.25 164.88 711.62 179.35 713.51 176.1"/>
                  <polygon points="718.94 174.1 721.75 156.98 722.34 172.18 722.46 175.34 718.94 174.1"/>
                  <polygon points="718.94 174.1 712.42 157.89 707.18 159.53 713.51 176.1 718.94 174.1"/>
                  <polygon points="712.41 157.89 708.32 143.11 708.3 143.05 708.51 143.04 710.74 142.95 721.75 156.98 712.41 157.89"/>
                  <polygon points="700.25 164.88 699.54 146.34 700.83 146.79 708.37 159.37 700.25 164.88"/>
                  <polygon points="718.94 174.1 721.75 156.98 712.41 157.89 718.94 174.1"/>
                  <polygon points="713.51 176.1 708.37 159.37 700.25 164.88 713.51 176.1"/>
                  <polygon points="712.41 157.89 708.37 159.37 701.48 147.95 709.51 144.99 712.41 157.89"/>
                  <rect x="696.44" y="141.53" width="16.6" height="5.48" transform="translate(1416 36.64) rotate(159.83)"/>
                </g>
                <polygon points="715.17 180.61 720.6 178.61 722.49 175.36 718.94 174.1 713.51 176.1 711.62 179.35 715.17 180.61" fill="#62d14e" class="bulb"/>
                <polygon points="713.51 176.1 700.25 164.88 711.62 179.35 713.51 176.1" fill="#5ebc48" class="bulb"/>
                <polygon points="718.94 174.1 721.75 156.98 722.34 172.18 722.46 175.34 718.94 174.1" fill="#5ebc48" class="bulb"/>
                <polygon points="718.94 174.1 712.42 157.89 707.18 159.53 713.51 176.1 718.94 174.1" fill="#79e260" class="bulb"/>
                <polygon points="712.41 157.89 708.32 143.11 708.3 143.05 708.51 143.04 710.74 142.95 721.75 156.98 712.41 157.89" fill="#5bb545" class="bulb"/>
                <polygon points="700.25 164.88 699.54 146.34 700.83 146.79 708.37 159.37 700.25 164.88" fill="#5bb545" class="bulb"/>
                <polygon points="718.94 174.1 721.75 156.98 712.41 157.89 718.94 174.1" fill="#6fce59" class="bulb"/>
                <polygon points="713.51 176.1 708.37 159.37 700.25 164.88 713.51 176.1" fill="#6fce59" class="bulb"/>
                <polygon points="712.41 157.89 708.37 159.37 701.48 147.95 709.51 144.99 712.41 157.89" fill="#6fce59" class="bulb"/>
                <rect x="696.44" y="141.53" width="16.6" height="5.48" transform="translate(1416 36.64) rotate(159.83)" fill="#383838" class="bulb"/>
              </g>
              <g class="lights">
                <g class="ilum">
                  <polygon points="110.02 44.78 103.4 45.72 100.47 48.94 104.17 51.22 110.79 50.28 113.72 47.06 110.02 44.78"/>
                  <polygon points="110.79 50.28 123.04 66.16 113.72 47.06 110.79 50.28"/>
                  <polygon points="104.17 51.21 96.85 69.86 99.87 52.56 100.5 48.96 104.17 51.21"/>
                  <polygon points="104.17 51.21 107.61 71.1 113.92 70.51 110.79 50.28 104.17 51.21"/>
                  <polygon points="107.61 71.1 108.65 88.77 108.65 88.85 108.41 88.81 105.88 88.37 96.85 69.86 107.61 71.1"/>
                  <polygon points="123.04 66.16 119.34 87.26 117.99 86.44 112.53 70.41 123.04 66.16"/>
                  <polygon points="104.17 51.21 96.85 69.86 107.61 71.1 104.17 51.21"/>
                  <polygon points="110.79 50.28 112.53 70.41 123.04 66.16 110.79 50.28"/>
                  <polygon points="107.61 71.1 112.53 70.41 117.54 84.98 107.75 86.36 107.61 71.1"/>
                  <rect x="103.38" y="85.17" width="19.17" height="6.33" transform="translate(-11.24 16.67) rotate(-8.04)"/>
                </g>
                <polygon points="110.02 44.78 103.4 45.72 100.47 48.94 104.17 51.22 110.79 50.28 113.72 47.06 110.02 44.78" fill="#62d14e" class="bulb"/>
                <polygon points="110.79 50.28 123.04 66.16 113.72 47.06 110.79 50.28" fill="#5ebc48" class="bulb"/>
                <polygon points="104.17 51.21 96.85 69.86 99.87 52.56 100.5 48.96 104.17 51.21" fill="#5ebc48" class="bulb"/>
                <polygon points="104.17 51.21 107.61 71.1 113.92 70.51 110.79 50.28 104.17 51.21" fill="#79e260" class="bulb"/>
                <polygon points="107.61 71.1 108.65 88.77 108.65 88.85 108.41 88.81 105.88 88.37 96.85 69.86 107.61 71.1" fill="#5bb545" class="bulb"/>
                <polygon points="123.04 66.16 119.34 87.26 117.99 86.44 112.53 70.41 123.04 66.16" fill="#5bb545" class="bulb"/>
                <polygon points="104.17 51.21 96.85 69.86 107.61 71.1 104.17 51.21" fill="#6fce59" class="bulb"/>
                <polygon points="110.79 50.28 112.53 70.41 123.04 66.16 110.79 50.28" fill="#6fce59" class="bulb"/>
                <polygon points="107.61 71.1 112.53 70.41 117.54 84.98 107.75 86.36 107.61 71.1" fill="#6fce59" class="bulb"/>
                <rect x="103.38" y="85.17" width="19.17" height="6.33" transform="translate(-11.24 16.67) rotate(-8.04)" fill="#383838" class="bulb"/>
              </g>
              <g class="lights">
                <g class="ilum">
                  <polygon points="631.1 106.02 626.19 102.96 622.46 103.47 623.64 107.04 628.56 110.1 632.29 109.6 631.1 106.02"/>
                  <polygon points="628.56 110.1 627.85 127.46 632.29 109.6 628.56 110.1"/>
                  <polygon points="623.64 107.04 608.41 115.34 620.04 105.54 622.47 103.5 623.64 107.04"/>
                  <polygon points="623.64 107.04 614.86 122.15 619.37 125.27 628.56 110.1 623.64 107.04"/>
                  <polygon points="614.86 122.15 605.71 134.46 605.67 134.51 605.54 134.35 604.1 132.65 608.41 115.34 614.86 122.15"/>
                  <polygon points="627.85 127.46 613.65 139.4 613.21 138.1 618.51 124.43 627.85 127.46"/>
                  <polygon points="623.64 107.04 608.41 115.34 614.86 122.15 623.64 107.04"/>
                  <polygon points="628.56 110.1 618.51 124.43 627.85 127.46 628.56 110.1"/>
                  <polygon points="614.86 122.15 618.51 124.43 613.73 136.89 606.46 132.36 614.86 122.15"/>
                  <rect x="600.52" y="133.82" width="16.6" height="5.48" transform="translate(164.43 -301.42) rotate(31.94)"/>
                </g>
                <polygon points="631.1 106.02 626.19 102.96 622.46 103.47 623.64 107.04 628.56 110.1 632.29 109.6 631.1 106.02" fill="#ed4f75" class="bulb"/>
                <polygon points="628.56 110.1 627.85 127.46 632.29 109.6 628.56 110.1" fill="#a0133c" class="bulb"/>
                <polygon points="623.64 107.04 608.41 115.34 620.04 105.54 622.47 103.5 623.64 107.04" fill="#a0133c" class="bulb"/>
                <polygon points="623.64 107.04 614.86 122.15 619.37 125.27 628.56 110.1 623.64 107.04" fill="#fc1f49" class="bulb"/>
                <polygon points="614.86 122.15 605.71 134.46 605.67 134.51 605.54 134.35 604.1 132.65 608.41 115.34 614.86 122.15" fill="#a0133c" class="bulb"/>
                <polygon points="627.85 127.46 613.65 139.4 613.21 138.1 618.51 124.43 627.85 127.46" fill="#a0133c" class="bulb"/>
                <polygon points="623.64 107.04 608.41 115.34 614.86 122.15 623.64 107.04" fill="#d61241" class="bulb"/>
                <polygon points="628.56 110.1 618.51 124.43 627.85 127.46 628.56 110.1" fill="#d61241" class="bulb"/>
                <polygon points="614.86 122.15 618.51 124.43 613.73 136.89 606.46 132.36 614.86 122.15" fill="#ed4f75" class="bulb"/>
                <rect x="600.52" y="133.82" width="16.6" height="5.48" transform="translate(164.43 -301.42) rotate(31.94)" fill="#383838" class="bulb"/>
              </g>
              <g class="lights">
                <g class="ilum">
                  <polygon points="1269.26 195.33 1274.53 192.94 1276.18 189.56 1272.55 188.56 1267.27 190.95 1265.63 194.33 1269.26 195.33"/>
                  <polygon points="1267.27 190.95 1253.23 180.72 1265.63 194.33 1267.27 190.95"/>
                  <polygon points="1272.55 188.56 1274.11 171.28 1275.79 186.39 1276.15 189.55 1272.55 188.56"/>
                  <polygon points="1272.55 188.56 1264.86 172.86 1259.76 174.89 1267.27 190.95 1272.55 188.56"/>
                  <polygon points="1264.86 172.86 1259.7 158.43 1259.67 158.36 1259.89 158.34 1262.1 158.09 1274.11 171.28 1264.86 172.86"/>
                  <polygon points="1253.23 180.72 1251.18 162.28 1252.5 162.64 1260.94 174.64 1253.23 180.72"/>
                  <polygon points="1272.55 188.56 1274.11 171.28 1264.86 172.86 1272.55 188.56"/>
                  <polygon points="1267.27 190.95 1260.94 174.64 1253.23 180.72 1267.27 190.95"/>
                  <polygon points="1264.86 172.86 1260.94 174.64 1253.23 163.75 1261.03 160.22 1264.86 172.86"/>
                  <rect x="1247.92" y="157.1" width="16.6" height="5.48" transform="translate(2466.63 -212.35) rotate(155.66)"/>
                </g>
                <polygon points="1269.26 195.33 1274.53 192.94 1276.18 189.56 1272.55 188.56 1267.27 190.95 1265.63 194.33 1269.26 195.33" fill="#ed4f75" class="bulb"/>
                <polygon points="1267.27 190.95 1253.23 180.72 1265.63 194.33 1267.27 190.95" fill="#a0133c" class="bulb"/>
                <polygon points="1272.55 188.56 1274.11 171.28 1275.79 186.39 1276.15 189.55 1272.55 188.56" fill="#a0133c" class="bulb"/>
                <polygon points="1272.55 188.56 1264.86 172.86 1259.76 174.89 1267.27 190.95 1272.55 188.56" fill="#fc1f49" class="bulb"/>
                <polygon points="1264.86 172.86 1259.7 158.43 1259.67 158.36 1259.89 158.34 1262.1 158.09 1274.11 171.28 1264.86 172.86" fill="#a0133c" class="bulb"/>
                <polygon points="1253.23 180.72 1251.18 162.28 1252.5 162.64 1260.94 174.64 1253.23 180.72" fill="#a0133c" class="bulb"/>
                <polygon points="1272.55 188.56 1274.11 171.28 1264.86 172.86 1272.55 188.56" fill="#d61241" class="bulb"/>
                <polygon points="1267.27 190.95 1260.94 174.64 1253.23 180.72 1267.27 190.95" fill="#d61241" class="bulb"/>
                <polygon points="1264.86 172.86 1260.94 174.64 1253.23 163.75 1261.03 160.22 1264.86 172.86" fill="#ed4f75" class="bulb"/>
                <rect x="1247.92" y="157.1" width="16.6" height="5.48" transform="translate(2466.63 -212.35) rotate(155.66)" fill="#383838" class="bulb"/>
              </g>
              <g class="lights">
                <g class="ilum">
                  <polygon points="936.85 104.46 931.97 107.58 930.83 111.17 934.57 111.63 939.45 108.51 940.59 104.92 936.85 104.46"/>
                  <polygon points="939.45 108.51 954.82 116.59 940.59 104.92 939.45 108.51"/>
                  <polygon points="934.57 111.63 935.53 128.96 931.67 114.24 930.87 111.18 934.57 111.63"/>
                  <polygon points="934.57 111.63 944.45 126.05 949.2 123.31 939.45 108.51 934.57 111.63"/>
                  <polygon points="944.45 126.05 951.64 139.59 951.67 139.65 951.47 139.7 949.31 140.27 935.53 128.96 944.45 126.05"/>
                  <polygon points="954.82 116.59 959.52 134.54 958.16 134.38 948.07 123.73 954.82 116.59"/>
                  <polygon points="934.57 111.63 935.53 128.96 944.45 126.05 934.57 111.63"/>
                  <polygon points="939.45 108.51 948.07 123.73 954.82 116.59 939.45 108.51"/>
                  <polygon points="944.45 126.05 948.07 123.73 957.28 133.39 950.07 138.01 944.45 126.05"/>
                  <rect x="946.58" y="134.95" width="16.6" height="5.48" transform="translate(76.63 536.98) rotate(-32.65)"/>
                </g>
                <polygon points="936.85 104.46 931.97 107.58 930.83 111.17 934.57 111.63 939.45 108.51 940.59 104.92 936.85 104.46" fill="#ed4f75" class="bulb"/>
                <polygon points="939.45 108.51 954.82 116.59 940.59 104.92 939.45 108.51" fill="#a0133c" class="bulb"/>
                <polygon points="934.57 111.63 935.53 128.96 931.67 114.24 930.87 111.18 934.57 111.63" fill="#a0133c" class="bulb"/>
                <polygon points="934.57 111.63 944.45 126.05 949.2 123.31 939.45 108.51 934.57 111.63" fill="#fc1f49" class="bulb"/>
                <polygon points="944.45 126.05 951.64 139.59 951.67 139.65 951.47 139.7 949.31 140.27 935.53 128.96 944.45 126.05" fill="#a0133c" class="bulb"/>
                <polygon points="954.82 116.59 959.52 134.54 958.16 134.38 948.07 123.73 954.82 116.59" fill="#a0133c" class="bulb"/>
                <polygon points="934.57 111.63 935.53 128.96 944.45 126.05 934.57 111.63" fill="#d61241" class="bulb"/>
                <polygon points="939.45 108.51 948.07 123.73 954.82 116.59 939.45 108.51" fill="#d61241" class="bulb"/>
                <polygon points="944.45 126.05 948.07 123.73 957.28 133.39 950.07 138.01 944.45 126.05" fill="#ed4f75" class="bulb"/>
                <rect x="946.58" y="134.95" width="16.6" height="5.48" transform="translate(76.63 536.98) rotate(-32.65)" fill="#383838" class="bulb"/>
              </g>
              <g class="lights">
                <g class="ilum">
                  <polygon points="1.53 149.04 6.72 151.6 10.39 150.73 8.85 147.29 3.66 144.72 0 145.6 1.53 149.04"/>
                  <polygon points="3.66 144.72 2.66 127.38 0 145.6 3.66 144.72"/>
                  <polygon points="8.85 147.29 23.2 137.53 12.59 148.42 10.37 150.7 8.85 147.29"/>
                  <polygon points="8.85 147.29 16.11 131.39 11.31 128.73 3.66 144.72 8.85 147.29"/>
                  <polygon points="16.11 131.39 24 118.24 24.03 118.18 24.19 118.33 25.78 119.88 23.2 137.53 16.11 131.39"/>
                  <polygon points="2.66 127.38 15.61 114.1 16.17 115.35 12.25 129.48 2.66 127.38"/>
                  <polygon points="8.85 147.29 23.2 137.53 16.11 131.39 8.85 147.29"/>
                  <polygon points="3.66 144.72 12.25 129.48 2.66 127.38 3.66 144.72"/>
                  <polygon points="16.11 131.39 12.25 129.48 15.78 116.61 23.46 120.4 16.11 131.39"/>
                  <rect x="12.4" y="113.71" width="16.6" height="5.48" transform="translate(-12.33 230.02) rotate(-153.71)"/>
                </g>
                <polygon points="1.53 149.04 6.72 151.6 10.39 150.73 8.85 147.29 3.66 144.72 0 145.6 1.53 149.04" fill="#ed4f75" class="bulb"/>
                <polygon points="3.66 144.72 2.66 127.38 0 145.6 3.66 144.72" fill="#a0133c" class="bulb"/>
                <polygon points="8.85 147.29 23.2 137.53 12.59 148.42 10.37 150.7 8.85 147.29" fill="#a0133c" class="bulb"/>
                <polygon points="8.85 147.29 16.11 131.39 11.31 128.73 3.66 144.72 8.85 147.29" fill="#fc1f49" class="bulb"/>
                <polygon points="16.11 131.39 24 118.24 24.03 118.18 24.19 118.33 25.78 119.88 23.2 137.53 16.11 131.39" fill="#a0133c" class="bulb"/>
                <polygon points="2.66 127.38 15.61 114.1 16.17 115.35 12.25 129.48 2.66 127.38" fill="#a0133c" class="bulb"/>
                <polygon points="8.85 147.29 23.2 137.53 16.11 131.39 8.85 147.29" fill="#d61241" class="bulb"/>
                <polygon points="3.66 144.72 12.25 129.48 2.66 127.38 3.66 144.72" fill="#d61241" class="bulb"/>
                <polygon points="16.11 131.39 12.25 129.48 15.78 116.61 23.46 120.4 16.11 131.39" fill="#ed4f75" class="bulb"/>
                <rect x="12.4" y="113.71" width="16.6" height="5.48" transform="translate(-12.33 230.02) rotate(-153.71)" fill="#383838" class="bulb"/>
              </g>
              <g class="lights">
                <g class="ilum">
                  <polygon points="313.07 185.64 318.98 188.73 323.21 187.82 321.54 183.82 315.63 180.74 311.4 181.64 313.07 185.64"/>
                  <polygon points="315.63 180.74 314.94 160.77 311.4 181.64 315.63 180.74"/>
                  <polygon points="321.54 183.83 338.3 172.98 325.8 185.23 323.2 187.78 321.54 183.83"/>
                  <polygon points="321.54 183.83 330.3 165.73 324.85 162.54 315.63 180.74 321.54 183.83"/>
                  <polygon points="330.3 165.72 339.72 150.81 339.76 150.74 339.94 150.92 341.73 152.75 338.3 172.98 330.3 165.72"/>
                  <polygon points="314.94 160.77 330.19 145.82 330.8 147.28 325.91 163.43 314.94 160.77"/>
                  <polygon points="321.54 183.83 338.3 172.98 330.3 165.72 321.54 183.83"/>
                  <polygon points="315.63 180.74 325.91 163.43 314.94 160.77 315.63 180.74"/>
                  <polygon points="330.3 165.72 325.91 163.43 330.32 148.72 339.04 153.28 330.3 165.72"/>
                  <rect x="326.42" y="145.51" width="19.1" height="6.31" transform="translate(564.84 436.06) rotate(-152.4)"/>
                </g>
                <polygon points="313.07 185.64 318.98 188.73 323.21 187.82 321.54 183.82 315.63 180.74 311.4 181.64 313.07 185.64" fill="#ed4f75" class="bulb"/>
                <polygon points="315.63 180.74 314.94 160.77 311.4 181.64 315.63 180.74" fill="#a0133c" class="bulb"/>
                <polygon points="321.54 183.83 338.3 172.98 325.8 185.23 323.2 187.78 321.54 183.83" fill="#a0133c" class="bulb"/>
                <polygon points="321.54 183.83 330.3 165.73 324.85 162.54 315.63 180.74 321.54 183.83" fill="#fc1f49" class="bulb"/>
                <polygon points="330.3 165.72 339.72 150.81 339.76 150.74 339.94 150.92 341.73 152.75 338.3 172.98 330.3 165.72" fill="#a0133c" class="bulb"/>
                <polygon points="314.94 160.77 330.19 145.82 330.8 147.28 325.91 163.43 314.94 160.77" fill="#a0133c" class="bulb"/>
                <polygon points="321.54 183.83 338.3 172.98 330.3 165.72 321.54 183.83" fill="#d61241" class="bulb"/>
                <polygon points="315.63 180.74 325.91 163.43 314.94 160.77 315.63 180.74" fill="#d61241" class="bulb"/>
                <polygon points="330.3 165.72 325.91 163.43 330.32 148.72 339.04 153.28 330.3 165.72" fill="#ed4f75" class="bulb"/>
                <rect x="326.42" y="145.51" width="19.1" height="6.31" transform="translate(564.84 436.06) rotate(-152.4)" fill="#383838" class="bulb"/>
              </g>
              <g class="lights">
                <g class="ilum">
                  <polygon points="528.84 109.97 523.3 111.66 521.23 114.8 524.7 116.26 530.24 114.57 532.31 111.42 528.84 109.97"/>
                  <polygon points="530.24 114.57 542.85 126.51 532.31 111.42 530.24 114.57"/>
                  <polygon points="524.7 116.25 520.94 133.19 521.2 117.99 521.26 114.82 524.7 116.25"/>
                  <polygon points="524.7 116.25 530.31 132.81 535.63 131.46 530.24 114.57 524.7 116.25"/>
                  <polygon points="530.31 132.81 533.58 147.79 533.59 147.85 533.38 147.85 531.15 147.82 520.94 133.19 530.31 132.81"/>
                  <polygon points="542.85 126.51 542.52 145.06 541.26 144.53 534.43 131.55 542.85 126.51"/>
                  <polygon points="524.7 116.25 520.94 133.19 530.31 132.81 524.7 116.25"/>
                  <polygon points="530.24 114.57 534.43 131.55 542.85 126.51 530.24 114.57"/>
                  <polygon points="530.31 132.81 534.43 131.55 540.68 143.35 532.49 145.84 530.31 132.81"/>
                  <rect x="528.91" y="144.09" width="16.6" height="5.48" transform="translate(-19.47 163.15) rotate(-16.97)"/>
                </g>
                <polygon points="528.84 109.97 523.3 111.66 521.23 114.8 524.7 116.26 530.24 114.57 532.31 111.42 528.84 109.97" fill="#fffc92" class="bulb"/>
                <polygon points="530.24 114.57 542.85 126.51 532.31 111.42 530.24 114.57" fill="#aaa321" class="bulb"/>
                <polygon points="524.7 116.25 520.94 133.19 521.2 117.99 521.26 114.82 524.7 116.25" fill="#aaa321" class="bulb"/>
                <polygon points="524.7 116.25 530.31 132.81 535.63 131.46 530.24 114.57 524.7 116.25" fill="#f9f921" class="bulb"/>
                <polygon points="530.31 132.81 533.58 147.79 533.59 147.85 533.38 147.85 531.15 147.82 520.94 133.19 530.31 132.81" fill="#aaa321" class="bulb"/>
                <polygon points="542.85 126.51 542.52 145.06 541.26 144.53 534.43 131.55 542.85 126.51" fill="#aaa321" class="bulb"/>
                <polygon points="524.7 116.25 520.94 133.19 530.31 132.81 524.7 116.25" fill="#d6d234" class="bulb"/>
                <polygon points="530.24 114.57 534.43 131.55 542.85 126.51 530.24 114.57" fill="#d6d234" class="bulb"/>
                <polygon points="530.31 132.81 534.43 131.55 540.68 143.35 532.49 145.84 530.31 132.81" fill="#dbd628" class="bulb"/>
                <rect x="528.91" y="144.09" width="16.6" height="5.48" transform="translate(-19.47 163.15) rotate(-16.97)" fill="#383838" class="bulb"/>
              </g>
              <g class="lights">
                <g class="ilum">
                  <polygon points="1176.61 129.79 1171.24 127.63 1167.65 128.78 1169.44 132.09 1174.82 134.25 1178.4 133.1 1176.61 129.79"/>
                  <polygon points="1174.82 134.25 1177.15 151.47 1178.4 133.1 1174.82 134.25"/>
                  <polygon points="1169.45 132.09 1155.89 142.92 1165.64 131.25 1167.67 128.81 1169.45 132.09"/>
                  <polygon points="1169.45 132.09 1163.43 148.5 1168.42 150.79 1174.82 134.25 1169.45 132.09"/>
                  <polygon points="1163.43 148.51 1156.58 162.22 1156.55 162.28 1156.38 162.14 1154.67 160.72 1155.89 142.92 1163.43 148.51"/>
                  <polygon points="1177.15 151.47 1165.25 165.71 1164.6 164.5 1167.43 150.11 1177.15 151.47"/>
                  <polygon points="1169.45 132.09 1155.89 142.92 1163.43 148.51 1169.45 132.09"/>
                  <polygon points="1174.82 134.25 1167.43 150.11 1177.15 151.47 1174.82 134.25"/>
                  <polygon points="1163.43 148.51 1167.43 150.11 1164.89 163.21 1156.95 160.02 1163.43 148.51"/>
                  <rect x="1151.7" y="161.01" width="16.6" height="5.48" transform="translate(144.7 -420.69) rotate(21.89)"/>
                </g>
                <polygon points="1176.61 129.79 1171.24 127.63 1167.65 128.78 1169.44 132.09 1174.82 134.25 1178.4 133.1 1176.61 129.79" fill="#fffc92" class="bulb"/>
                <polygon points="1174.82 134.25 1177.15 151.47 1178.4 133.1 1174.82 134.25" fill="#aaa321" class="bulb"/>
                <polygon points="1169.45 132.09 1155.89 142.92 1165.64 131.25 1167.67 128.81 1169.45 132.09" fill="#aaa321" class="bulb"/>
                <polygon points="1169.45 132.09 1163.43 148.5 1168.42 150.79 1174.82 134.25 1169.45 132.09" fill="#f9f921" class="bulb"/>
                <polygon points="1163.43 148.51 1156.58 162.22 1156.55 162.28 1156.38 162.14 1154.67 160.72 1155.89 142.92 1163.43 148.51" fill="#aaa321" class="bulb"/>
                <polygon points="1177.15 151.47 1165.25 165.71 1164.6 164.5 1167.43 150.11 1177.15 151.47" fill="#aaa321" class="bulb"/>
                <polygon points="1169.45 132.09 1155.89 142.92 1163.43 148.51 1169.45 132.09" fill="#d6d234" class="bulb"/>
                <polygon points="1174.82 134.25 1167.43 150.11 1177.15 151.47 1174.82 134.25" fill="#d6d234" class="bulb"/>
                <polygon points="1163.43 148.51 1167.43 150.11 1164.89 163.21 1156.95 160.02 1163.43 148.51" fill="#dbd628" class="bulb"/>
                <rect x="1151.7" y="161.01" width="16.6" height="5.48" transform="translate(144.7 -420.69) rotate(21.89)" fill="#383838" class="bulb"/>
              </g>
              <g class="lights">
                <g class="ilum">
                  <polygon points="837.84 134.51 832.67 131.88 829 132.72 830.49 136.17 835.66 138.8 839.33 137.96 837.84 134.51"/>
                  <polygon points="835.66 138.8 836.47 156.15 839.33 137.96 835.66 138.8"/>
                  <polygon points="830.5 136.17 816.04 145.77 826.78 135 829.01 132.75 830.5 136.17"/>
                  <polygon points="830.5 136.17 823.06 151.99 827.83 154.71 835.66 138.8 830.5 136.17"/>
                  <polygon points="823.06 151.99 815.03 165.05 814.99 165.11 814.84 164.96 813.26 163.39 816.04 145.77 823.06 151.99"/>
                  <polygon points="836.47 156.15 823.37 169.29 822.82 168.03 826.9 153.94 836.47 156.15"/>
                  <polygon points="830.5 136.17 816.04 145.77 823.06 151.99 830.5 136.17"/>
                  <polygon points="835.66 138.8 826.9 153.94 836.47 156.15 835.66 138.8"/>
                  <polygon points="823.06 151.99 826.9 153.94 823.22 166.77 815.59 162.89 823.06 151.99"/>
                  <rect x="810.01" y="164.14" width="16.6" height="5.48" transform="translate(164.33 -352.53) rotate(26.93)"/>
                </g>
                <polygon points="837.84 134.51 832.67 131.88 829 132.72 830.49 136.17 835.66 138.8 839.33 137.96 837.84 134.51" fill="#fffc92" class="bulb"/>
                <polygon points="835.66 138.8 836.47 156.15 839.33 137.96 835.66 138.8" fill="#aaa321" class="bulb"/>
                <polygon points="830.5 136.17 816.04 145.77 826.78 135 829.01 132.75 830.5 136.17" fill="#aaa321" class="bulb"/>
                <polygon points="830.5 136.17 823.06 151.99 827.83 154.71 835.66 138.8 830.5 136.17" fill="#f9f921" class="bulb"/>
                <polygon points="823.06 151.99 815.03 165.05 814.99 165.11 814.84 164.96 813.26 163.39 816.04 145.77 823.06 151.99" fill="#aaa321" class="bulb"/>
                <polygon points="836.47 156.15 823.37 169.29 822.82 168.03 826.9 153.94 836.47 156.15" fill="#aaa321" class="bulb"/>
                <polygon points="830.5 136.17 816.04 145.77 823.06 151.99 830.5 136.17" fill="#d6d234" class="bulb"/>
                <polygon points="835.66 138.8 826.9 153.94 836.47 156.15 835.66 138.8" fill="#d6d234" class="bulb"/>
                <polygon points="823.06 151.99 826.9 153.94 823.22 166.77 815.59 162.89 823.06 151.99" fill="#dbd628" class="bulb"/>
                <rect x="810.01" y="164.14" width="16.6" height="5.48" transform="translate(164.33 -352.53) rotate(26.93)" fill="#383838" class="bulb"/>
              </g>
              <g class="lights">
                <g class="ilum">
                  <polygon points="260.42 105.76 255.63 100.79 251.17 100.3 251.5 104.78 256.3 109.74 260.76 110.23 260.42 105.76"/>
                  <polygon points="256.3 109.74 250.46 129.61 260.76 110.23 256.3 109.74"/>
                  <polygon points="251.51 104.78 231.48 109.96 247.77 102 251.17 100.34 251.51 104.78"/>
                  <polygon points="251.51 104.78 236.97 119.71 241.29 124.62 256.3 109.74 251.51 104.78"/>
                  <polygon points="236.97 119.71 222.84 131.29 222.77 131.34 222.66 131.11 221.49 128.73 231.48 109.96 236.97 119.71"/>
                  <polygon points="250.46 129.61 230.58 139.31 230.45 137.68 240.54 123.4 250.46 129.61"/>
                  <polygon points="251.51 104.78 231.48 109.96 236.97 119.71 251.51 104.78"/>
                  <polygon points="256.3 109.74 240.54 123.4 250.46 129.61 256.3 109.74"/>
                  <polygon points="236.97 119.71 240.54 123.4 231.4 136.42 224.31 129.08 236.97 119.71"/>
                  <rect x="215.93" y="131.36" width="19.79" height="6.53" transform="translate(165.76 -121.32) rotate(45.99)"/>
                </g>
                <polygon points="260.42 105.76 255.63 100.79 251.17 100.3 251.5 104.78 256.3 109.74 260.76 110.23 260.42 105.76" fill="#fffc92" class="bulb"/>
                <polygon points="256.3 109.74 250.46 129.61 260.76 110.23 256.3 109.74" fill="#aaa321" class="bulb"/>
                <polygon points="251.51 104.78 231.48 109.96 247.77 102 251.17 100.34 251.51 104.78" fill="#aaa321" class="bulb"/>
                <polygon points="251.51 104.78 236.97 119.71 241.29 124.62 256.3 109.74 251.51 104.78" fill="#f9f921" class="bulb"/>
                <polygon points="236.97 119.71 222.84 131.29 222.77 131.34 222.66 131.11 221.49 128.73 231.48 109.96 236.97 119.71" fill="#aaa321" class="bulb"/>
                <polygon points="250.46 129.61 230.58 139.31 230.45 137.68 240.54 123.4 250.46 129.61" fill="#aaa321" class="bulb"/>
                <polygon points="251.51 104.78 231.48 109.96 236.97 119.71 251.51 104.78" fill="#d6d234" class="bulb"/>
                <polygon points="256.3 109.74 240.54 123.4 250.46 129.61 256.3 109.74" fill="#d6d234" class="bulb"/>
                <polygon points="236.97 119.71 240.54 123.4 231.4 136.42 224.31 129.08 236.97 119.71" fill="#dbd628" class="bulb"/>
                <rect x="215.93" y="131.36" width="19.79" height="6.53" transform="translate(165.76 -121.32) rotate(45.99)" fill="#383838" class="bulb"/>
              </g>
            </g>
            <g isolation="isolate">
              <g id="b" data-name="Layer 2">
                <g id="c" data-name="Layer 1">
                  <g>
                    <g>
                      <path d="M0,118.51V41.78C0,40.8,.44,39.87,1.2,39.25h0c2.13-1.74,5.32-.22,5.32,2.53v23.7c0,1.38,.87,2.61,2.17,3.07l41.34,14.7s5.56,2.18,5.56,7.49v37.22c0,.37-.06,.74-.18,1.08-.6,1.69-2.85,6.4-9.35,6.87-.41,.03-.81-.04-1.2-.16L2.24,121.61C.9,121.17,0,119.92,0,118.51Z" fill="#376ced"/>
                      <path d="M0,32.06V120.87l6.53,2.06v-37.78l43.51-15.47s5.56-2.18,5.56-7.49V24.48s-1.69-7.25-10.15-7.49L0,32.06H0Z" fill="#829bf9"/>
                    </g>
                    <g>
                      <g>
                        <path d="M143.11,114.75V45.13h-24.49v-14.41h64.82v14.41h-24.49V114.75h-15.84Z" fill="#ff4b4b"/>
                        <path d="M194.72,114.75V30.72h15.12v31.81h2.16c.64-1.28,1.64-2.56,3-3.84,1.36-1.28,3.18-2.34,5.46-3.18,2.28-.84,5.18-1.26,8.7-1.26,4.64,0,8.7,1.06,12.18,3.18,3.48,2.12,6.18,5.04,8.1,8.76,1.92,3.72,2.88,8.06,2.88,13.02v35.53h-15.12v-34.33c0-4.48-1.1-7.84-3.3-10.08-2.2-2.24-5.34-3.36-9.42-3.36-4.64,0-8.24,1.54-10.8,4.62-2.56,3.08-3.84,7.38-3.84,12.9v30.25h-15.12Z" fill="#ff4b4b"/>
                        <path d="M293.39,116.43c-5.92,0-11.14-1.26-15.67-3.78-4.52-2.52-8.04-6.08-10.56-10.68-2.52-4.6-3.78-10.02-3.78-16.26v-1.44c0-6.24,1.24-11.66,3.72-16.27,2.48-4.6,5.96-8.16,10.44-10.68,4.48-2.52,9.68-3.78,15.6-3.78s10.92,1.3,15.24,3.9c4.32,2.6,7.68,6.2,10.08,10.8,2.4,4.6,3.6,9.95,3.6,16.03v5.16h-43.33c.16,4.08,1.68,7.4,4.56,9.96,2.88,2.56,6.4,3.84,10.56,3.84s7.36-.92,9.36-2.76c2-1.84,3.52-3.88,4.56-6.12l12.36,6.48c-1.12,2.08-2.74,4.34-4.86,6.78-2.12,2.44-4.94,4.52-8.46,6.24-3.52,1.72-8,2.58-13.44,2.58Zm-14.52-38.29h27.85c-.32-3.44-1.7-6.2-4.14-8.28-2.44-2.08-5.62-3.12-9.54-3.12s-7.32,1.04-9.72,3.12c-2.4,2.08-3.88,4.84-4.44,8.28Z" fill="#ff4b4b"/>
                        <path d="M363.14,114.75V30.72h15.84v34.69h31.21V30.72h15.85V114.75h-15.85v-34.93h-31.21v34.93h-15.84Z" fill="#ff4b4b"/>
                        <path d="M465.89,116.43c-5.92,0-11.24-1.2-15.96-3.6-4.72-2.4-8.44-5.88-11.16-10.44-2.72-4.56-4.08-10.04-4.08-16.44v-1.92c0-6.4,1.36-11.88,4.08-16.45,2.72-4.56,6.44-8.04,11.16-10.44,4.72-2.4,10.04-3.6,15.96-3.6s11.24,1.2,15.96,3.6c4.72,2.4,8.44,5.88,11.16,10.44,2.72,4.56,4.08,10.04,4.08,16.45v1.92c0,6.4-1.36,11.88-4.08,16.44-2.72,4.56-6.44,8.04-11.16,10.44-4.72,2.4-10.04,3.6-15.96,3.6Zm0-13.44c4.64,0,8.48-1.5,11.52-4.5,3.04-3,4.56-7.3,4.56-12.9v-1.2c0-5.6-1.5-9.9-4.5-12.9-3-3-6.86-4.5-11.58-4.5s-8.48,1.5-11.52,4.5c-3.04,3-4.56,7.3-4.56,12.9v1.2c0,5.6,1.52,9.9,4.56,12.9,3.04,3,6.88,4.5,11.52,4.5Z" fill="#ff4b4b"/>
                        <path d="M506.22,114.75V30.72h15.12V114.75h-15.12Z" fill="#ff4b4b"/>
                        <path d="M543.31,48.25c-2.72,0-5.02-.88-6.9-2.64-1.88-1.76-2.82-4.08-2.82-6.96s.94-5.2,2.82-6.96c1.88-1.76,4.18-2.64,6.9-2.64s5.12,.88,6.96,2.64c1.84,1.76,2.76,4.08,2.76,6.96s-.92,5.2-2.76,6.96c-1.84,1.76-4.16,2.64-6.96,2.64Zm-7.56,66.5V55.21h15.12v59.54h-15.12Z" fill="#ff4b4b"/>
                        <path d="M586.88,116.43c-4.72,0-9.14-1.18-13.26-3.54-4.12-2.36-7.42-5.82-9.9-10.38-2.48-4.56-3.72-10.08-3.72-16.56v-1.92c0-6.48,1.24-12,3.72-16.56,2.48-4.56,5.76-8.02,9.84-10.38,4.08-2.36,8.52-3.54,13.32-3.54,3.6,0,6.62,.42,9.06,1.26,2.44,.84,4.42,1.9,5.94,3.18,1.52,1.28,2.68,2.64,3.48,4.08h2.16V30.72h15.12V114.75h-14.88v-7.2h-2.16c-1.36,2.24-3.46,4.28-6.3,6.12-2.84,1.84-6.98,2.76-12.42,2.76Zm4.56-13.2c4.64,0,8.52-1.5,11.64-4.5s4.68-7.38,4.68-13.14v-1.2c0-5.76-1.54-10.14-4.62-13.14-3.08-3-6.98-4.5-11.7-4.5s-8.52,1.5-11.64,4.5c-3.12,3-4.68,7.38-4.68,13.14v1.2c0,5.76,1.56,10.14,4.68,13.14s7,4.5,11.64,4.5Z" fill="#ff4b4b"/>
                        <path d="M634.41,90.98v-13.68h35.53v13.68h-35.53Z" fill="#ff4b4b"/>
                        <path d="M674.51,114.75l22.09-84.03h27.61l22.09,84.03h-16.32l-4.56-18.49h-30.01l-4.56,18.49h-16.32Zm24.61-33.13h22.57l-10.2-40.93h-2.16l-10.2,40.93Z" fill="#ff4b4b"/>
                        <path d="M766.37,114.75V30.72h15.84V114.75h-15.84Z" fill="#ff4b4b"/>
                        <path d="M857.6,116.43c-5.76,0-10.98-1.3-15.67-3.9s-8.4-6.4-11.16-11.4c-2.76-5-4.14-11.1-4.14-18.3v-20.17c0-10.8,3.04-19.11,9.12-24.91,6.08-5.8,14.32-8.7,24.73-8.7s18.31,2.74,23.95,8.22c5.64,5.48,8.46,12.9,8.46,22.27v.48h-15.61v-.96c0-2.96-.62-5.64-1.86-8.04-1.24-2.4-3.1-4.3-5.58-5.7-2.48-1.4-5.6-2.1-9.36-2.1-5.6,0-10,1.72-13.2,5.16-3.2,3.44-4.8,8.12-4.8,14.04v20.65c0,5.84,1.6,10.54,4.8,14.1,3.2,3.56,7.68,5.34,13.44,5.34s9.96-1.52,12.6-4.56c2.64-3.04,3.96-6.88,3.96-11.52v-1.2h-19.93v-13.45h35.53v42.97h-14.64v-8.04h-2.16c-.56,1.36-1.46,2.8-2.7,4.32-1.24,1.52-3.1,2.8-5.58,3.84-2.48,1.04-5.88,1.56-10.2,1.56Z" fill="#ff4b4b"/>
                        <path d="M913.65,48.25c-2.72,0-5.02-.88-6.9-2.64-1.88-1.76-2.82-4.08-2.82-6.96s.94-5.2,2.82-6.96c1.88-1.76,4.18-2.64,6.9-2.64s5.12,.88,6.96,2.64c1.84,1.76,2.76,4.08,2.76,6.96s-.92,5.2-2.76,6.96c-1.84,1.76-4.16,2.64-6.96,2.64Zm-7.56,66.5V55.21h15.12v59.54h-15.12Z" fill="#ff4b4b"/>
                        <path d="M945.46,114.75v-47.05h-15.12v-12.48h15.12v-11.04c0-4.08,1.22-7.34,3.66-9.78,2.44-2.44,5.62-3.66,9.54-3.66h15.61v12.48h-10.32c-2.24,0-3.36,1.2-3.36,3.6v8.4h15.61v12.48h-15.61v47.05h-15.12Z" fill="#ff4b4b"/>
                        <path d="M1009.56,114.75c-3.92,0-7.1-1.22-9.54-3.66-2.44-2.44-3.66-5.7-3.66-9.78v-33.61h-14.88v-12.48h14.88v-18.49h15.12v18.49h16.33v12.48h-16.33v30.97c0,2.4,1.12,3.6,3.36,3.6h11.52v12.48h-16.8Z" fill="#ff4b4b"/>
                        <path d="M1069.1,114.75V30.72h52.82v14.41h-36.97v20.29h34.09v14.4h-34.09v34.93h-15.84Z" fill="#ff4b4b"/>
                        <path d="M1148.8,116.43c-4.24,0-8.04-.74-11.4-2.22-3.36-1.48-6.02-3.64-7.98-6.48-1.96-2.84-2.94-6.3-2.94-10.38s.98-7.5,2.94-10.26c1.96-2.76,4.68-4.84,8.16-6.24,3.48-1.4,7.46-2.1,11.94-2.1h16.33v-3.36c0-2.8-.88-5.1-2.64-6.9-1.76-1.8-4.56-2.7-8.4-2.7s-6.56,.86-8.4,2.58c-1.84,1.72-3.04,3.94-3.6,6.66l-13.92-4.68c.96-3.04,2.5-5.82,4.62-8.34,2.12-2.52,4.96-4.56,8.52-6.12,3.56-1.56,7.9-2.34,13.02-2.34,7.84,0,14.04,1.96,18.61,5.88,4.56,3.92,6.84,9.6,6.84,17.04v22.21c0,2.4,1.12,3.6,3.36,3.6h4.8v12.48h-10.08c-2.96,0-5.4-.72-7.32-2.16-1.92-1.44-2.88-3.36-2.88-5.76v-.12h-2.28c-.32,.96-1.04,2.22-2.16,3.78-1.12,1.56-2.88,2.94-5.28,4.14-2.4,1.2-5.68,1.8-9.84,1.8Zm2.64-12.24c4.24,0,7.7-1.18,10.38-3.54,2.68-2.36,4.02-5.5,4.02-9.42v-1.2h-15.25c-2.8,0-5,.6-6.6,1.8-1.6,1.2-2.4,2.88-2.4,5.04s.84,3.92,2.52,5.28,4.12,2.04,7.32,2.04Z" fill="#ff4b4b"/>
                        <path d="M1224.06,116.43c-5.76,0-10.98-1.2-15.67-3.6-4.68-2.4-8.38-5.88-11.1-10.44-2.72-4.56-4.08-10.08-4.08-16.56v-1.68c0-6.48,1.36-12,4.08-16.57,2.72-4.56,6.42-8.04,11.1-10.44,4.68-2.4,9.9-3.6,15.67-3.6s10.56,1,14.64,3c4.08,2,7.38,4.74,9.9,8.22s4.18,7.42,4.98,11.82l-14.64,3.12c-.32-2.4-1.04-4.56-2.16-6.48-1.12-1.92-2.7-3.44-4.74-4.56-2.04-1.12-4.58-1.68-7.62-1.68s-5.78,.66-8.22,1.98c-2.44,1.32-4.36,3.28-5.76,5.88-1.4,2.6-2.1,5.78-2.1,9.54v1.2c0,3.76,.7,6.94,2.1,9.54,1.4,2.6,3.32,4.56,5.76,5.88,2.44,1.32,5.18,1.98,8.22,1.98,4.56,0,8.02-1.18,10.38-3.54s3.86-5.46,4.5-9.3l14.64,3.48c-1.04,4.24-2.82,8.1-5.34,11.58-2.52,3.48-5.82,6.22-9.9,8.22-4.08,2-8.96,3-14.64,3Z" fill="#ff4b4b"/>
                        <path d="M1284.92,114.75c-3.92,0-7.1-1.22-9.54-3.66-2.44-2.44-3.66-5.7-3.66-9.78v-33.61h-14.88v-12.48h14.88v-18.49h15.12v18.49h16.33v12.48h-16.33v30.97c0,2.4,1.12,3.6,3.36,3.6h11.52v12.48h-16.8Z" fill="#ff4b4b"/>
                        <path d="M1340.62,116.43c-5.92,0-11.24-1.2-15.96-3.6-4.72-2.4-8.44-5.88-11.16-10.44-2.72-4.56-4.08-10.04-4.08-16.44v-1.92c0-6.4,1.36-11.88,4.08-16.45,2.72-4.56,6.44-8.04,11.16-10.44,4.72-2.4,10.04-3.6,15.96-3.6s11.24,1.2,15.96,3.6c4.72,2.4,8.44,5.88,11.16,10.44,2.72,4.56,4.08,10.04,4.08,16.45v1.92c0,6.4-1.36,11.88-4.08,16.44-2.72,4.56-6.44,8.04-11.16,10.44-4.72,2.4-10.04,3.6-15.96,3.6Zm0-13.44c4.64,0,8.48-1.5,11.52-4.5,3.04-3,4.56-7.3,4.56-12.9v-1.2c0-5.6-1.5-9.9-4.5-12.9-3-3-6.86-4.5-11.58-4.5s-8.48,1.5-11.52,4.5c-3.04,3-4.56,7.3-4.56,12.9v1.2c0,5.6,1.52,9.9,4.56,12.9,3.04,3,6.88,4.5,11.52,4.5Z" fill="#ff4b4b"/>
                        <path d="M1380.95,114.75V55.21h14.88v6.72h2.16c.88-2.4,2.34-4.16,4.38-5.28s4.42-1.68,7.14-1.68h7.2v13.45h-7.44c-3.84,0-7,1.02-9.48,3.06-2.48,2.04-3.72,5.18-3.72,9.42v33.85h-15.12Z" fill="#ff4b4b"/>
                        <path d="M1432.56,138.76v-13.21h32.41c2.24,0,3.36-1.2,3.36-3.6v-15h-2.16c-.64,1.36-1.64,2.72-3,4.08-1.36,1.36-3.2,2.48-5.52,3.36-2.32,.88-5.28,1.32-8.88,1.32-4.64,0-8.7-1.06-12.18-3.18-3.48-2.12-6.18-5.06-8.1-8.82-1.92-3.76-2.88-8.08-2.88-12.96V55.21h15.12v34.33c0,4.48,1.1,7.84,3.3,10.08,2.2,2.24,5.34,3.36,9.42,3.36,4.64,0,8.24-1.54,10.8-4.62,2.56-3.08,3.84-7.38,3.84-12.9v-30.25h15.12V125.31c0,4.08-1.2,7.34-3.6,9.78-2.4,2.44-5.6,3.66-9.6,3.66h-37.45Z" fill="#ff4b4b"/>
                        <circle cx="755.51" cy="109.26" r="5" fill="#ff4b4b"/>
                        <circle cx="795.51" cy="109.26" r="5" fill="#ff4b4b"/>
                      </g>
                      <g mask="url(#d)">
                        <g mix-blend-mode="multiply" opacity=".15">
                          <g>
                            <path d="M143.11,114.75V45.13h-24.49v-14.41h64.82v14.41h-24.49V114.75h-15.84Z" fill="#1e1e1e"/>
                            <path d="M194.72,114.75V30.72h15.12v31.81h2.16c.64-1.28,1.64-2.56,3-3.84,1.36-1.28,3.18-2.34,5.46-3.18,2.28-.84,5.18-1.26,8.7-1.26,4.64,0,8.7,1.06,12.18,3.18,3.48,2.12,6.18,5.04,8.1,8.76,1.92,3.72,2.88,8.06,2.88,13.02v35.53h-15.12v-34.33c0-4.48-1.1-7.84-3.3-10.08-2.2-2.24-5.34-3.36-9.42-3.36-4.64,0-8.24,1.54-10.8,4.62-2.56,3.08-3.84,7.38-3.84,12.9v30.25h-15.12Z" fill="#1e1e1e"/>
                            <path d="M293.39,116.43c-5.92,0-11.14-1.26-15.67-3.78-4.52-2.52-8.04-6.08-10.56-10.68-2.52-4.6-3.78-10.02-3.78-16.26v-1.44c0-6.24,1.24-11.66,3.72-16.27,2.48-4.6,5.96-8.16,10.44-10.68,4.48-2.52,9.68-3.78,15.6-3.78s10.92,1.3,15.24,3.9c4.32,2.6,7.68,6.2,10.08,10.8,2.4,4.6,3.6,9.95,3.6,16.03v5.16h-43.33c.16,4.08,1.68,7.4,4.56,9.96,2.88,2.56,6.4,3.84,10.56,3.84s7.36-.92,9.36-2.76c2-1.84,3.52-3.88,4.56-6.12l12.36,6.48c-1.12,2.08-2.74,4.34-4.86,6.78-2.12,2.44-4.94,4.52-8.46,6.24-3.52,1.72-8,2.58-13.44,2.58Zm-14.52-38.29h27.85c-.32-3.44-1.7-6.2-4.14-8.28-2.44-2.08-5.62-3.12-9.54-3.12s-7.32,1.04-9.72,3.12c-2.4,2.08-3.88,4.84-4.44,8.28Z" fill="#1e1e1e"/>
                            <path d="M363.14,114.75V30.72h15.84v34.69h31.21V30.72h15.85V114.75h-15.85v-34.93h-31.21v34.93h-15.84Z" fill="#1e1e1e"/>
                            <path d="M465.89,116.43c-5.92,0-11.24-1.2-15.96-3.6-4.72-2.4-8.44-5.88-11.16-10.44-2.72-4.56-4.08-10.04-4.08-16.44v-1.92c0-6.4,1.36-11.88,4.08-16.45,2.72-4.56,6.44-8.04,11.16-10.44,4.72-2.4,10.04-3.6,15.96-3.6s11.24,1.2,15.96,3.6c4.72,2.4,8.44,5.88,11.16,10.44,2.72,4.56,4.08,10.04,4.08,16.45v1.92c0,6.4-1.36,11.88-4.08,16.44-2.72,4.56-6.44,8.04-11.16,10.44-4.72,2.4-10.04,3.6-15.96,3.6Zm0-13.44c4.64,0,8.48-1.5,11.52-4.5,3.04-3,4.56-7.3,4.56-12.9v-1.2c0-5.6-1.5-9.9-4.5-12.9-3-3-6.86-4.5-11.58-4.5s-8.48,1.5-11.52,4.5c-3.04,3-4.56,7.3-4.56,12.9v1.2c0,5.6,1.52,9.9,4.56,12.9,3.04,3,6.88,4.5,11.52,4.5Z" fill="#1e1e1e"/>
                            <path d="M506.22,114.75V30.72h15.12V114.75h-15.12Z" fill="#1e1e1e"/>
                            <path d="M543.31,48.25c-2.72,0-5.02-.88-6.9-2.64-1.88-1.76-2.82-4.08-2.82-6.96s.94-5.2,2.82-6.96c1.88-1.76,4.18-2.64,6.9-2.64s5.12,.88,6.96,2.64c1.84,1.76,2.76,4.08,2.76,6.96s-.92,5.2-2.76,6.96c-1.84,1.76-4.16,2.64-6.96,2.64Zm-7.56,66.5V55.21h15.12v59.54h-15.12Z" fill="#1e1e1e"/>
                            <path d="M586.88,116.43c-4.72,0-9.14-1.18-13.26-3.54-4.12-2.36-7.42-5.82-9.9-10.38-2.48-4.56-3.72-10.08-3.72-16.56v-1.92c0-6.48,1.24-12,3.72-16.56,2.48-4.56,5.76-8.02,9.84-10.38,4.08-2.36,8.52-3.54,13.32-3.54,3.6,0,6.62,.42,9.06,1.26,2.44,.84,4.42,1.9,5.94,3.18,1.52,1.28,2.68,2.64,3.48,4.08h2.16V30.72h15.12V114.75h-14.88v-7.2h-2.16c-1.36,2.24-3.46,4.28-6.3,6.12-2.84,1.84-6.98,2.76-12.42,2.76Zm4.56-13.2c4.64,0,8.52-1.5,11.64-4.5s4.68-7.38,4.68-13.14v-1.2c0-5.76-1.54-10.14-4.62-13.14-3.08-3-6.98-4.5-11.7-4.5s-8.52,1.5-11.64,4.5c-3.12,3-4.68,7.38-4.68,13.14v1.2c0,5.76,1.56,10.14,4.68,13.14s7,4.5,11.64,4.5Z" fill="#1e1e1e"/>
                            <path d="M634.41,90.98v-13.68h35.53v13.68h-35.53Z" fill="#1e1e1e"/>
                            <path d="M674.51,114.75l22.09-84.03h27.61l22.09,84.03h-16.32l-4.56-18.49h-30.01l-4.56,18.49h-16.32Zm24.61-33.13h22.57l-10.2-40.93h-2.16l-10.2,40.93Z" fill="#1e1e1e"/>
                            <path d="M766.37,114.75V30.72h15.84V114.75h-15.84Z" fill="#1e1e1e"/>
                            <path d="M857.6,116.43c-5.76,0-10.98-1.3-15.67-3.9s-8.4-6.4-11.16-11.4c-2.76-5-4.14-11.1-4.14-18.3v-20.17c0-10.8,3.04-19.11,9.12-24.91,6.08-5.8,14.32-8.7,24.73-8.7s18.31,2.74,23.95,8.22c5.64,5.48,8.46,12.9,8.46,22.27v.48h-15.61v-.96c0-2.96-.62-5.64-1.86-8.04-1.24-2.4-3.1-4.3-5.58-5.7-2.48-1.4-5.6-2.1-9.36-2.1-5.6,0-10,1.72-13.2,5.16-3.2,3.44-4.8,8.12-4.8,14.04v20.65c0,5.84,1.6,10.54,4.8,14.1,3.2,3.56,7.68,5.34,13.44,5.34s9.96-1.52,12.6-4.56c2.64-3.04,3.96-6.88,3.96-11.52v-1.2h-19.93v-13.45h35.53v42.97h-14.64v-8.04h-2.16c-.56,1.36-1.46,2.8-2.7,4.32-1.24,1.52-3.1,2.8-5.58,3.84-2.48,1.04-5.88,1.56-10.2,1.56Z" fill="#1e1e1e"/>
                            <path d="M913.65,48.25c-2.72,0-5.02-.88-6.9-2.64-1.88-1.76-2.82-4.08-2.82-6.96s.94-5.2,2.82-6.96c1.88-1.76,4.18-2.64,6.9-2.64s5.12,.88,6.96,2.64c1.84,1.76,2.76,4.08,2.76,6.96s-.92,5.2-2.76,6.96c-1.84,1.76-4.16,2.64-6.96,2.64Zm-7.56,66.5V55.21h15.12v59.54h-15.12Z" fill="#1e1e1e"/>
                            <path d="M945.46,114.75v-47.05h-15.12v-12.48h15.12v-11.04c0-4.08,1.22-7.34,3.66-9.78,2.44-2.44,5.62-3.66,9.54-3.66h15.61v12.48h-10.32c-2.24,0-3.36,1.2-3.36,3.6v8.4h15.61v12.48h-15.61v47.05h-15.12Z" fill="#1e1e1e"/>
                            <path d="M1009.56,114.75c-3.92,0-7.1-1.22-9.54-3.66-2.44-2.44-3.66-5.7-3.66-9.78v-33.61h-14.88v-12.48h14.88v-18.49h15.12v18.49h16.33v12.48h-16.33v30.97c0,2.4,1.12,3.6,3.36,3.6h11.52v12.48h-16.8Z" fill="#1e1e1e"/>
                            <path d="M1069.1,114.75V30.72h52.82v14.41h-36.97v20.29h34.09v14.4h-34.09v34.93h-15.84Z" fill="#1e1e1e"/>
                            <path d="M1148.8,116.43c-4.24,0-8.04-.74-11.4-2.22-3.36-1.48-6.02-3.64-7.98-6.48-1.96-2.84-2.94-6.3-2.94-10.38s.98-7.5,2.94-10.26c1.96-2.76,4.68-4.84,8.16-6.24,3.48-1.4,7.46-2.1,11.94-2.1h16.33v-3.36c0-2.8-.88-5.1-2.64-6.9-1.76-1.8-4.56-2.7-8.4-2.7s-6.56,.86-8.4,2.58c-1.84,1.72-3.04,3.94-3.6,6.66l-13.92-4.68c.96-3.04,2.5-5.82,4.62-8.34,2.12-2.52,4.96-4.56,8.52-6.12,3.56-1.56,7.9-2.34,13.02-2.34,7.84,0,14.04,1.96,18.61,5.88,4.56,3.92,6.84,9.6,6.84,17.04v22.21c0,2.4,1.12,3.6,3.36,3.6h4.8v12.48h-10.08c-2.96,0-5.4-.72-7.32-2.16-1.92-1.44-2.88-3.36-2.88-5.76v-.12h-2.28c-.32,.96-1.04,2.22-2.16,3.78-1.12,1.56-2.88,2.94-5.28,4.14-2.4,1.2-5.68,1.8-9.84,1.8Zm2.64-12.24c4.24,0,7.7-1.18,10.38-3.54,2.68-2.36,4.02-5.5,4.02-9.42v-1.2h-15.25c-2.8,0-5,.6-6.6,1.8-1.6,1.2-2.4,2.88-2.4,5.04s.84,3.92,2.52,5.28,4.12,2.04,7.32,2.04Z" fill="#1e1e1e"/>
                            <path d="M1224.06,116.43c-5.76,0-10.98-1.2-15.67-3.6-4.68-2.4-8.38-5.88-11.1-10.44-2.72-4.56-4.08-10.08-4.08-16.56v-1.68c0-6.48,1.36-12,4.08-16.57,2.72-4.56,6.42-8.04,11.1-10.44,4.68-2.4,9.9-3.6,15.67-3.6s10.56,1,14.64,3c4.08,2,7.38,4.74,9.9,8.22s4.18,7.42,4.98,11.82l-14.64,3.12c-.32-2.4-1.04-4.56-2.16-6.48-1.12-1.92-2.7-3.44-4.74-4.56-2.04-1.12-4.58-1.68-7.62-1.68s-5.78,.66-8.22,1.98c-2.44,1.32-4.36,3.28-5.76,5.88-1.4,2.6-2.1,5.78-2.1,9.54v1.2c0,3.76,.7,6.94,2.1,9.54,1.4,2.6,3.32,4.56,5.76,5.88,2.44,1.32,5.18,1.98,8.22,1.98,4.56,0,8.02-1.18,10.38-3.54s3.86-5.46,4.5-9.3l14.64,3.48c-1.04,4.24-2.82,8.1-5.34,11.58-2.52,3.48-5.82,6.22-9.9,8.22-4.08,2-8.96,3-14.64,3Z" fill="#1e1e1e"/>
                            <path d="M1284.92,114.75c-3.92,0-7.1-1.22-9.54-3.66-2.44-2.44-3.66-5.7-3.66-9.78v-33.61h-14.88v-12.48h14.88v-18.49h15.12v18.49h16.33v12.48h-16.33v30.97c0,2.4,1.12,3.6,3.36,3.6h11.52v12.48h-16.8Z" fill="#1e1e1e"/>
                            <path d="M1340.62,116.43c-5.92,0-11.24-1.2-15.96-3.6-4.72-2.4-8.44-5.88-11.16-10.44-2.72-4.56-4.08-10.04-4.08-16.44v-1.92c0-6.4,1.36-11.88,4.08-16.45,2.72-4.56,6.44-8.04,11.16-10.44,4.72-2.4,10.04-3.6,15.96-3.6s11.24,1.2,15.96,3.6c4.72,2.4,8.44,5.88,11.16,10.44,2.72,4.56,4.08,10.04,4.08,16.45v1.92c0,6.4-1.36,11.88-4.08,16.44-2.72,4.56-6.44,8.04-11.16,10.44-4.72,2.4-10.04,3.6-15.96,3.6Zm0-13.44c4.64,0,8.48-1.5,11.52-4.5,3.04-3,4.56-7.3,4.56-12.9v-1.2c0-5.6-1.5-9.9-4.5-12.9-3-3-6.86-4.5-11.58-4.5s-8.48,1.5-11.52,4.5c-3.04,3-4.56,7.3-4.56,12.9v1.2c0,5.6,1.52,9.9,4.56,12.9,3.04,3,6.88,4.5,11.52,4.5Z" fill="#1e1e1e"/>
                            <path d="M1380.95,114.75V55.21h14.88v6.72h2.16c.88-2.4,2.34-4.16,4.38-5.28s4.42-1.68,7.14-1.68h7.2v13.45h-7.44c-3.84,0-7,1.02-9.48,3.06-2.48,2.04-3.72,5.18-3.72,9.42v33.85h-15.12Z" fill="#1e1e1e"/>
                            <path d="M1432.56,138.76v-13.21h32.41c2.24,0,3.36-1.2,3.36-3.6v-15h-2.16c-.64,1.36-1.64,2.72-3,4.08-1.36,1.36-3.2,2.48-5.52,3.36-2.32,.88-5.28,1.32-8.88,1.32-4.64,0-8.7-1.06-12.18-3.18-3.48-2.12-6.18-5.06-8.1-8.82-1.92-3.76-2.88-8.08-2.88-12.96V55.21h15.12v34.33c0,4.48,1.1,7.84,3.3,10.08,2.2,2.24,5.34,3.36,9.42,3.36,4.64,0,8.24-1.54,10.8-4.62,2.56-3.08,3.84-7.38,3.84-12.9v-30.25h15.12V125.31c0,4.08-1.2,7.34-3.6,9.78-2.4,2.44-5.6,3.66-9.6,3.66h-37.45Z" fill="#1e1e1e"/>
                            <circle cx="755.51" cy="109.26" r="5" fill="#1e1e1e"/>
                            <circle cx="795.51" cy="109.26" r="5" fill="#1e1e1e"/>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <polygon points="0 32.06 17.77 6.26 36.55 0 45.44 16.99 24.03 28.8 0 32.06" fill="#fff"/>
                      <polygon points="120.89 30.98 126.53 27.22 131.24 23.26 143.1 24.02 152.89 17.81 159.29 19.12 163.24 22.7 172.09 24.58 178.67 27.59 180.37 30.79 165.69 31.92 155.71 31.55 149.31 32.86 138.58 32.3 128.42 31.92 120.89 30.98" fill="#fff"/>
                      <polygon points="191.99 30.79 193.31 27.78 197.26 24.02 203.47 27.03 205.75 30.77 199.15 31.92 194.44 32.11 191.99 30.79" fill="#fff"/>
                      <polygon points="208.36 50.56 211.37 49.99 213.63 52.06 215.7 53.38 219.84 52.25 224.55 52.06 228.08 54.3 223.98 56.77 216.45 57.52 215.13 58.65 213.44 60.35 210.8 64.11 206.66 63.74 206.85 56.77 207.98 53.38 208.36 50.56" fill="#fff"/>
                      <polygon points="270.09 65.05 272.91 57.9 279.5 53.57 284.77 44.54 293.43 43.03 304.72 50.94 311.87 53.19 315.07 61.48 304.35 59.97 296.44 60.16 288.53 60.35 284.39 63.92 281.38 64.11 276.49 63.92 269.01 68.04 270.09 65.05" fill="#fff"/>
                      <polygon points="280.51 77.85 284.65 74.28 289.17 75.22 292.75 75.22 298.96 72.77 301.03 75.41 305.17 77.48 305.17 78.98 296.7 80.11 288.6 80.3 284.65 81.62 280.51 77.85" fill="#fff"/>
                      <polygon points="279.19 94.98 283.15 95.36 287.1 98.18 291.05 96.3 296.89 98.56 301.22 97.99 305.17 98.37 304.79 100.44 298.58 103.64 291.62 104.21 285.03 101.76 280.7 98.75 279.19 94.98" fill="#fff"/>
                      <polygon points="307.93 95.17 311.89 94.61 315.09 96.11 318.47 96.3 319.07 100.87 313.2 99.31 310.94 97.62 307.93 95.17" fill="#fff"/>
                      <polygon points="363.65 30.61 366.85 27.97 369.67 25.15 373.82 26.84 376.07 28.35 376.07 29.85 375.13 31.36 368.73 31.92 363.65 30.61" fill="#fff"/>
                      <polygon points="376.96 55.83 380.72 58.46 387.12 58.28 389.76 60.35 393.33 61.66 398.23 59.59 402.56 61.1 405.94 54.32 407.83 51.31 410.46 58.65 408.1 65.46 406.7 67.31 398.23 66.75 388.06 68.06 379.4 66.37 376.89 65.46 374.51 60.35 376.96 55.83" fill="#fff"/>
                      <polygon points="409.71 30.61 411.97 27.78 415.36 25.34 419.12 26.46 421.94 27.22 423.94 30.77 422.13 31.36 416.67 31.36 412.34 31.17 409.71 30.61" fill="#fff"/>
                      <polygon points="442.58 61.85 448.04 55.45 452.37 50.94 456.13 49.99 461.22 47.17 466.86 49.05 473.26 49.05 477.97 51.88 483.8 55.83 486.44 59.03 490.39 64.3 482.67 61.85 474.39 59.03 470.25 60.16 464.23 59.97 458.58 58.65 455 61.29 450.49 62.98 447.1 63.17 443.71 63.74 438.66 67.62 442.58 61.85" fill="#fff"/>
                      <polygon points="450.92 90.28 456.76 92.16 458.45 95.17 465.79 95.55 471.06 94.79 474.26 96.68 477.65 94.04 478.59 91.41 481.98 87.83 483.86 87.26 481.79 94.04 478.32 98.53 475.2 102.14 467.49 104.21 461.27 103.45 455.27 98.53 452.8 97.24 450.92 90.28" fill="#fff"/>
                      <polygon points="503.33 30.79 505.59 28.72 509.36 27.78 512.56 26.09 515.76 28.54 517.25 30.77 511.8 31.74 507.66 31.92 503.33 30.79" fill="#fff"/>
                      <polygon points="536.31 31.73 538.77 27.22 542.34 26.28 547.05 26.09 549.12 28.91 550.18 31.73 547.24 31.74 542.34 30.61 538.02 32.11 536.31 31.73" fill="#fff"/>
                      <polygon points="535.65 55.26 539.52 53.38 541.03 51.5 545.36 51.69 546.86 53.01 549.5 54.32 549.31 56.21 543.85 56.02 540.65 56.58 537.45 56.96 535.65 55.26" fill="#fff"/>
                      <path d="M566.94,60.72l6.78-6.4,1.88-3.01,8.09-1.32,3.39-.94,3.76,1.88,2.45,.75,2.45,1.32,3.39,3.01,2.82-3.95,1.88-1.13,.38-6.02,1.51-2.45,1.32-2.45,.38,4.89-.38,4.33,.75,3.01-.94,3.2-.75,4.14,.34,2.51-4.67,.32-3.95-2.26-6.21-2.64-3.76,.75-6.78,1.51-2.45,1.32-3.76-.94s-1.32,1.32-1.69,1.88-3.58,2.64-3.58,2.64l-4.89,.94-1.69,.56,3.95-5.46Z" fill="#fff"/>
                      <polygon points="574.1 88.21 577.3 91.41 580.12 92.54 587.09 93.1 590.1 95.74 594.43 95.74 596.5 94.98 603.09 92.54 604.97 90.28 606.66 89.71 605.53 97.05 601.39 101.38 595.93 104.58 590.35 103.27 583.32 102.89 579.93 101.57 576.36 98.94 572.97 93.85 574.1 88.21" fill="#fff"/>
                      <polygon points="604.6 30.79 606.49 28.91 609.31 25.15 611.38 25.34 614.01 27.22 618.16 29.48 618.56 30.77 611.38 31.74 607.24 31.74 604.6 30.79" fill="#fff"/>
                      <path d="M637.66,77.18l3.64-2.8,5.88-2.24,8.12-.56,6.72,.84s3.64,1.4,4.2,2.52l.56,1.12,1.12,2.24-10.64,.56-8.4,.28-4.76,.84-6.44-2.8Z" fill="#fff"/>
                      <polygon points="700.79 75.5 703.59 77.74 706.39 74.66 711.43 74.1 716.48 76.06 720.4 77.74 721.59 81.67 716.2 83.9 709.19 83.34 699.02 81.67 697.43 79.14 700.79 75.5" fill="#fff"/>
                      <polygon points="697.27 30.41 701.75 25.65 706.23 24.53 711.55 21.45 719.4 23.97 723.11 30.77 717.16 32.37 708.47 32.37 703.43 32.37 697.27 30.41" fill="#fff"/>
                      <polygon points="766.28 30.77 770.72 27.89 776.04 27.33 780.52 27.61 782.12 30.77 775.76 32.09 770.44 31.81 766.28 30.77" fill="#fff"/>
                      <polygon points="832.61 40.21 839.89 30.69 847.74 27.61 850.82 23.69 859.22 20.33 864.54 22.57 870.42 25.93 877.98 28.17 882.46 33.49 886.38 36.57 886.66 39.93 874.9 36.29 869.02 33.49 860.34 33.21 852.5 35.73 846.89 40.21 840.45 40.77 832.61 40.21" fill="#fff"/>
                      <polygon points="857.26 71.82 863.7 69.34 866.5 65.98 874.06 65.7 879.1 66.54 882.46 67.66 889.18 67.1 892.79 71.82 890.86 72.42 879.66 73.26 869.58 73.26 857.26 71.82" fill="#fff"/>
                      <polygon points="843.69 86.14 849.86 92.02 855.74 91.74 859.94 95.66 864.42 97.06 870.86 92.86 875.62 90.9 878.42 89.78 877.3 96.5 870.86 101.26 864.14 104.62 857.14 104.34 852.38 102.66 846.77 100.14 844.81 94.54 842.01 88.38 843.69 86.14" fill="#fff"/>
                      <polygon points="906.99 55.26 911.47 51.97 914.55 51.97 919.59 53.37 922.12 55.26 918.75 57.29 913.15 56.73 908.94 56.73 906.99 55.26" fill="#fff"/>
                      <polygon points="906.65 31.73 910.47 27.61 912.99 26.77 917.19 26.49 919.43 29.01 922.23 33.21 918.87 33.77 914.67 32.37 910.47 32.93 906.65 31.73" fill="#fff"/>
                      <polygon points="928.24 55.26 933.67 53.09 937.03 52.25 940.67 50.57 943.47 46.09 945.71 50.85 943.37 55.26 937.59 57.29 932.27 56.73 928.24 55.26" fill="#fff"/>
                      <polygon points="949.03 34.43 953.31 27.61 958.35 26.21 963.67 24.25 968.99 27.05 974.18 30.77 967.59 32.65 960.03 32.65 957.79 33.77 949.03 34.43" fill="#fff"/>
                      <polygon points="959.75 49.17 963.39 51.41 965.63 51.97 968.43 50.57 971.52 52.81 976.1 55.26 968.71 56.17 960.49 55.26 959.75 49.17" fill="#fff"/>
                      <polygon points="980.38 55.26 985.36 51.97 988.16 52.53 992.92 53.09 995.44 53.37 995.26 55.26 989.28 56.45 983.96 55.89 980.38 55.26" fill="#fff"/>
                      <polygon points="996.26 36.77 1000.92 32.93 1003.44 32.37 1008.48 32.37 1009.32 34.89 1011.38 36.77 1006.8 37.97 999.8 38.25 996.26 36.77" fill="#fff"/>
                      <polygon points="1011 46.93 1013.8 50.01 1020.24 51.69 1023.88 53.93 1027.71 55.26 1020.8 56.45 1015.48 56.45 1011.38 55.26 1011 49.45 1011 46.93" fill="#fff"/>
                      <polygon points="1010.28 93.42 1013.64 97.06 1017.84 98.46 1020.36 98.18 1024.56 100.42 1025.27 102.31 1021.2 103.22 1011.68 102.94 1008.6 100.98 1010.28 93.42" fill="#fff"/>
                      <polygon points="1069 30.77 1077.37 27.05 1082.41 24.53 1088.57 25.93 1093.33 27.89 1099.5 25.37 1106.22 25.09 1112.38 29.29 1121.82 30.77 1106.5 33.49 1097.26 32.37 1089.69 33.21 1082.13 34.61 1074.29 33.77 1069 30.77" fill="#fff"/>
                      <polygon points="1084.65 59.54 1089.69 62.06 1095.3 58.41 1100.9 58.41 1107.06 59.26 1116.86 64.3 1118.94 65.46 1107.9 68.5 1099.78 67.38 1092.49 66.54 1089.41 67.38 1087.17 66.26 1084.65 65.42 1084.65 59.54" fill="#fff"/>
                      <polygon points="1135.62 59.26 1140.94 52.53 1144.86 49.17 1153.54 47.49 1159.99 49.17 1165.59 52.53 1170.07 55.33 1173.55 59.46 1163.63 56.45 1158.31 56.73 1150.74 55.61 1145.14 57.85 1137.58 60.1 1135.62 59.26" fill="#fff"/>
                      <polygon points="1132.82 83.34 1139.54 78.86 1144.3 76.9 1151.3 73.26 1155.51 73.82 1157.75 75.78 1163.91 75.78 1165.75 78.78 1159.43 79.98 1154.38 79.98 1146.82 80.26 1142.62 81.66 1136.46 84.46 1129.32 87.13 1132.82 83.34" fill="#fff"/>
                      <polygon points="1142.78 94.26 1145.3 97.06 1148.94 99.86 1152.02 99.3 1157.63 97.06 1161.27 98.18 1163.51 97.34 1166.03 97.06 1162.39 102.38 1155.1 105.46 1148.1 105.46 1144.46 104.06 1142.78 101.54 1141.38 97.9 1142.78 94.26" fill="#fff"/>
                      <polygon points="1178.87 94.54 1181.39 97.34 1184.19 98.74 1187.56 102.31 1185.03 103.22 1182.76 102.31 1179.15 102.1 1178.87 94.54" fill="#fff"/>
                      <polygon points="1208.24 84.43 1211.79 90.62 1214.59 93.42 1218.24 93.7 1224.12 93.14 1228.88 93.98 1230.84 95.66 1235.04 92.58 1239.21 90.19 1238.68 96.5 1233.64 101.26 1226.92 103.5 1218.52 103.5 1212.63 102.1 1207.87 92.3 1208.24 84.43" fill="#fff"/>
                      <polygon points="1202.55 60.1 1209.55 53.37 1214.87 50.85 1224.68 47.49 1233.08 49.73 1240.64 55.89 1247.36 62.34 1248.52 64.8 1238.12 60.1 1232.52 59.26 1227.76 58.98 1220.2 60.1 1215.15 61.22 1210.39 61.22 1205.63 62.06 1199.75 65.14 1197.2 67.62 1202.55 60.1" fill="#fff"/>
                      <polygon points="1236.92 90.34 1240.56 89.5 1246.72 89.22 1248.68 91.74 1249.86 93.67 1241.4 92.3 1236.92 90.34" fill="#fff"/>
                      <polygon points="1271.62 36.77 1275.64 32.65 1279.01 31.81 1283.21 34.33 1286.75 36.77 1279.29 38.81 1271.62 36.77" fill="#fff"/>
                      <polygon points="1242.74 55.26 1246.8 51.97 1253.8 51.97 1257.62 55.26 1255.48 56.45 1251.84 56.73 1247.08 56.73 1242.74 55.26" fill="#fff"/>
                      <polygon points="1272.57 46.93 1274.81 50.29 1279.29 51.97 1284.89 52.81 1289.07 55.26 1282.93 57.29 1278.17 56.45 1272.75 55.26 1271.17 49.45 1272.57 46.93" fill="#fff"/>
                      <polygon points="1285.85 91.18 1290.61 95.94 1290.33 97.9 1293.13 98.46 1295.93 98.74 1300.63 102.31 1294.81 104.06 1289.11 102.31 1284.73 100.7 1284.45 96.5 1285.85 91.18" fill="#fff"/>
                      <polygon points="1313.57 65.14 1322.53 54.21 1328.13 49.17 1340.46 48.61 1348.58 50.57 1356.7 55.05 1364.82 65.14 1355.3 63.18 1348.3 60.94 1335.13 59.82 1328.41 61.22 1318.61 64.86 1313.57 65.14" fill="#fff"/>
                      <polygon points="1322.44 85.63 1328.53 93.7 1331.89 93.98 1338.06 94.82 1345.06 92.58 1349.82 93.42 1352.06 92.02 1354.86 90.9 1352.06 96.78 1346.74 101.82 1338.52 103.03 1330.49 102.38 1323.77 98.74 1322.37 90.34 1322.44 85.63" fill="#fff"/>
                      <polygon points="1380.86 55.26 1388.5 50.29 1392.98 51.69 1395.74 55.26 1391.86 56.73 1387.38 56.73 1380.86 55.26" fill="#fff"/>
                      <polygon points="1395.78 57.57 1397.74 58.13 1400.54 54.77 1404.19 52.53 1412.31 53.09 1416.63 55.02 1411.75 57.01 1403.63 58.69 1400.82 61.22 1397.18 64.3 1392.42 62.62 1395.78 57.57" fill="#fff"/>
                      <polygon points="1425.51 55.26 1426.58 54.43 1429.39 52.25 1434.43 52.81 1440.03 53.37 1440.63 55.26 1431.35 56.45 1425.51 55.26" fill="#fff"/>
                      <polygon points="1441.31 81.94 1443.83 87.26 1449.15 91.46 1451.67 95.38 1454.47 95.38 1460.07 91.74 1464.84 88.38 1469 85.51 1469.6 90.62 1467.92 97.06 1464.28 101.26 1456.99 104.06 1448.59 103.78 1441.59 98.18 1439.07 90.34 1441.31 81.94" fill="#fff"/>
                      <polygon points="1469 55.26 1471.84 51.69 1476.6 49.17 1481.64 53.09 1484.13 55.26 1479.96 56.45 1475.2 57.29 1470.72 56.45 1469 55.26" fill="#fff"/>
                      <polygon points="1431.47 125.6 1437.35 122.55 1446.31 122.55 1451.91 119.46 1458.63 121.15 1463.4 121.71 1466.76 124.79 1463.88 125.6 1454.43 128.15 1447.15 127.31 1440.15 127.87 1435.39 128.43 1431.47 125.6" fill="#fff"/>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <p>Welcome to The Holid-A.I. Gift Factory where the perfect gift is just a few clicks away. You won’t find these gifts on any other shelf because everything is created by you and manufactured with A.I. technology.</p>
          <p>Go ahead, you’ve been good all year. Create something that will bring about some cheer!</p>
          <a href="#giftGen" class="button styled-button"><span>Build a Gift</span></a>
          <a href="#" class="button styled-button" @click="playVideo"><span>Watch Video</span></a>
        </div>
        <a href="#" class="button styled-button share-link" @click="showShare">
          <span>Share This Experience &nbsp;<i class="fas fa-share"></i></span>
          <span class="share-mod" v-if="isShare">
            <ul>
              <li>
                <ShareNetwork
                  network="twitter"
                  :url="pageURL"
                  :title="this.sharing.title"
                  :description="this.sharing.description"
                  :hashtags="this.sharing.hashtags"
                >
                <i class="fab fa-twitter"></i>
                </ShareNetwork>
              </li>
              <li>
                <ShareNetwork
                    network="facebook"
                    :url="pageURL"
                    :title="this.sharing.title"
                    :description="this.sharing.description"
                    :hashtags="this.sharing.hashtags"
                  >
                  <i class="fab fa-facebook"></i>
                </ShareNetwork>
              </li>
              <li>
                <ShareNetwork
                    network="linkedin"
                    :url="pageURL"
                    :title="this.sharing.title"
                    :description="this.sharing.description"
                    :hashtags="this.sharing.hashtags"
                  >
                  <i class="fab fa-linkedin"></i>
                </ShareNetwork>
              </li>
              <li>
                <ShareNetwork
                    network="email"
                    :url="pageURL"
                    :title="this.sharing.title"
                    :description="this.sharing.description"
                    :hashtags="this.sharing.hashtags"
                  >
                  <i class="fas fa-envelope"></i>
                </ShareNetwork>
              </li>
              <li class="close">
                <a href="#" class="closeThis">Close</a>
              </li>
            </ul>
          </span>
        </a>
        <a href="#giftGen" class="scroll">
          <span class="one"><i class="fal fa-chevron-down"></i></span>
          <span class="two"><i class="far fa-chevron-down"></i></span>
          <span class="three"><i class="fas fa-chevron-down"></i></span>
          <span class="four"><i class="fas fa-chevron-down"></i></span>
        </a>
      </div>
      <img src="../assets/images/mountains.svg" class="mountains" />
      <div class="video-wrap" v-if="videoActive" @click="closeVideo">
        <div class="inner">
          <button type="button" aria-label="Close Video" @click="closeVideo">
            <i class="fas fa-times"></i>
          </button>
          <div class="responsive-embed widescreen">
            <iframe src="https://www.youtube.com/embed/_K5FBT-BeOw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <div class="overlay"></div>
      <video autoplay muted loop id="myVideo">
        <source src="../assets/images/marquee-video.mp4" type="video/mp4">
      </video>
    </section>
    <Marquee />
    <section class="body-wrap" id="giftGen">
      <div class="row align-center">
        <div class="columns large-12 gen-outer">
          <div class="row align-center">
            <div class="columns medium-12 small-12">
              <div class="gen-wrap">
              <img src="../assets/images/snow-1.svg" alt="" class="snow-1" />
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 622.43 228.86" class="gen-lights-right mob">
                <g>
                  <polyline points="614.23 202.99 594.32 80.3 513.89 57.25 421.83 76.9 338.65 122.64 211.03 92.81 120.35 41.5 4.74 77.53" fill="none" stroke="#1d7c13" stroke-miterlimit="10" stroke-width="2"/>
                  <g>
                    <polygon points="414.25 42.91 408.78 44.8 406.83 48.02 410.35 49.35 415.82 47.45 417.77 44.23 414.25 42.91" fill="#62d14e" class="bulbd"/>
                    <polygon points="415.82 47.45 428.88 58.91 417.77 44.23 415.82 47.45" fill="#5ebc48" class="bulbd"/>
                    <polygon points="410.35 49.34 407.22 66.41 406.92 51.21 406.86 48.03 410.35 49.34" fill="#5ebc48" class="bulbd"/>
                    <polygon points="410.35 49.34 416.58 65.68 421.84 64.13 415.82 47.45 410.35 49.34" fill="#79e260" class="bulbd"/>
                    <polygon points="416.58 65.68 420.4 80.52 420.42 80.59 420.2 80.59 417.98 80.64 407.22 66.41 416.58 65.68" fill="#5bb545" class="bulbd"/>
                    <polygon points="428.88 58.91 429.24 77.46 427.95 76.98 420.65 64.27 428.88 58.91" fill="#5bb545" class="bulbd"/>
                    <polygon points="410.35 49.34 407.22 66.41 416.58 65.68 410.35 49.34" fill="#6fce59" class="bulbd"/>
                    <polygon points="415.82 47.45 420.65 64.27 428.88 58.91 415.82 47.45" fill="#6fce59" class="bulbd"/>
                    <polygon points="416.58 65.68 420.65 64.27 427.33 75.82 419.24 78.62 416.58 65.68" fill="#6fce59" class="bulbd"/>
                    <rect x="415.7" y="76.69" width="16.6" height="5.48" transform="translate(-2.64 143.17) rotate(-19.11)" fill="#383838"/>
                  </g>
                  <g>
                    <polygon points="595.09 226.38 600.32 228.86 603.97 227.93 602.39 224.52 597.16 222.03 593.51 222.96 595.09 226.38" fill="#62d14e" class="bulbd"/>
                    <polygon points="597.16 222.03 595.89 204.71 593.51 222.96 597.16 222.03" fill="#5ebc48" class="bulbd"/>
                    <polygon points="602.39 224.52 616.58 214.55 606.14 225.6 603.96 227.9 602.39 224.52" fill="#5ebc48" class="bulbd"/>
                    <polygon points="602.39 224.52 609.4 208.51 604.56 205.92 597.16 222.03 602.39 224.52" fill="#79e260" class="bulbd"/>
                    <polygon points="609.4 208.51 617.09 195.24 617.12 195.19 617.28 195.33 618.9 196.86 616.58 214.55 609.4 208.51" fill="#5bb545" class="bulbd"/>
                    <polygon points="595.89 204.71 608.64 191.23 609.22 192.47 605.51 206.66 595.89 204.71" fill="#5bb545" class="bulbd"/>
                    <polygon points="602.39 224.52 616.58 214.55 609.4 208.51 602.39 224.52" fill="#6fce59" class="bulbd"/>
                    <polygon points="597.16 222.03 605.51 206.66 595.89 204.71 597.16 222.03" fill="#6fce59" class="bulbd"/>
                    <polygon points="609.4 208.51 605.51 206.66 608.85 193.74 616.58 197.42 609.4 208.51" fill="#6fce59" class="bulbd"/>
                    <rect x="605.46" y="190.76" width="16.6" height="5.48" transform="translate(1085.05 631.71) rotate(-154.58)" fill="#383838"/>
                  </g>
                  <g>
                    <polygon points="116.41 0 109.81 1.11 106.98 4.41 110.74 6.59 117.33 5.47 120.17 2.18 116.41 0" fill="#62d14e" class="bulbd"/>
                    <polygon points="117.33 5.47 130 21.02 120.17 2.18 117.33 5.47" fill="#5ebc48" class="bulbd"/>
                    <polygon points="110.74 6.58 103.91 25.42 106.48 8.05 107.01 4.43 110.74 6.58" fill="#5ebc48" class="bulbd"/>
                    <polygon points="110.74 6.58 114.71 26.37 121 25.62 117.33 5.47 110.74 6.58" fill="#79e260" class="bulbd"/>
                    <polygon points="114.71 26.37 116.22 44.01 116.23 44.09 115.98 44.05 113.44 43.68 103.91 25.42 114.71 26.37" fill="#5bb545" class="bulbd"/>
                    <polygon points="130 21.02 126.87 42.21 125.5 41.42 119.61 25.55 130 21.02" fill="#5bb545" class="bulbd"/>
                    <polygon points="110.74 6.58 103.91 25.42 114.71 26.37 110.74 6.58" fill="#6fce59" class="bulbd"/>
                    <polygon points="117.33 5.47 119.61 25.55 130 21.02 117.33 5.47" fill="#6fce59" class="bulbd"/>
                    <polygon points="114.71 26.37 119.61 25.55 125.01 39.98 115.26 41.62 114.71 26.37" fill="#6fce59" class="bulbd"/>
                    <rect x="110.94" y="40.29" width="19.17" height="6.33" transform="translate(-5.55 20.66) rotate(-9.58)" fill="#383838"/>
                  </g>
                  <g>
                    <polygon points="613.53 53.2 608.53 50.27 604.82 50.88 606.1 54.42 611.09 57.35 614.81 56.74 613.53 53.2" fill="#ed4f75" class="bulbd"/>
                    <polygon points="611.09 57.35 610.85 74.71 614.81 56.74 611.09 57.35" fill="#a0133c" class="bulbd"/>
                    <polygon points="606.1 54.41 591.09 63.12 602.46 53.02 604.83 50.91 606.1 54.41" fill="#a0133c" class="bulbd"/>
                    <polygon points="606.1 54.41 597.72 69.76 602.32 72.75 611.09 57.35 606.1 54.41" fill="#fc1f49" class="bulbd"/>
                    <polygon points="597.72 69.76 588.91 82.31 588.87 82.36 588.73 82.2 587.25 80.54 591.09 63.12 597.72 69.76" fill="#a0133c" class="bulbd"/>
                    <polygon points="610.85 74.71 596.98 87.04 596.51 85.75 601.44 71.94 610.85 74.71" fill="#a0133c" class="bulbd"/>
                    <polygon points="606.1 54.41 591.09 63.12 597.72 69.76 606.1 54.41" fill="#d61241" class="bulbd"/>
                    <polygon points="611.09 57.35 601.44 71.94 610.85 74.71 611.09 57.35" fill="#d61241" class="bulbd"/>
                    <polygon points="597.72 69.76 601.44 71.94 596.99 84.52 589.6 80.19 597.72 69.76" fill="#ed4f75" class="bulbd"/>
                    <rect x="583.77" y="81.59" width="16.6" height="5.48" transform="translate(124.09 -288.03) rotate(30.4)" fill="#383838"/>
                  </g>
                  <g>
                    <polygon points="7.74 106.57 13.53 106.81 16.52 104.53 13.73 102 7.94 101.76 4.95 104.04 7.74 106.57" fill="#ed4f75" class="bulbd"/>
                    <polygon points="7.94 101.76 0 86.31 4.95 104.04 7.94 101.76" fill="#a0133c" class="bulbd"/>
                    <polygon points="13.73 102.01 22.89 87.27 17.6 101.53 16.5 104.5 13.73 102.01" fill="#a0133c" class="bulbd"/>
                    <polygon points="13.73 102.01 13.92 84.53 8.46 84.04 7.94 101.76 13.73 102.01" fill="#fc1f49" class="bulbd"/>
                    <polygon points="13.92 84.53 15.81 69.31 15.82 69.24 16.02 69.32 18.11 70.09 22.89 87.27 13.92 84.53" fill="#a0133c" class="bulbd"/>
                    <polygon points="0 86.31 6.46 68.92 7.48 69.84 9.62 84.35 0 86.31" fill="#a0133c" class="bulbd"/>
                    <polygon points="13.73 102.01 22.89 87.27 13.92 84.53 13.73 102.01" fill="#d61241" class="bulbd"/>
                    <polygon points="7.94 101.76 9.62 84.35 0 86.31 7.94 101.76" fill="#d61241" class="bulbd"/>
                    <polygon points="13.92 84.53 9.62 84.35 7.64 71.15 16.19 71.51 13.92 84.53" fill="#ed4f75" class="bulbd"/>
                    <rect x="3.76" y="66.27" width="16.6" height="5.48" transform="translate(21.23 138.46) rotate(-177.6)" fill="#383838"/>
                  </g>
                  <g>
                    <polygon points="317.36 148.74 323.34 151.67 327.55 150.65 325.77 146.7 319.79 143.77 315.58 144.79 317.36 148.74" fill="#ed4f75" class="bulbd"/>
                    <polygon points="319.79 143.77 318.55 123.83 315.58 144.79 319.79 143.77" fill="#a0133c" class="bulbd"/>
                    <polygon points="325.77 146.7 342.23 135.41 330.07 147.99 327.54 150.61 325.77 146.7" fill="#a0133c" class="bulbd"/>
                    <polygon points="325.77 146.7 334.05 128.37 328.51 125.33 319.79 143.77 325.77 146.7" fill="#fc1f49" class="bulbd"/>
                    <polygon points="334.05 128.37 343.06 113.21 343.1 113.14 343.28 113.31 345.12 115.09 342.23 135.41 334.05 128.37" fill="#a0133c" class="bulbd"/>
                    <polygon points="318.55 123.83 333.39 108.48 334.05 109.92 329.6 126.19 318.55 123.83" fill="#a0133c" class="bulbd"/>
                    <polygon points="325.77 146.7 342.23 135.41 334.05 128.37 325.77 146.7" fill="#d61241" class="bulbd"/>
                    <polygon points="319.79 143.77 329.6 126.19 318.55 123.83 319.79 143.77" fill="#d61241" class="bulbd"/>
                    <polygon points="334.05 128.37 329.6 126.19 333.6 111.37 342.45 115.7 334.05 128.37" fill="#ed4f75" class="bulbd"/>
                    <rect x="329.7" y="108.01" width="19.1" height="6.31" transform="translate(595.18 360.06) rotate(-153.94)" fill="#383838"/>
                  </g>
                  <g>
                    <polygon points="515.78 22.26 509.99 22.12 507.04 24.45 509.87 26.93 515.66 27.07 518.62 24.73 515.78 22.26" fill="#fffc92" class="bulbd"/>
                    <polygon points="515.66 27.07 523.88 42.37 518.62 24.73 515.66 27.07" fill="#aaa321" class="bulbd"/>
                    <polygon points="509.87 26.92 500.97 41.82 506.01 27.47 507.06 24.47 509.87 26.92" fill="#aaa321" class="bulbd"/>
                    <polygon points="509.87 26.92 509.99 44.4 515.46 44.8 515.66 27.07 509.87 26.92" fill="#f9f921" class="bulbd"/>
                    <polygon points="509.99 44.4 508.37 59.65 508.36 59.72 508.16 59.65 506.06 58.91 500.97 41.82 509.99 44.4" fill="#aaa321" class="bulbd"/>
                    <polygon points="523.88 42.37 517.72 59.88 516.68 58.98 514.29 44.51 523.88 42.37" fill="#aaa321" class="bulbd"/>
                    <polygon points="509.87 26.92 500.97 41.82 509.99 44.4 509.87 26.92" fill="#d6d234" class="bulbd"/>
                    <polygon points="515.66 27.07 514.29 44.51 523.88 42.37 515.66 27.07" fill="#d6d234" class="bulbd"/>
                    <polygon points="509.99 44.4 514.29 44.51 516.51 57.67 507.95 57.46 509.99 44.4" fill="#dbd628" class="bulbd"/>
                    <rect x="503.82" y="57.15" width="16.6" height="5.48" transform="translate(1.6 -12.4) rotate(1.39)" fill="#383838"/>
                  </g>
                  <g>
                    <polygon points="242.97 62.89 238.05 58.06 233.57 57.68 234.03 62.15 238.96 66.98 243.43 67.35 242.97 62.89" fill="#fffc92" class="bulbd"/>
                    <polygon points="238.96 66.98 233.65 87 243.43 67.35 238.96 66.98" fill="#aaa321" class="bulbd"/>
                    <polygon points="234.03 62.15 214.16 67.87 230.23 59.48 233.58 57.72 234.03 62.15" fill="#aaa321" class="bulbd"/>
                    <polygon points="234.03 62.15 219.91 77.46 224.35 82.26 238.96 66.98 234.03 62.15" fill="#f9f921" class="bulbd"/>
                    <polygon points="219.91 77.46 206.09 89.42 206.03 89.48 205.91 89.25 204.67 86.9 214.16 67.87 219.91 77.46" fill="#aaa321" class="bulbd"/>
                    <polygon points="233.65 87 214.04 97.23 213.87 95.61 223.57 81.06 233.65 87" fill="#aaa321" class="bulbd"/>
                    <polygon points="234.03 62.15 214.16 67.87 219.91 77.46 234.03 62.15" fill="#d6d234" class="bulbd"/>
                    <polygon points="238.96 66.98 223.57 81.06 233.65 87 238.96 66.98" fill="#d6d234" class="bulbd"/>
                    <polygon points="219.91 77.46 223.57 81.06 214.79 94.32 207.5 87.17 219.91 77.46" fill="#dbd628" class="bulbd"/>
                    <rect x="199.26" y="89.41" width="19.79" height="6.53" transform="translate(124.76 -119.96) rotate(44.45)" fill="#383838"/>
                  </g>
                </g>
              </svg>
              <div class="row">
                <div class="columns large-5 medium-12 small-12 screen-left">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -50 690.63 845.18" class="screen">
                      <g>
                        <g>
                          <g>
                            <path d="M470.6,106.79s-285.32-1.25-306.94-.14c-.74,.04-1.17,.08-1.28,.12l15.22-21.04,17.47-5.82,18.82-12.55,34.49,.9,13.89,2.69,36.29-1.8,33.94-7.3,1.46-.31,21.95,16.58,24.64,17.92,24.64-13.44,28.68-2.24,.77,.65,14.01,11.89,21.95,13.89Z" fill="#fff"/>
                            <path d="M332.5,61.85l-34.04,18.2-25.6,1.89-42.16-3.02-32.75,7.16-34.29,20.57c-.74,.04-1.17,.08-1.28,.12l15.22-21.04,17.47-5.82,18.82-12.55,34.49,.9,13.89,2.69,36.29-1.8,33.94-7.3Z" fill="#e1ebf4"/>
                            <polygon points="434.64 81.01 419.69 86.45 395.97 96.24 380.55 96.04 405.19 82.6 433.87 80.36 434.64 81.01" fill="#e1ebf4"/>
                          </g>
                          <rect y="107.09" width="669.46" height="520.3" fill="#c4c4c4"/>
                          <rect x="70.49" y="189.21" width="528.48" height="346.48" fill="#274559"/>
                          <polygon points="598.97 188.38 598.97 536.37 593.81 532.67 593.81 194.51 598.97 188.38" fill="#365b70"/>
                          <g>
                            <path d="M205.17,292.16l244.88-98.8H70.49s131.67,95.79,134.68,98.8Z" fill="#365b70"/>
                            <path d="M598.97,188.38l-4.41,6.12H76.65l-6.16-6.12H598.97Z" fill="#162d35"/>
                            <polygon points="205.17 292.16 189.83 530.39 169.28 535.69 70.49 535.69 70.49 197.03 76.66 194.5 205.17 292.16" fill="#325568"/>
                            <polygon points="76.66 194.5 76.66 530.18 70.49 536.61 70.49 188.9 76.66 194.5" fill="#162d35"/>
                            <polygon points="598.97 536.32 598.97 536.62 70.49 536.62 70.49 533.35 76.66 530.16 593.81 530.16 598.97 536.32" fill="#365b70"/>
                          </g>
                          <foreignObject width="460" height="350" class="bdy" x="110" y="240" requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility" v-if="getCurrentStep == 1">
                            <text class="hdl" x="165" y="315">Build a Gift</text>
                            <p xmlns="http://www.w3.org/1999/xhtml">Making the perfect gift is fast and easy! Just answer a few questions for our robot elves.</p>
                          </foreignObject>
                          <foreignObject width="460" height="350" class="bdy" x="110" y="240" requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility" v-if="getCurrentStep != 1 && getCurrentStep != 6">
                            <text class="hdl" x="165" y="315">A Gift for {{ $store.getters.getRecepient }}</text>
                          </foreignObject>
                          <foreignObject width="460" height="350" class="bdy" x="110" y="240" requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility" v-if="getCurrentStep == 6">
                            <text class="hdl" x="165" y="315">{{ $store.getters.getRecepient }} is gonna love this!</text>
                          </foreignObject>
                          <g id="large-light" v-if="$store.getters.loadingStatus">
                            <rect x="73.65" y="86.39" width="105.04" height="22.98" fill="#848484"/>
                            <path d="M174.85,86.43H77.49l1.44-25.09,.75-12.99,1.58-27.56,.02-.23v.22S95.65,0,95.65,0h60.39c.21,0,.42,0,.63,.02l12.84,11.12c.16,.83,.24,1.69,.24,2.56l.28,4.05,1.37,19.48,1.29,18.35,.51,7.35,1.65,23.5Z" class="lg-light-fills g" />
                            <polygon points="114.91 86.39 111.37 109.37 73.65 109.37 73.65 86.39 114.91 86.39" fill="#707070" />
                            <polygon points="127.08 0 121.08 41.77 118.77 57.9 114.67 86.43 77.49 86.43 78.93 61.34 79.68 48.35 81.27 20.78 95.65 0 127.08 0" class="lg-light-fills a"/>
                            <polygon points="121.08 41.77 118.77 57.9 78.93 61.34 81.28 20.56 121.08 41.77" class="lg-light-fills b"/>
                            <polygon points="127.08 0 121.08 41.77 111.3 0 127.08 0" class="lg-light-fills c"/>
                            <polygon points="174.85 86.43 114.67 86.43 146.26 69.63 172.69 55.58 173.2 62.93 174.85 86.43" class="lg-light-fills d"/>
                            <polygon points="172.69 55.58 172.49 55.7 146.26 69.63 154.96 50.64 170.03 17.75 171.4 37.23 172.69 55.58" class="lg-light-fills e"/>
                            <path d="M170.03,17.75l-15.07,32.89L127.08,0h28.96c.21,0,.42,0,.63,.02l12.84,11.12c.16,.83,.24,1.69,.24,2.56l.28,4.05Z" class="lg-light-fills f"/>
                            <polygon points="81.28 20.56 81.27 20.78 81.26 20.79 81.28 20.56" fill="#f66"/>
                          </g>
                          <g id="large-light-static" transform="translate(73, 0)" v-else>
                            <rect y="86.39" width="105.04" height="22.98" fill="#848484"/>
                            <path d="M101.2,86.43H3.84l1.44-25.09,.75-12.99,1.58-27.56,.02-.23v.22S22,0,22,0h60.39c.21,0,.42,0,.63,.02l12.84,11.12c.16,.83,.24,1.69,.24,2.56l.28,4.05,1.37,19.48,1.29,18.35,.51,7.35,1.65,23.5Z" fill="#ff3e3e"/>
                            <polygon points="41.26 86.39 37.72 109.37 0 109.37 0 86.39 41.26 86.39" fill="#707070"/>
                            <polygon points="53.43 0 47.43 41.77 45.12 57.9 41.02 86.43 3.84 86.43 5.28 61.34 6.03 48.35 7.62 20.78 22 0 53.43 0" fill="#ff7373"/>
                            <polygon points="47.43 41.77 45.12 57.9 5.28 61.34 7.63 20.56 47.43 41.77" fill="#ff9494"/>
                            <polygon points="53.43 0 47.43 41.77 37.65 0 53.43 0" fill="#ff9494"/>
                            <polygon points="101.2 86.43 41.02 86.43 72.61 69.63 99.04 55.58 99.55 62.93 101.2 86.43" fill="#cc1717"/>
                            <polygon points="99.04 55.58 98.84 55.7 72.61 69.63 81.31 50.64 96.38 17.75 97.75 37.23 99.04 55.58" fill="#e52323"/>
                            <path d="M96.38,17.75l-15.07,32.89L53.43,0h28.96c.21,0,.42,0,.63,.02l12.84,11.12c.16,.83,.24,1.69,.24,2.56l.28,4.05Z" fill="#f66"/>
                            <polygon points="7.63 20.56 7.62 20.78 7.61 20.79 7.63 20.56" fill="#f66"/>
                          </g>
                          <rect x="49.71" y="558.41" width="22.24" height="57.45" transform="translate(647.96 526.3) rotate(90)" fill="#6b6b6b"/>
                          <g>
                            <polygon points="559.44 563.88 537.07 563.88 525.88 583.26 537.07 602.63 559.44 602.63 570.63 583.26 559.44 563.88" fill="#969696"/>
                            <polygon points="540.43 604.12 537.07 602.63 559.44 602.63 570.63 583.26 559.44 563.88 562.81 565.37 574 584.75 562.81 604.12 540.43 604.12" fill="#757575"/>
                            <polygon points="554.91 566.43 536.73 566.43 527.65 582.17 536.73 597.91 554.91 597.91 564 582.17 554.91 566.43" fill="#65d2e0" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="539.47 599.12 536.73 597.91 554.91 597.91 564 582.17 554.91 566.43 557.64 567.64 566.73 583.38 557.64 599.12 539.47 599.12" fill="#3f9393" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="536.73 566.43 543.08 580.86 527.65 582.17 536.73 566.43" fill="#a4eef4" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="554.91 566.43 543.08 580.86 564 582.17 554.91 566.43" fill="#4fa8aa" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="543.08 580.86 560.3 588.48 564 582.17 543.08 580.86" fill="#a4eef4" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="543.08 580.86 536.73 597.91 547.7 597.91 543.08 580.86" fill="#4fa8aa" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                          </g>
                          <g>
                            <polygon points="614.37 563.88 592 563.88 580.81 583.26 592 602.63 614.37 602.63 625.56 583.26 614.37 563.88" fill="#969696"/>
                            <polygon points="595.36 604.12 592 602.63 614.37 602.63 625.56 583.26 614.37 563.88 617.74 565.37 628.92 584.75 617.74 604.12 595.36 604.12" fill="#757575"/>
                            <polygon points="609.83 566.43 591.66 566.43 582.57 582.17 591.66 597.91 609.83 597.91 618.92 582.17 609.83 566.43" fill="#528cff" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="594.39 599.12 591.66 597.91 609.83 597.91 618.92 582.17 609.83 566.43 612.57 567.64 621.65 583.38 612.57 599.12 594.39 599.12" fill="#376ced" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="591.66 566.43 598.01 580.86 582.57 582.17 591.66 566.43" fill="#94bdff" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="609.83 566.43 598.01 580.86 618.92 582.17 609.83 566.43" fill="#3875e0" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="598.01 580.86 615.23 588.48 618.92 582.17 598.01 580.86" fill="#94bdff" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="598.01 580.86 591.66 597.91 602.63 597.91 598.01 580.86" fill="#3875e0" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                          </g>
                          <g>
                            <image href="../assets/images/snow-3.svg" class="snow-small" x="526" y="1" />
                            <polyline points="631.67 17.34 623.27 17.34 604.1 50.56 623.27 83.78 661.63 83.78 680.81 50.56 661.63 17.34 631.67 17.34" fill="#d3d3d3"/>
                            <polygon points="629.04 86.33 623.27 83.78 661.63 83.78 680.81 50.56 661.63 17.34 667.4 19.89 686.58 53.11 667.4 86.33 629.04 86.33" fill="#b2b2b2" />
                            <polygon points="653.86 21.71 622.7 21.71 607.12 48.7 622.7 75.68 653.86 75.68 669.43 48.7 653.86 21.71" fill="#829bf9" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="627.39 77.75 622.7 75.68 653.86 75.68 669.43 48.7 653.86 21.71 658.54 23.79 674.12 50.77 658.54 77.75 627.39 77.75" fill="#254c99" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="622.7 21.71 633.58 46.45 607.12 48.7 622.7 21.71" fill="#b7c6f9" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="653.86 21.71 633.58 46.45 669.43 48.7 653.86 21.71" fill="#376ced" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="633.58 46.45 663.11 59.52 669.43 48.7 633.58 46.45" fill="#b7c6f9" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="633.58 46.45 622.7 75.68 641.5 75.68 633.58 46.45" fill="#376ced" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                          </g>
                          <g>
                            <image href="../assets/images/snow-2.svg" class="snow-small" x="618" y="5" />
                            <polyline points="541.16 17.34 532.76 17.34 513.59 50.56 532.76 83.78 571.12 83.78 590.3 50.56 571.12 17.34 541.16 17.34" fill="#d3d3d3"/>
                            <polygon points="538.53 86.33 532.76 83.78 571.12 83.78 590.3 50.56 571.12 17.34 576.89 19.89 596.07 53.11 576.89 86.33 538.53 86.33" fill="#b2b2b2" />
                            <polygon points="563.35 21.71 532.19 21.71 516.61 48.7 532.19 75.68 563.35 75.68 578.92 48.7 563.35 21.71" fill="#829bf9" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="536.88 77.75 532.19 75.68 563.35 75.68 578.92 48.7 563.35 21.71 568.03 23.79 583.61 50.77 568.03 77.75 536.88 77.75" fill="#7292d3" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="532.19 21.71 543.07 46.45 516.61 48.7 532.19 21.71" fill="#b7c6f9" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="563.35 21.71 543.07 46.45 578.92 48.7 563.35 21.71" fill="#376ced" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="543.07 46.45 572.6 59.52 578.92 48.7 543.07 46.45" fill="#b7c6f9" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="543.07 46.45 532.19 75.68 550.99 75.68 543.07 46.45" fill="#376ced" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="563.23 21.63 532.08 21.63 516.5 48.61 532.08 75.59 563.23 75.59 578.81 48.61 563.23 21.63" fill="#fff" opacity=".57" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                          </g>
                          <path d="M669.46,106.51l21.18,17.79V645.18H21.18L0,627.39H669.46s1.44-520.88,0-520.88Z" fill="#9b9b9b"/>
                          <g>
                            <polygon points="503.62 564.42 481.24 564.42 470.05 583.8 481.24 603.18 503.62 603.18 514.81 583.8 503.62 564.42" fill="#969696"/>
                            <polygon points="484.61 604.67 481.24 603.18 503.62 603.18 514.81 583.8 503.62 564.42 506.98 565.91 518.17 585.29 506.98 604.67 484.61 604.67" fill="#757575"/>
                            <polygon points="499.08 566.97 480.91 566.97 471.82 582.71 480.91 598.45 499.08 598.45 508.17 582.71 499.08 566.97" fill="#e264d0" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="483.64 599.66 480.91 598.45 499.08 598.45 508.17 582.71 499.08 566.97 501.81 568.18 510.9 583.92 501.81 599.66 483.64 599.66" fill="#b5309c" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="480.91 566.97 487.25 581.4 471.82 582.71 480.91 566.97" fill="#ef7be4" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="499.08 566.97 487.25 581.4 508.17 582.71 499.08 566.97" fill="#c13eaf" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="487.25 581.4 504.48 589.02 508.17 582.71 487.25 581.4" fill="#ef7be4" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                            <polygon points="487.25 581.4 480.91 598.45 491.88 598.45 487.25 581.4" fill="#c13eaf" :class="$store.getters.loadingStatus ? 'flicker' : 'flickerstatic'" />
                          </g>
                          <g>
                            <g>
                              <path d="M645.61,167v20.09l-7.01,4.19-22.53-7.47c-.08-.02-.15-.06-.22-.09h0c-.61-.3-1.01-.93-1.01-1.63v-42.49c0-.54,.24-1.06,.67-1.4,.35-.28,.74-.41,1.13-.41,.93,0,1.81,.73,1.81,1.81v13.12c0,.76,.48,1.44,1.2,1.7l12.32,4.38,10.43,3.71,3.22,4.48Z" fill="#376ced"/>
                              <polygon points="645.61 130.79 645.61 150.94 641.68 155.35 631.96 158.81 618.44 163.62 618.44 184.53 615.84 183.71 615.84 183.71 614.83 183.39 614.83 134.22 636.66 126.98 636.66 126.98 645.61 130.79" fill="#829bf9"/>
                            </g>
                            <path d="M645.58,130.78v1.12l-6.49-2.77h0l-21.77,7.22h-.15v47.8l-1.12-.38c-.08-.02-.15-.05-.22-.09h0l-1.01-.32v-49.17l21.84-7.24,8.94,3.81Z" fill="#5f7ace"/>
                          </g>
                        </g>
                        <g>
                          <polygon points="599.18 445.53 599.18 536.61 385.26 536.61 386.45 530.38 414.94 517.72 418.72 516.04 448.6 519.62 467.72 523.21 514.33 500.5 554.96 501.7 583.65 488.55 590.82 469.43 599.18 445.53" fill="#fff"/>
                          <polygon points="599.18 445.53 590.09 498.23 562.99 517.43 525.72 511.79 504.26 510.66 463.6 530.99 414.94 517.72 418.72 516.04 448.6 519.62 467.72 523.21 514.33 500.5 554.96 501.7 583.65 488.55 590.82 469.43 599.18 445.53" fill="#e1ebf4"/>
                        </g>
                      </g>
                  </svg>
                  <div class="bottom dt">
                    <div v-if="!getStatus">
                      <h3 v-if="getNoun || getAdj || getVerb || getStyle">Parts Selected:</h3>
                      <ul v-if="getNoun || getAdj || getVerb || getStyle">
                        <transition name="slide">
                          <li v-if="getNoun">{{ getNoun }}</li>
                        </transition>
                        <transition name="slide">
                          <li v-if="getAdj">{{ getAdj }}</li>
                        </transition>
                        <transition name="slide">
                          <li v-if="getVerb">{{ getVerb }}</li>
                        </transition>
                        <transition name="slide">
                          <li v-if="getStyle">{{ getStyle }}</li>
                        </transition>
                      </ul>
                    </div>
                    <div class="try-again" v-else>
                      <p>Did the elves put one of the parts on backwards? Try again, our return policy is very generous.</p>
                      <button type="button" class="button btn-nav reset" @click="reload"><span>Try Again</span></button>
                    </div>
                  </div>
                  <img src="../assets/images/soldier.svg" class="soldier" />
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151.67 618.79"  class="gen-lights-left">
                      <g>
                        <polyline id="d" data-name="left-line" points="137.29 98.92 80.3 28.89 23.3 185.23 122.64 284.56 15.16 437.64 77.53 618.47" fill="none" stroke="#1d7c13" stroke-miterlimit="10" stroke-width="2"/>
                        <g id="e" data-name="1">
                          <polygon points="34.97 237.53 36.87 243 40.09 244.95 41.41 241.43 39.51 235.96 36.29 234.01 34.97 237.53" fill="#62d14e" class="bulbc"/>
                          <polygon points="39.51 235.96 50.97 222.9 36.29 234.01 39.51 235.96" fill="#5ebc48" class="bulbc"/>
                          <polygon points="41.41 241.43 58.48 244.56 43.27 244.86 40.1 244.92 41.41 241.43" fill="#5ebc48" class="bulbc"/>
                          <polygon points="41.41 241.43 57.74 235.2 56.19 229.94 39.51 235.96 41.41 241.43" fill="#79e260" class="bulbc"/>
                          <polygon points="57.74 235.2 72.59 231.38 72.65 231.36 72.66 231.58 72.71 233.8 58.48 244.56 57.74 235.2" fill="#5bb545" class="bulbc"/>
                          <polygon points="50.97 222.9 69.53 222.54 69.05 223.83 56.33 231.13 50.97 222.9" fill="#5bb545" class="bulbc"/>
                          <polygon points="41.41 241.43 58.48 244.56 57.74 235.2 41.41 241.43" fill="#6fce59" class="bulbc"/>
                          <polygon points="39.51 235.96 56.33 231.13 50.97 222.9 39.51 235.96" fill="#6fce59" class="bulbc"/>
                          <polygon points="57.74 235.2 56.33 231.13 67.88 224.45 70.69 232.54 57.74 235.2" fill="#6fce59" class="bulbc"/>
                          <rect x="63.2" y="225.04" width="16.6" height="5.48" transform="translate(-120.33 369.91) rotate(-109.11)" fill="#383838"/>
                        </g>
                        <g id="f" data-name="2">
                          <polygon points="107.5 119.15 111.79 123.03 115.55 123.2 115.02 119.47 110.73 115.58 106.97 115.42 107.5 119.15" fill="#62d14e" class="bulbc"/>
                          <polygon points="110.73 115.58 114.51 98.63 106.97 115.42 110.73 115.58" fill="#5ebc48" class="bulbc"/>
                          <polygon points="115.02 119.47 131.49 114.02 118.3 121.59 115.55 123.17 115.02 119.47" fill="#5ebc48" class="bulbc"/>
                          <polygon points="115.02 119.47 126.35 106.17 122.47 102.29 110.73 115.58 115.02 119.47" fill="#79e260" class="bulbc"/>
                          <polygon points="126.35 106.17 137.54 95.68 137.59 95.64 137.7 95.82 138.81 97.75 131.49 114.02 126.35 106.17" fill="#5bb545" class="bulbc"/>
                          <polygon points="114.51 98.63 130.61 89.4 130.81 90.76 123.16 103.27 114.51 98.63" fill="#5bb545" class="bulbc"/>
                          <polygon points="115.02 119.47 131.49 114.02 126.35 106.17 115.02 119.47" fill="#6fce59" class="bulbc"/>
                          <polygon points="110.73 115.58 123.16 103.27 114.51 98.63 110.73 115.58" fill="#6fce59" class="bulbc"/>
                          <polygon points="126.35 106.17 123.16 103.27 130.09 91.87 136.43 97.62 126.35 106.17" fill="#6fce59" class="bulbc"/>
                          <rect x="126.56" y="90.31" width="16.6" height="5.48" transform="translate(172.28 252.57) rotate(-137.81)" fill="#383838"/>
                        </g>
                        <g id="g" data-name="3">
                          <polygon points="0 506.81 1.11 513.4 4.41 516.23 6.59 512.48 5.47 505.88 2.18 503.05 0 506.81" fill="#62d14e" class="bulbc"/>
                          <polygon points="5.47 505.88 21.02 493.21 2.18 503.05 5.47 505.88" fill="#5ebc48" class="bulbc"/>
                          <polygon points="6.58 512.47 25.42 519.3 8.05 516.73 4.43 516.2 6.58 512.47" fill="#5ebc48" class="bulbc"/>
                          <polygon points="6.58 512.47 26.37 508.51 25.62 502.22 5.47 505.88 6.58 512.47" fill="#79e260" class="bulbc"/>
                          <polygon points="26.37 508.51 44.01 506.99 44.09 506.98 44.05 507.23 43.68 509.77 25.42 519.3 26.37 508.51" fill="#5bb545" class="bulbc"/>
                          <polygon points="21.02 493.21 42.21 496.34 41.42 497.71 25.55 503.6 21.02 493.21" fill="#5bb545" class="bulbc"/>
                          <polygon points="6.58 512.47 25.42 519.3 26.37 508.51 6.58 512.47" fill="#6fce59" class="bulbc"/>
                          <polygon points="5.47 505.88 25.55 503.6 21.02 493.21 5.47 505.88" fill="#6fce59" class="bulbc"/>
                          <polygon points="26.37 508.51 25.55 503.6 39.98 498.2 41.62 507.95 26.37 508.51" fill="#6fce59" class="bulbc"/>
                          <rect x="33.87" y="499.53" width="19.17" height="6.33" transform="translate(-445 629.19) rotate(-99.58)" fill="#383838"/>
                        </g>
                        <g id="h" data-name="4">
                          <polygon points="76.16 0 70.47 1.1 68.09 4.02 71.39 5.82 77.07 4.72 79.46 1.81 76.16 0" fill="#ed4f75" class="bulbc"/>
                          <polygon points="77.07 4.72 88.37 17.91 79.46 1.81 77.07 4.72" fill="#a0133c" class="bulbc"/>
                          <polygon points="71.39 5.82 65.88 22.28 67.73 7.18 68.11 4.03 71.39 5.82" fill="#a0133c" class="bulbc"/>
                          <polygon points="71.39 5.82 75.24 22.87 80.67 22.08 77.07 4.72 71.39 5.82" fill="#fc1f49" class="bulbc"/>
                          <polygon points="75.24 22.87 76.93 38.11 76.94 38.18 76.72 38.15 74.51 37.88 65.88 22.28 75.24 22.87" fill="#a0133c" class="bulbc"/>
                          <polygon points="88.37 17.91 86.11 36.33 84.91 35.67 79.47 22.05 88.37 17.91" fill="#a0133c" class="bulbc"/>
                          <polygon points="71.39 5.82 65.88 22.28 75.24 22.87 71.39 5.82" fill="#d61241" class="bulbc"/>
                          <polygon points="77.07 4.72 79.47 22.05 88.37 17.91 77.07 4.72" fill="#d61241" class="bulbc"/>
                          <polygon points="75.24 22.87 79.47 22.05 84.45 34.43 76.05 36.06 75.24 22.87" fill="#ed4f75" class="bulbc"/>
                          <rect x="72.34" y="34.8" width="16.6" height="5.48" transform="translate(-5.67 16.05) rotate(-10.98)" fill="#383838"/>
                        </g>
                        <g id="i" data-name="5">
                          <polygon points="93.84 577.3 88.97 574.17 85.24 574.62 86.37 578.21 91.24 581.35 94.98 580.89 93.84 577.3" fill="#ed4f75" class="bulbc"/>
                          <polygon points="91.24 581.35 90.28 598.69 94.98 580.89 91.24 581.35" fill="#a0133c" class="bulbc"/>
                          <polygon points="86.37 578.21 71.02 586.29 82.79 576.66 85.24 574.65 86.37 578.21" fill="#a0133c" class="bulbc"/>
                          <polygon points="86.37 578.21 77.37 593.19 81.84 596.38 91.24 581.35 86.37 578.21" fill="#fc1f49" class="bulbc"/>
                          <polygon points="77.37 593.19 68.05 605.37 68.01 605.42 67.87 605.26 66.46 603.53 71.02 586.29 77.37 593.19" fill="#a0133c" class="bulbc"/>
                          <polygon points="90.28 598.69 75.91 610.43 75.5 609.12 80.99 595.52 90.28 598.69" fill="#a0133c" class="bulbc"/>
                          <polygon points="86.37 578.21 71.02 586.29 77.37 593.19 86.37 578.21" fill="#d61241" class="bulbc"/>
                          <polygon points="91.24 581.35 80.99 595.52 90.28 598.69 91.24 581.35" fill="#d61241" class="bulbc"/>
                          <polygon points="77.37 593.19 80.99 595.52 76.03 607.91 68.83 603.28 77.37 593.19" fill="#ed4f75" class="bulbc"/>
                          <rect x="62.83" y="604.78" width="16.6" height="5.48" transform="translate(340.11 58.17) rotate(32.77)" fill="#383838"/>
                        </g>
                        <g id="j" data-name="6">
                          <polygon points="148.74 305.86 151.67 299.87 150.65 295.66 146.7 297.44 143.77 303.43 144.79 307.63 148.74 305.86" fill="#ed4f75" class="bulbc"/>
                          <polygon points="143.77 303.43 123.83 304.66 144.79 307.63 143.77 303.43" fill="#a0133c" class="bulbc"/>
                          <polygon points="146.7 297.44 135.41 280.98 147.99 293.14 150.61 295.68 146.7 297.44" fill="#a0133c" class="bulbc"/>
                          <polygon points="146.7 297.44 128.37 289.17 125.33 294.7 143.77 303.43 146.7 297.44" fill="#fc1f49" class="bulbc"/>
                          <polygon points="128.37 289.17 113.21 280.15 113.14 280.11 113.31 279.93 115.09 278.09 135.41 280.98 128.37 289.17" fill="#a0133c" class="bulbc"/>
                          <polygon points="123.83 304.66 108.48 289.82 109.92 289.17 126.19 293.62 123.83 304.66" fill="#a0133c" class="bulbc"/>
                          <polygon points="146.7 297.44 135.41 280.98 128.37 289.17 146.7 297.44" fill="#d61241" class="bulbc"/>
                          <polygon points="143.77 303.43 126.19 293.62 123.83 304.66 143.77 303.43" fill="#d61241" class="bulbc"/>
                          <polygon points="128.37 289.17 126.19 293.62 111.37 289.61 115.7 280.76 128.37 289.17" fill="#ed4f75" class="bulbc"/>
                          <rect x="101.61" y="280.8" width="19.1" height="6.31" transform="translate(415.08 308.85) rotate(116.06)" fill="#383838"/>
                        </g>
                        <g id="k" data-name="7">
                          <polygon points="73.74 138.21 72.55 132.54 69.6 130.2 67.84 133.53 69.03 139.2 71.98 141.54 73.74 138.21" fill="#fffc92" class="bulbc"/>
                          <polygon points="69.03 139.2 56.03 150.71 71.98 141.54 69.03 139.2" fill="#aaa321" class="bulbc"/>
                          <polygon points="67.84 133.53 51.3 128.29 66.42 129.9 69.58 130.23 67.84 133.53" fill="#aaa321" class="bulbc"/>
                          <polygon points="67.84 133.53 50.86 137.66 51.73 143.08 69.03 139.2 67.84 133.53" fill="#f9f921" class="bulbc"/>
                          <polygon points="50.86 137.67 35.65 139.6 35.58 139.6 35.6 139.39 35.83 137.18 51.3 128.29 50.86 137.67" fill="#aaa321" class="bulbc"/>
                          <polygon points="56.03 150.71 37.58 148.75 38.21 147.53 51.75 141.88 56.03 150.71" fill="#aaa321" class="bulbc"/>
                          <polygon points="67.84 133.53 51.3 128.29 50.86 137.67 67.84 133.53" fill="#d6d234" class="bulbc"/>
                          <polygon points="69.03 139.2 51.75 141.88 56.03 150.71 69.03 139.2" fill="#d6d234" class="bulbc"/>
                          <polygon points="50.86 137.67 51.75 141.88 39.45 147.06 37.68 138.68 50.86 137.67" fill="#dbd628" class="bulbc"/>
                          <rect x="27.98" y="140.56" width="16.6" height="5.48" transform="translate(169.01 78.24) rotate(78.09)" fill="#383838"/>
                        </g>
                        <g id="l" data-name="8">
                          <polygon points="17.95 360.82 15.87 367.4 17.56 371.56 21.34 369.13 23.42 362.55 21.72 358.39 17.95 360.82" fill="#fffc92" class="bulbc"/>
                          <polygon points="23.42 362.55 43.66 358.22 21.72 358.39 23.42 362.55" fill="#aaa321" class="bulbc"/>
                          <polygon points="21.33 369.13 35.43 384.26 20.68 373.73 17.6 371.54 21.33 369.13" fill="#aaa321" class="bulbc"/>
                          <polygon points="21.33 369.13 41.39 374.79 43.65 368.66 23.42 362.55 21.33 369.13" fill="#f9f921" class="bulbc"/>
                          <polygon points="41.39 374.79 58.31 381.7 58.38 381.73 58.23 381.94 56.7 384.1 35.43 384.26 41.39 374.79" fill="#aaa321" class="bulbc"/>
                          <polygon points="43.66 358.22 61.67 371.07 60.29 371.96 42.93 369.9 43.66 358.22" fill="#aaa321" class="bulbc"/>
                          <polygon points="21.33 369.13 35.43 384.26 41.39 374.79 21.33 369.13" fill="#d6d234" class="bulbc"/>
                          <polygon points="23.42 362.55 42.93 369.9 43.66 358.22 23.42 362.55" fill="#d6d234" class="bulbc"/>
                          <polygon points="41.39 374.79 42.93 369.9 58.74 371.73 55.66 381.46 41.39 374.79" fill="#dbd628" class="bulbc"/>
                          <rect x="49.93" y="374.22" width="19.79" height="6.53" transform="translate(-318.15 320.79) rotate(-72.46)" fill="#383838"/>
                        </g>
                      </g>
                    </svg>
                </div>
                <div class="columns large-7 medium-12 small-12 screen-right">
                  <div class="img-wrp">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 622.43 228.86" class="gen-lights-right dt">
                      <g>
                        <polyline points="614.23 202.99 594.32 80.3 513.89 57.25 421.83 76.9 338.65 122.64 211.03 92.81 120.35 41.5 4.74 77.53" fill="none" stroke="#1d7c13" stroke-miterlimit="10" stroke-width="2"/>
                        <g>
                          <polygon points="414.25 42.91 408.78 44.8 406.83 48.02 410.35 49.35 415.82 47.45 417.77 44.23 414.25 42.91" fill="#62d14e" class="bulbd"/>
                          <polygon points="415.82 47.45 428.88 58.91 417.77 44.23 415.82 47.45" fill="#5ebc48" class="bulbd"/>
                          <polygon points="410.35 49.34 407.22 66.41 406.92 51.21 406.86 48.03 410.35 49.34" fill="#5ebc48" class="bulbd"/>
                          <polygon points="410.35 49.34 416.58 65.68 421.84 64.13 415.82 47.45 410.35 49.34" fill="#79e260" class="bulbd"/>
                          <polygon points="416.58 65.68 420.4 80.52 420.42 80.59 420.2 80.59 417.98 80.64 407.22 66.41 416.58 65.68" fill="#5bb545" class="bulbd"/>
                          <polygon points="428.88 58.91 429.24 77.46 427.95 76.98 420.65 64.27 428.88 58.91" fill="#5bb545" class="bulbd"/>
                          <polygon points="410.35 49.34 407.22 66.41 416.58 65.68 410.35 49.34" fill="#6fce59" class="bulbd"/>
                          <polygon points="415.82 47.45 420.65 64.27 428.88 58.91 415.82 47.45" fill="#6fce59" class="bulbd"/>
                          <polygon points="416.58 65.68 420.65 64.27 427.33 75.82 419.24 78.62 416.58 65.68" fill="#6fce59" class="bulbd"/>
                          <rect x="415.7" y="76.69" width="16.6" height="5.48" transform="translate(-2.64 143.17) rotate(-19.11)" fill="#383838"/>
                        </g>
                        <g>
                          <polygon points="595.09 226.38 600.32 228.86 603.97 227.93 602.39 224.52 597.16 222.03 593.51 222.96 595.09 226.38" fill="#62d14e" class="bulbd"/>
                          <polygon points="597.16 222.03 595.89 204.71 593.51 222.96 597.16 222.03" fill="#5ebc48" class="bulbd"/>
                          <polygon points="602.39 224.52 616.58 214.55 606.14 225.6 603.96 227.9 602.39 224.52" fill="#5ebc48" class="bulbd"/>
                          <polygon points="602.39 224.52 609.4 208.51 604.56 205.92 597.16 222.03 602.39 224.52" fill="#79e260" class="bulbd"/>
                          <polygon points="609.4 208.51 617.09 195.24 617.12 195.19 617.28 195.33 618.9 196.86 616.58 214.55 609.4 208.51" fill="#5bb545" class="bulbd"/>
                          <polygon points="595.89 204.71 608.64 191.23 609.22 192.47 605.51 206.66 595.89 204.71" fill="#5bb545" class="bulbd"/>
                          <polygon points="602.39 224.52 616.58 214.55 609.4 208.51 602.39 224.52" fill="#6fce59" class="bulbd"/>
                          <polygon points="597.16 222.03 605.51 206.66 595.89 204.71 597.16 222.03" fill="#6fce59" class="bulbd"/>
                          <polygon points="609.4 208.51 605.51 206.66 608.85 193.74 616.58 197.42 609.4 208.51" fill="#6fce59" class="bulbd"/>
                          <rect x="605.46" y="190.76" width="16.6" height="5.48" transform="translate(1085.05 631.71) rotate(-154.58)" fill="#383838"/>
                        </g>
                        <g>
                          <polygon points="116.41 0 109.81 1.11 106.98 4.41 110.74 6.59 117.33 5.47 120.17 2.18 116.41 0" fill="#62d14e" class="bulbd"/>
                          <polygon points="117.33 5.47 130 21.02 120.17 2.18 117.33 5.47" fill="#5ebc48" class="bulbd"/>
                          <polygon points="110.74 6.58 103.91 25.42 106.48 8.05 107.01 4.43 110.74 6.58" fill="#5ebc48" class="bulbd"/>
                          <polygon points="110.74 6.58 114.71 26.37 121 25.62 117.33 5.47 110.74 6.58" fill="#79e260" class="bulbd"/>
                          <polygon points="114.71 26.37 116.22 44.01 116.23 44.09 115.98 44.05 113.44 43.68 103.91 25.42 114.71 26.37" fill="#5bb545" class="bulbd"/>
                          <polygon points="130 21.02 126.87 42.21 125.5 41.42 119.61 25.55 130 21.02" fill="#5bb545" class="bulbd"/>
                          <polygon points="110.74 6.58 103.91 25.42 114.71 26.37 110.74 6.58" fill="#6fce59" class="bulbd"/>
                          <polygon points="117.33 5.47 119.61 25.55 130 21.02 117.33 5.47" fill="#6fce59" class="bulbd"/>
                          <polygon points="114.71 26.37 119.61 25.55 125.01 39.98 115.26 41.62 114.71 26.37" fill="#6fce59" class="bulbd"/>
                          <rect x="110.94" y="40.29" width="19.17" height="6.33" transform="translate(-5.55 20.66) rotate(-9.58)" fill="#383838"/>
                        </g>
                        <g>
                          <polygon points="613.53 53.2 608.53 50.27 604.82 50.88 606.1 54.42 611.09 57.35 614.81 56.74 613.53 53.2" fill="#ed4f75" class="bulbd"/>
                          <polygon points="611.09 57.35 610.85 74.71 614.81 56.74 611.09 57.35" fill="#a0133c" class="bulbd"/>
                          <polygon points="606.1 54.41 591.09 63.12 602.46 53.02 604.83 50.91 606.1 54.41" fill="#a0133c" class="bulbd"/>
                          <polygon points="606.1 54.41 597.72 69.76 602.32 72.75 611.09 57.35 606.1 54.41" fill="#fc1f49" class="bulbd"/>
                          <polygon points="597.72 69.76 588.91 82.31 588.87 82.36 588.73 82.2 587.25 80.54 591.09 63.12 597.72 69.76" fill="#a0133c" class="bulbd"/>
                          <polygon points="610.85 74.71 596.98 87.04 596.51 85.75 601.44 71.94 610.85 74.71" fill="#a0133c" class="bulbd"/>
                          <polygon points="606.1 54.41 591.09 63.12 597.72 69.76 606.1 54.41" fill="#d61241" class="bulbd"/>
                          <polygon points="611.09 57.35 601.44 71.94 610.85 74.71 611.09 57.35" fill="#d61241" class="bulbd"/>
                          <polygon points="597.72 69.76 601.44 71.94 596.99 84.52 589.6 80.19 597.72 69.76" fill="#ed4f75" class="bulbd"/>
                          <rect x="583.77" y="81.59" width="16.6" height="5.48" transform="translate(124.09 -288.03) rotate(30.4)" fill="#383838"/>
                        </g>
                        <g>
                          <polygon points="7.74 106.57 13.53 106.81 16.52 104.53 13.73 102 7.94 101.76 4.95 104.04 7.74 106.57" fill="#ed4f75" class="bulbd"/>
                          <polygon points="7.94 101.76 0 86.31 4.95 104.04 7.94 101.76" fill="#a0133c" class="bulbd"/>
                          <polygon points="13.73 102.01 22.89 87.27 17.6 101.53 16.5 104.5 13.73 102.01" fill="#a0133c" class="bulbd"/>
                          <polygon points="13.73 102.01 13.92 84.53 8.46 84.04 7.94 101.76 13.73 102.01" fill="#fc1f49" class="bulbd"/>
                          <polygon points="13.92 84.53 15.81 69.31 15.82 69.24 16.02 69.32 18.11 70.09 22.89 87.27 13.92 84.53" fill="#a0133c" class="bulbd"/>
                          <polygon points="0 86.31 6.46 68.92 7.48 69.84 9.62 84.35 0 86.31" fill="#a0133c" class="bulbd"/>
                          <polygon points="13.73 102.01 22.89 87.27 13.92 84.53 13.73 102.01" fill="#d61241" class="bulbd"/>
                          <polygon points="7.94 101.76 9.62 84.35 0 86.31 7.94 101.76" fill="#d61241" class="bulbd"/>
                          <polygon points="13.92 84.53 9.62 84.35 7.64 71.15 16.19 71.51 13.92 84.53" fill="#ed4f75" class="bulbd"/>
                          <rect x="3.76" y="66.27" width="16.6" height="5.48" transform="translate(21.23 138.46) rotate(-177.6)" fill="#383838"/>
                        </g>
                        <g>
                          <polygon points="317.36 148.74 323.34 151.67 327.55 150.65 325.77 146.7 319.79 143.77 315.58 144.79 317.36 148.74" fill="#ed4f75" class="bulbd"/>
                          <polygon points="319.79 143.77 318.55 123.83 315.58 144.79 319.79 143.77" fill="#a0133c" class="bulbd"/>
                          <polygon points="325.77 146.7 342.23 135.41 330.07 147.99 327.54 150.61 325.77 146.7" fill="#a0133c" class="bulbd"/>
                          <polygon points="325.77 146.7 334.05 128.37 328.51 125.33 319.79 143.77 325.77 146.7" fill="#fc1f49" class="bulbd"/>
                          <polygon points="334.05 128.37 343.06 113.21 343.1 113.14 343.28 113.31 345.12 115.09 342.23 135.41 334.05 128.37" fill="#a0133c" class="bulbd"/>
                          <polygon points="318.55 123.83 333.39 108.48 334.05 109.92 329.6 126.19 318.55 123.83" fill="#a0133c" class="bulbd"/>
                          <polygon points="325.77 146.7 342.23 135.41 334.05 128.37 325.77 146.7" fill="#d61241" class="bulbd"/>
                          <polygon points="319.79 143.77 329.6 126.19 318.55 123.83 319.79 143.77" fill="#d61241" class="bulbd"/>
                          <polygon points="334.05 128.37 329.6 126.19 333.6 111.37 342.45 115.7 334.05 128.37" fill="#ed4f75" class="bulbd"/>
                          <rect x="329.7" y="108.01" width="19.1" height="6.31" transform="translate(595.18 360.06) rotate(-153.94)" fill="#383838"/>
                        </g>
                        <g>
                          <polygon points="515.78 22.26 509.99 22.12 507.04 24.45 509.87 26.93 515.66 27.07 518.62 24.73 515.78 22.26" fill="#fffc92" class="bulbd"/>
                          <polygon points="515.66 27.07 523.88 42.37 518.62 24.73 515.66 27.07" fill="#aaa321" class="bulbd"/>
                          <polygon points="509.87 26.92 500.97 41.82 506.01 27.47 507.06 24.47 509.87 26.92" fill="#aaa321" class="bulbd"/>
                          <polygon points="509.87 26.92 509.99 44.4 515.46 44.8 515.66 27.07 509.87 26.92" fill="#f9f921" class="bulbd"/>
                          <polygon points="509.99 44.4 508.37 59.65 508.36 59.72 508.16 59.65 506.06 58.91 500.97 41.82 509.99 44.4" fill="#aaa321" class="bulbd"/>
                          <polygon points="523.88 42.37 517.72 59.88 516.68 58.98 514.29 44.51 523.88 42.37" fill="#aaa321" class="bulbd"/>
                          <polygon points="509.87 26.92 500.97 41.82 509.99 44.4 509.87 26.92" fill="#d6d234" class="bulbd"/>
                          <polygon points="515.66 27.07 514.29 44.51 523.88 42.37 515.66 27.07" fill="#d6d234" class="bulbd"/>
                          <polygon points="509.99 44.4 514.29 44.51 516.51 57.67 507.95 57.46 509.99 44.4" fill="#dbd628" class="bulbd"/>
                          <rect x="503.82" y="57.15" width="16.6" height="5.48" transform="translate(1.6 -12.4) rotate(1.39)" fill="#383838"/>
                        </g>
                        <g>
                          <polygon points="242.97 62.89 238.05 58.06 233.57 57.68 234.03 62.15 238.96 66.98 243.43 67.35 242.97 62.89" fill="#fffc92" class="bulbd"/>
                          <polygon points="238.96 66.98 233.65 87 243.43 67.35 238.96 66.98" fill="#aaa321" class="bulbd"/>
                          <polygon points="234.03 62.15 214.16 67.87 230.23 59.48 233.58 57.72 234.03 62.15" fill="#aaa321" class="bulbd"/>
                          <polygon points="234.03 62.15 219.91 77.46 224.35 82.26 238.96 66.98 234.03 62.15" fill="#f9f921" class="bulbd"/>
                          <polygon points="219.91 77.46 206.09 89.42 206.03 89.48 205.91 89.25 204.67 86.9 214.16 67.87 219.91 77.46" fill="#aaa321" class="bulbd"/>
                          <polygon points="233.65 87 214.04 97.23 213.87 95.61 223.57 81.06 233.65 87" fill="#aaa321" class="bulbd"/>
                          <polygon points="234.03 62.15 214.16 67.87 219.91 77.46 234.03 62.15" fill="#d6d234" class="bulbd"/>
                          <polygon points="238.96 66.98 223.57 81.06 233.65 87 238.96 66.98" fill="#d6d234" class="bulbd"/>
                          <polygon points="219.91 77.46 223.57 81.06 214.79 94.32 207.5 87.17 219.91 77.46" fill="#dbd628" class="bulbd"/>
                          <rect x="199.26" y="89.41" width="19.79" height="6.53" transform="translate(124.76 -119.96) rotate(44.45)" fill="#383838"/>
                        </g>
                      </g>
                    </svg>
                    <ImgGen/>
                  </div>
                  <div class="bottom mob">
                    <div v-if="!getStatus">
                      <h3 v-if="getNoun || getAdj || getVerb || getStyle">Parts Selected:</h3>
                      <ul v-if="getNoun || getAdj || getVerb || getStyle">
                        <transition name="slide">
                          <li v-if="getNoun">{{ getNoun }}</li>
                        </transition>
                        <transition name="slide">
                          <li v-if="getAdj">{{ getAdj }}</li>
                        </transition>
                        <transition name="slide">
                          <li v-if="getVerb">{{ getVerb }}</li>
                        </transition>
                        <transition name="slide">
                          <li v-if="getStyle">{{ getStyle }}</li>
                        </transition>
                      </ul>
                    </div>
                    <div class="try-again" v-else>
                      <p>Did the elves put one of the parts on backwards? It happens to the best of us! Try again, our return policy is very generous.</p>
                      <button type="button" class="button btn-nav reset" @click="reload"><span>Try Again</span></button>
                    </div>
                  </div>
                  <img src="../assets/images/bear.svg" class="bear" />
                </div>
              </div>
            </div>
          </div>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920.86 1080.15" class="pipes">
            <g>
              <polygon points="1920.86 365.79 1920.86 400.53 618.22 400.53 618.22 456.08 583.48 456.08 583.48 375.56 603.95 365.79 1920.86 365.79" fill="#bfbfbf" opacity=".7"/>
              <polygon points="896.47 1004.04 896.47 1026.5 881.79 1038.41 881.34 1038.78 0 1038.78 0 1004.04 858.19 1004.04 858.19 938.43 896.38 938.43 896.38 1004.04 896.47 1004.04" fill="#bfbfbf" opacity=".3"/>
              <rect x="1459.61" y="0" width="30.52" height="1133.5" fill="#4c618c"/>
              <rect x="711.74" width="30.52" height="816.4" fill="#4c618c"/>
              <rect y="499.48" width="1920.86" height="53.58" fill="#223a5e"/>
              <rect y="228.74" width="958.03" height="53.58" fill="#223a5e" opacity=".55" class="dt"/>
            </g>
          </svg>
        </div>
      </div>
      <div class="row">
        <div class="columns">
          <GiftShop />
        </div>
      </div>
    </section>
    <section class="bottom-banner">
      <div class="row align-center">
        <div class="columns large-11 medium-11 small-10">
          <div class="row align-justify align-middle">
            <div class="columns large-7 medium-6 small-12">
              <p>Share your joy with the world!</p>
            </div>
            <div class="columns large-4 medium-5 small-12">
              <a href="#" class="button styled-button share-link" @click="shareBtm">
                <span>Share this Experience</span>
                <span class="share-mod" v-if="shareBottom">
                <ul class="share">
                  <li>
                  <ShareNetwork
                    network="twitter"
                    :url="pageURL"
                    :title="this.sharing.title"
                    :description="this.sharing.description"
                    :hashtags="this.sharing.hashtags"
                  >
                  <i class="fab fa-twitter"></i>
                  </ShareNetwork>
                </li>
                <li>
                  <ShareNetwork
                      network="facebook"
                      :url="pageURL"
                      :title="this.sharing.title"
                      :description="this.sharing.description"
                      :hashtags="this.sharing.hashtags"
                    >
                    <i class="fab fa-facebook"></i>
                  </ShareNetwork>
                </li>
                <li>
                  <ShareNetwork
                      network="linkedin"
                      :url="pageURL"
                      :title="this.sharing.title"
                      :description="this.sharing.description"
                      :hashtags="this.sharing.hashtags"
                    >
                    <i class="fab fa-linkedin"></i>
                  </ShareNetwork>
                </li>
                <li>
                  <ShareNetwork
                      network="email"
                      :url="pageURL"
                      :title="this.sharing.title"
                      :description="this.sharing.description"
                      :hashtags="this.sharing.hashtags"
                    >
                    <i class="fas fa-envelope"></i>
                  </ShareNetwork>
                  </li>
                  <li class="close">
                    <a href="#" class="closeThis">Close</a>
                  </li>
                </ul>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer>
      <div class="row align-center">
        <div class="columns large-12 medium-12 small-10">
          <img src="../assets/images/pb-logo.svg" alt="Park & Battery" class="logo" />
          <p class="lg">From the elves at Park &amp; Battery</p>
          <p class="legal"><a href="https://www.parkandbattery.com" target="_blank">Park & Battery</a> is a brand, marketing and content agency that harnesses perspectives to create clarity, empower action and deliver outcomes for brands and businesses globally. If you’re looking for a B2B marketing agency. If you’re looking for a B2C advertising agency. If you’re looking for a bit of both, we want to hear from you.</p>
          <ul>
            <li>
              <a href="https://twitter.com/parkandbattery" target="_blank">
                <i class="fab fa-twitter"></i>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/parkandbattery/" target="_blank">
                <i class="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/parkandbattery" target="_blank">
                <i class="fab fa-linkedin"></i>
              </a>
            </li>
          </ul>
          <p class="legal">&copy; 2022 Park & Battery</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import ImgGen from '@/components/ImgGen.vue'
import Marquee from '@/components/Marquee.vue'
import GiftShop from '@/components/GiftShop.vue'
import Vue from 'vue'
import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing)

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'Home',
  components: {
    ImgGen,
    Marquee,
    GiftShop
  },
  data () {
    return {
      videoActive: false,
      isShare: false,
      shareBottom: false,
      sharing: {
        title: 'Look what I made at The Holid-A.I. Gift Factory from @parkandbattery! ',
        hashtags: '#holidaifactory'
      }
    }
  },
  computed: {
    getCurrentStep () {
      return this.$store.getters.getStep
    },
    getNoun () {
      return this.$store.getters.getNoun
    },
    getAdj () {
      return this.$store.getters.getAdj
    },
    getVerb () {
      return this.$store.getters.getVerb
    },
    getStyle () {
      return this.$store.getters.getStyle
    },
    getStatus () {
      return this.$store.getters.getImg
    },
    pageURL () {
      return 'https://holiday.parkandbattery.com'
    }
  },
  methods: {
    reload () {
      window.location.href = 'https://holiday.parkandbattery.com'
    },
    playVideo () {
      this.videoActive = !this.videoActive
    },
    closeVideo () {
      this.videoActive = false
    },
    showShare () {
      this.isShare = !this.isShare
    },
    hideShare () {
      this.isShare = false
    },
    shareBtm (event) {
      event.preventDefault()
      this.shareBottom = !this.shareBottom
    }
  },
  mounted () {
    const bulbs = document.querySelectorAll('.bulb')
    const bulbsc = document.querySelectorAll('.bulbc')
    const bulbsd = document.querySelectorAll('.bulbd')
    const arrows = document.querySelectorAll('.scroll span')
    const lights = gsap.timeline({
      repeat: -1,
      repeatDelay: 0.8
    })
    lights.to(bulbs, { filter: 'brightness(1.5)', yoyo: true, duration: 0.5, ease: 'bounce.out', stagger: { amount: 0.2 } })

    const lightsc = gsap.timeline({
      repeat: -1,
      repeatDelay: 0.8
    })
    lightsc.to(bulbsc, { filter: 'brightness(1.3)', yoyo: true, duration: 0.5, ease: 'bounce.out', stagger: { amount: 0.3 } })

    const lightsd = gsap.timeline({
      repeat: -1,
      repeatDelay: 0.8
    })
    lightsd.to(bulbsd, { filter: 'brightness(1.3)', yoyo: true, duration: 0.5, ease: 'bounce.out', stagger: { amount: 0.4 } })

    const ar = gsap.timeline({
      repeat: -1,
      repeatDelay: 0.8
    })
    ar.to(arrows, { y: 0, opacity: 1, duration: 0.8, ease: 'slow(0.5, 0.4, false)', stagger: { amount: 0.4 } })

    window.addEventListener('keydown', (event) => {
      if (this.videoActive && event.keyCode === 27) {
        console.log(event)
        this.closeVideo()
      }
    })
  }
}
</script>

<style lang="scss">
  .video-wrap {
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .inner {
      position: absolute;
      top: 10%;
      left: 0;
      right: 0;
      margin: 0 auto;
      max-width: 80%;

      button {
        position: absolute;
        top: -30px;
        right: -30px;
        color: #fff;
        font-size: 30px;
        cursor: pointer;
      }
    }
  }
</style>
