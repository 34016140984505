<template>
  <div class="wrap">
     <div class="slides-container">
      <div class="slides-inner">
        <div class="slide" v-for="(img, key) in this.images" :key="key">
          <img :src="require(`../assets/images/marquee/${img.img}`)" :class="`img-${key}`" />
        </div>
      </div>
    </div>
    <div class="controls">
      <button id="prevButton">Prev</button>
      <button id="nextButton">Next</button>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import Draggable from 'gsap/Draggable'
import InertiaPlugin from 'gsap/InertiaPlugin'

gsap.registerPlugin(Draggable)
gsap.registerPlugin(InertiaPlugin)

export default {
  name: 'Marquee',
  data () {
    return {
      images: [
        { img: 'A.png' },
        { img: 'B.png' },
        { img: 'C.png' },
        { img: 'J.png' },
        { img: 'D.png' },
        { img: 'E.png' },
        { img: 'F.png' },
        { img: 'H.png' },
        { img: 'I.png' },
        { img: 'K.png' },
        { img: 'G.png' },
        { img: 'L.png' }
      ]
    }
  },
  mounted () {
    gsap.registerPlugin(Draggable, InertiaPlugin)
    var slideDelay = 0.5
    var slideDuration = 7
    var wrap = true

    var slides = document.querySelectorAll('.slide')
    var prevButton = document.querySelector('#prevButton')
    var nextButton = document.querySelector('#nextButton')
    var progressWrap = gsap.utils.wrap(0, 1)

    var numSlides = slides.length

    gsap.set(slides, {
      xPercent: i => i * 100
    })

    var wrapX = gsap.utils.wrap(-100, (numSlides - 1) * 100)
    var timer = gsap.delayedCall(slideDelay, autoPlay)

    var animation = gsap.to(slides, {
      xPercent: '+=' + (numSlides * 100),
      duration: 1,
      ease: 'none',
      paused: true,
      repeat: -1,
      modifiers: {
        xPercent: wrapX
      }
    })

    var proxy = document.createElement('div')
    var slideAnimation = gsap.to({}, {})
    var slideWidth = 0
    var wrapWidth = 0

    var draggable = new Draggable(proxy, {
      trigger: '.slides-container',
      inertia: true,
      onPress: updateDraggable,
      onDrag: updateProgress,
      onThrowUpdate: updateProgress,
      snap: {
        x: snapX
      }
    })

    resize()

    window.addEventListener('resize', resize)

    prevButton.addEventListener('click', function () {
      animateSlides(1)
    })

    nextButton.addEventListener('click', function () {
      animateSlides(-1)
    })

    function updateDraggable () {
      timer.restart(true)
      slideAnimation.kill()
      this.update()
    }

    function animateSlides (direction) {
      timer.restart(true)
      slideAnimation.kill()
      var x = snapX(gsap.getProperty(proxy, 'x') + direction * slideWidth)
      slideAnimation = gsap.to(proxy, {
        x: x,
        duration: slideDuration,
        onUpdate: updateProgress
      })
    }
    function autoPlay () {
      if (draggable.isPressed || draggable.isDragging || draggable.isThrowing) {
        timer.restart(true)
      } else {
        animateSlides(-1)
      }
    }

    function updateProgress () {
      animation.progress(progressWrap(gsap.getProperty(proxy, 'x') / wrapWidth))
    }

    function snapX (value) {
      const snapped = gsap.utils.snap(slideWidth, value)
      return wrap ? snapped : gsap.utils.clamp(-slideWidth * (numSlides - 1), 0, snapped)
    }

    function resize () {
      var norm = (gsap.getProperty(proxy, 'x') / wrapWidth) || 0
      slideWidth = slides[0].offsetWidth
      wrapWidth = slideWidth * numSlides
      wrap || draggable.applyBounds({ minX: -slideWidth * (numSlides - 1), maxX: 0 })
      gsap.set(proxy, {
        x: norm * wrapWidth
      })
      animateSlides(0)
      slideAnimation.progress(1)
    }

    animateSlides(0)

    // const gifts = document.querySelectorAll('.gifts')
    // const belt = gsap.timeline({
    //   repeat: -1
    // })
    // belt.to(gifts, { x: '110vw', duration: 15, ease: 'sine.inOut', stagger: { amount: 22.5, each: 4 } })
  }
}
</script>

<style scoped lang="scss">
.wrap {
  position: relative;
  top: -80px;
  overflow: hidden;
  z-index: 5;

  .slides-container {
    position: relative;
    background-image: url('../assets/images/conveyor.svg');
    background-repeat: repeat-x;
    background-size: auto 380px;
    height: 380px;
    width: 100vw;
  }

  .slides-inner {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;

    .slide {
      position: absolute;
      top: 81px;
      height: 200px;
      width: 250px;
      text-align: center;

      img {
        max-width: 100%;
        height: auto;
        padding: 0 50px;

        &.img-0 {
          margin-top: -80px;
        }

        &.img-1 {
          margin-top: -30px;
        }

        &.img-3 {
          margin-top: -20px;
        }

        &.img-4 {
          margin-top: -20px;
        }

        &.img-7 {
          margin-top: 60px;
        }
      }

      &::after {
        content: '';
        width: 2px;
        height: 170px;
        background: #181919;
        display: block;
        position: absolute;
        top: 0;
        transform: skew(-15deg, 10deg);
      }
    }
  }

  .conveyor {
    position: relative;
    width: 100vw;
    z-index: 4;
  }

  .controls {
    position: absolute;
    opacity: 0;
  }

  .gifts {
    transform: translateX(-500px);
  }
}
</style>
