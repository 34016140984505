import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex, VueAxios, axios)

axios.defaults.baseURL = 'https://holiday.parkandbattery.com/api/'

export default new Vuex.Store({
  state: {
    imgURL: '',
    loadingStatus: false,
    recipientName: '',
    senderName: '',
    noun: '',
    adj: '',
    verb: '',
    style: '',
    step: 1
  },
  mutations: {
    createImg (state, imgURL) {
      state.imgURL = imgURL
    },
    loadingStatus (state, newLoadingStatus) {
      state.loadingStatus = newLoadingStatus
    },
    setNameMutate (state, senderName) {
      state.senderName = senderName
    },
    setNameToMutate (state, recipientName) {
      state.recipientName = recipientName
    },
    setNounMutate (state, noun) {
      state.noun = noun
    },
    setAdjMutate (state, adj) {
      state.adj = adj
    },
    setVerbMutate (state, verb) {
      state.verb = verb
    },
    setStyleMutate (state, style) {
      state.style = style
    },
    setStepMutate (state, step) {
      state.step = step
    }
  },
  actions: {
    loadImg ({ commit, state }, payload) {
      commit('loadingStatus', true)
      axios.get(`connect?req=${payload}`)
        .then(result => {
          commit('createImg', result.data)
          commit('loadingStatus', false)
        })
        .catch(error => {
          throw new Error(`API ${error}`)
        })
    },
    setName ({ commit, state }, payload) {
      commit('setNameMutate', payload)
    },
    setNameTo ({ commit, state }, payload) {
      commit('setNameToMutate', payload)
    },
    setNoun ({ commit, state }, payload) {
      commit('setNounMutate', payload)
    },
    setAdj ({ commit, state }, payload) {
      commit('setAdjMutate', payload)
    },
    setVerb ({ commit, state }, payload) {
      commit('setVerbMutate', payload)
    },
    setStyle ({ commit, state }, payload) {
      commit('setStyleMutate', payload)
    },
    setStep ({ commit, state }, payload) {
      commit('setStepMutate', payload)
    }
  },
  getters: {
    loadingStatus (state) {
      return state.loadingStatus
    },
    getSender (state) {
      return `${state.senderName}`
    },
    getRecepient (state) {
      return `${state.recipientName}`
    },
    getNoun (state) {
      return `${state.noun}`
    },
    getAdj (state) {
      return `${state.adj}`
    },
    getVerb (state) {
      return `${state.verb}`
    },
    getStyle (state) {
      return `${state.style}`
    },
    getStep (state) {
      return `${state.step}`
    },
    getImg (state) {
      return `${state.imgURL}`
    }
  }
})
