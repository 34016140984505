<template>
  <div class="gift-gen">
    <div class="img-req" v-if="!submitting">
      <form ref="form" id="generator" action="" @submit.prevent="processRequest">
        <label for="recepientName" v-if="this.formPosition === 1">
          <span>Who is the gift for?</span>
          <input type="text" name="recepientName" id="recepientName" placeholder="Name of Super Special Person" v-model="recName">
          <div class="btn-group end">
            <p class="msg">Psst! We won't tell if you make one for yourself.</p>
            <button type="button" class="button btn-nav next" aria-label="Next Question" @click="goTo('nxt'); setTo()"><span>Next</span></button>
          </div>
        </label>
        <label for="yourName" v-if="this.formPosition === 2">
          <span>What are we making today?</span>
          <input type="text" name="yourName" id="yourName" placeholder="Make me a..." v-model="noun">
          <div class="btn-group">
            <button type="button" class="button btn-nav prev" aria-label="Previous Question" @click="goTo('prev')"><span>Prev</span></button>
            <p class="msg">The factory elves respond best when you name a noun. For example, "cookie," "monster," or "unicorn."</p>
            <button type="button" class="button btn-nav next" aria-label="Next Question" @click="goTo('nxt'); setNoun()"><span>Next</span></button>
          </div>
        </label>
        <label for="nounReq" v-if="this.formPosition === 3">
          <span>Describe the gift in one word.</span>
          <input type="text" name="nounReq" id="nounReq" placeholder="My gift is..." v-model="adj">
          <div class="btn-group">
            <button type="button" class="button btn-nav prev" aria-label="Previous Question" @click="goTo('prev')"><span>Prev</span></button>
            <p class="msg">Give the elves an adjective like "sparkly" or "frosty," or the color you want your gift to be like "red" or "blue." </p>
            <button type="button" class="button btn-nav next" aria-label="Next Question" @click="goTo('nxt'); setAdj()"><span>Next</span></button>
          </div>
        </label>
        <label for="adjReq" v-if="this.formPosition === 4">
          <span>What is your gift doing?</span>
          <input type="text" name="adjReq" id="adjReq" placeholder="I want the gift to..." v-model="verb">
          <div class="btn-group">
            <button type="button" class="button btn-nav prev" aria-label="Previous Question" @click="goTo('prev')"><span>Prev</span></button>
            <p class="msg">Tell the elves if your gift is "dancing," "singing" or anything else you can dream up. </p>
            <button type="button" class="button btn-nav next" aria-label="Next Question" @click="goTo('nxt'); setVerb()"><span>Next</span></button>
          </div>
        </label>
        <label for="verbReq" v-if="this.formPosition === 5">
          <span>Give your gift some style!</span>
           <fieldset class="columns">
            <legend>Choose a style</legend>
            <div class="styles-wrap">
              <input type="radio" id="vg" value="Van Gogh" v-model="style"><label for="vg"><img src="../assets/images/starry.jpg" alt="Van Gogh"/><span>Van Gogh</span></label>
              <input type="radio" id="rockwell" value="Norman Rockwell" v-model="style"><label for="rockwell"><img src="../assets/images/rockwell.jpg" alt="Rockwell"/><span>Norman Rockwell</span></label>
              <input type="radio" id="monet" value="Monet" v-model="style"><label for="monet"><img src="../assets/images/monet.jpg" alt="Monet"/><span>Monet</span></label>
              <input type="radio" id="warhol" value="Andy Warhol" v-model="style"><label for="warhol"><img src="../assets/images/warhol.jpg" alt="Andy Warhol"/><span>Andy Warhol</span></label>
              <input type="radio" id="photo" value="Photo Realism" v-model="style"><label for="photo"><img src="../assets/images/photo.jpg" alt="Photo Realism"/><span>Photo Realism</span></label>
              <input type="radio" id="pencil" value="Pencil Sketch" v-model="style"><label for="pencil"><img src="../assets/images/pencil.jpg" alt="Pencil Sketch"/><span>Pencil Sketch</span></label>
              <input type="radio" id="crayon" value="Crayon" v-model="style"><label for="crayon"><img src="../assets/images/crayon.jpg" alt="Crayon"/><span>Crayon</span></label>
              <input type="radio" id="watercolor" value="Water Color" v-model="style"><label for="watercolor"><img src="../assets/images/watercolor.jpg" alt="Water Color"/><span>Watercolor</span></label>
            </div>
          </fieldset>
          <div class="or">OR</div>
          <input type="text" name="verbReq" id="verbReq" placeholder="Type a Custom Style..." v-model="style">
          <div class="btn-group">
            <button type="button" class="button btn-nav prev" aria-label="Previous Question" @click="goTo('prev')"><span>Prev</span></button>
            <p class="msg">The elves are putting the finishing touches on your gift!</p>
            <button type="button" class="button btn-nav next" aria-label="Next Question" @click="goTo('nxt'); setStyle()"><span>Next</span></button>
          </div>
        </label>
        <label for="prepReq" v-if="this.formPosition === 6">
          <span>Tell us who the gift is from.</span>
          <input type="text" name="prepReq" id="prepReq" placeholder="You can take all the credit!" v-model="yourName">
        <div class="btn-group">
          <button type="button" class="button btn-nav prev" aria-label="Previous Question" @click="goTo('prev')" v-if="this.formPosition === 6"><span>Prev</span></button>
          <button type="submit" class="button btn-nav send" @click="setFrom(); processRequest" v-if="this.formPosition === 6" tabindex="-1"><span>Make My Gift</span></button>
        </div>
        </label>
      </form>
    </div>
    <div v-if="loadingStatus" class="loading-div">
      <vue-spinner message="Hold tight! The elves are making sure your gift sleighs!" size="medium" />
    </div>
    <div class="your-img" v-if="imgURL">
      <div class="img">
        <h3>Wow! Look what you made!</h3>
        <img :src="imgURL">
      </div>
      <div class="share-module">
        <ul>
          <li>Share it:</li>
          <li>
            <ShareNetwork
              network="twitter"
              :url="imgURL"
              :title="this.sharing.title"
              :description="this.sharing.description"
              :hashtags="this.sharing.hashtags"
            >
            <i class="fab fa-twitter"></i>
            </ShareNetwork>
          </li>
          <li>
            <ShareNetwork
                network="facebook"
                :url="imgURL"
                :title="this.sharing.title"
                :description="this.sharing.description"
                :hashtags="this.sharing.hashtags"
              >
              <i class="fab fa-facebook"></i>
            </ShareNetwork>
          </li>
          <li>
            <ShareNetwork
                network="linkedin"
                :url="imgURL"
                :title="this.sharing.title"
                :description="this.sharing.description"
                :hashtags="this.sharing.hashtags"
              >
              <i class="fab fa-linkedin"></i>
            </ShareNetwork>
          </li>
          <li>
            <ShareNetwork
                network="email"
                :title="this.sharingEmail.title"
                :description="this.sharingEmail.description"
                :url="imgURL"
              >
              <i class="fas fa-envelope"></i>
            </ShareNetwork>
          </li>
          <li><a :href="imgURL"><i class="far fa-arrow-to-bottom"></i></a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import Spinner from 'vue-simple-spinner'
import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing)

export default {
  name: 'ImgGen',
  components: {
    vueSpinner: Spinner
  },
  data () {
    return {
      q1: false,
      q2: false,
      q3: false,
      q4: false,
      q5: false,
      q6: false,
      adj: '',
      noun: '',
      verb: '',
      style: '',
      recName: '',
      yourName: '',
      formPosition: 1,
      submitting: false,
      sharing: {
        title: 'Look what I made at The Holid-A.I. Gift Factory from @parkandbattery!',
        hashtags: '#holidaifactory'
      },
      sharingEmail: {
        title: 'Look what I made for you at The Holid-A.I. Gift Factory from Park & Battery. I hope you love it!',
        description: `To ${this.$store.getters.getRecepient}, Look what I made for you at The Holid-A.I. Gift Factory from Park & Battery. I hope you love it! Happy Holidays, ${this.$store.getters.getSender}`
      }
    }
  },
  computed: {
    ...mapState(['imgURL'], ['recipientName'], ['step'], ['adj'], ['noun'], ['verb'], ['style'], ['yourName']),

    loadingStatus () {
      return this.$store.getters.loadingStatus
    },
    getYourName () {
      return this.yourName
    },
    getNoun () {
      return this.noun
    },
    getAdj () {
      return this.adj
    },
    getVerb () {
      return this.verb
    },
    getPrep () {
      return this.style
    },
    reqVal () {
      return `A ${this.getAdj} ${this.getNoun} ${this.getVerb} in the style of ${this.getPrep}`
    }
  },
  methods: {
    processRequest (event) {
      if (this.adj && this.noun && this.verb && this.style && this.recName && this.yourName) {
        this.submitting = true
        this.$store.dispatch('loadImg', this.reqVal)
      } else {
        console.log('you are NOT done')
      }
    },
    goTo (step) {
      if (step === 'nxt') {
        this.formPosition += 1
        this.$store.dispatch('setStep', this.formPosition)
      }

      if (step === 'prev' && this.formPosition !== 1) {
        this.formPosition -= 1
        this.$store.dispatch('setStep', this.formPosition)
      }
    },
    setFrom () {
      this.$store.dispatch('setName', this.yourName)
    },
    setTo () {
      this.$store.dispatch('setNameTo', this.recName)
    },
    setNoun () {
      this.$store.dispatch('setNoun', this.noun)
    },
    setAdj () {
      this.$store.dispatch('setAdj', this.adj)
    },
    setVerb () {
      this.$store.dispatch('setVerb', this.verb)
    },
    setStyle () {
      this.$store.dispatch('setStyle', this.style)
    }
  },
  mounted () {
    this.q1 = true
  }
}
</script>

<style scoped lang="scss">
  .gift-gen {
    width: 100%;
  }

  .next {
    cursor: pointer;
  }

  .styles-wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    label {
      display: block;
      width: 130px;
      height: 130px;
      text-align: center;
      margin-bottom: 30px;

      img {
        width: 130px;
        height: 130px;
        object-fit: cover;
      }

      span {
        display: block;
        font-size: 14px;
        line-height: 24px;
        color: #000;
        padding: 0;
        font-weight: 300;
      }
    }

    input[type="radio"] {
      opacity: 0;
      position: absolute;
    }
  }

  .or {
    padding: 15px 0;
  }

  .loading-div {
    padding-top: 165px;

    @media screen and (min-width: 1024px) {
      padding-top: 200px;
    }
  }
</style>
